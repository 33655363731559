import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Receipt, Tune } from '@mui/icons-material'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

import { QUOTE_STATUS } from '@/common/utils/Constants/Constants'

import ShippingBreakdownDialog from '../ShippingBreakdownDialog/ShippingBreakdownDialog'

const QuoteShippingBreakdown = ({ quote }) => {
    const { t } = useTranslation()
    const [showShippingBreakdownDialog, setShowShippingBreakdownDialog] = useState(false)

    const handleShippingBreakdownClicked = () => {
        setShowShippingBreakdownDialog(true)
    }

    const handleShippingBreakdownDialogClose = () => {
        setShowShippingBreakdownDialog(false)
    }

    const disabledShippingBreakdownButton = useMemo(() => {
        return [QUOTE_STATUS.NotCalculated].includes(quote?.status) || !quote.deliveryPriceDetailsId
    }, [quote?.status, quote.deliveryPriceDetailsId])

    return (
        <>
            <Button
                color="primary"
                data-testid="shipping-breakdown-button"
                disabled={disabledShippingBreakdownButton}
                size="small"
                startIcon={<Receipt />}
                variant="outlined"
                onClick={handleShippingBreakdownClicked}
            >
                {t('Shipping Breakdown')}
            </Button>

            {showShippingBreakdownDialog ? (
                <ShippingBreakdownDialog
                    open={showShippingBreakdownDialog}
                    quoteId={quote.id}
                    onClose={handleShippingBreakdownDialogClose}
                />
            ) : null}
        </>
    )
}

QuoteShippingBreakdown.propTypes = {
    quote: PropTypes.object.isRequired,
}

export default QuoteShippingBreakdown
