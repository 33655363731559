import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material'
import { Box, Button, Divider, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useCreateStripeCheckoutSessionMutation } from '@/app/services/integrations'
import { useGetStoreSettingsQuery } from '@/app/services/organisation'
import { useCreateCustomerMutation, useUpdateCustomerMutation } from '@/app/services/web-store/webStoreCustomer'
import {
    useCalculateQuoteMutation,
    useConfirmOrderMutation,
    useGetQuoteQuery,
    useUpdateQuoteMutation,
} from '@/app/services/web-store/webStoreQuote'
import { useGetQuoteItemsQuery } from '@/app/services/web-store/webStoreQuoteItems'
import {
    selectCurrentContact,
    selectGuestUser,
    selectIsContactLoggedIn,
    selectIsGuestLoggedIn,
    setGuestUserData,
} from '@/app/slices/web-store/webStoreAuthSlice'
import { getLargestDimensions, Paths, QUOTE_USER_TYPE, WEB_STORE_QUOTE_STATUS } from '@/common/utils'

import { formatCurrency, formatNumber } from '../../helpers/utilities'

const classes = {
    orderSummary: {
        padding: 3,
        gap: '24px',
        minHeight: {
            lg: 'calc(100vh - 114px)',
        },
    },
    summaryTitle: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },
    issueMessages: {
        gap: '24px',
    },
    pricingSummary: {
        gap: '12px',
        '& .price': {
            fontWeight: 700,
        },
    },
    checkoutButton: {
        gap: '8px',
    },
    saveOrder: {
        gap: '8px',
    },
    smallText: {
        fontSize: '0.875rem',
    },
    needHelp: {
        maxWidth: '500px',
    },
}

const OrderSummary = ({ form }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { organisationId, quoteId } = useParams()

    const { handleSubmit, watch } = form

    const isContactLoggedIn = useSelector(selectIsContactLoggedIn)
    const isGuestLoggedIn = useSelector(selectIsGuestLoggedIn)
    const currentUser = useSelector(selectCurrentContact)
    const guestUser = useSelector(selectGuestUser)

    const { data: storeSettings } = useGetStoreSettingsQuery({
        organisationId,
    })

    const { data: quote } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })

    const { data: quoteItems } = useGetQuoteItemsQuery({
        organisationId,
        quoteId,
    })

    const [createCustomer] = useCreateCustomerMutation()
    const [updateCustomer] = useUpdateCustomerMutation()
    const [updateQuote] = useUpdateQuoteMutation()
    const [calculateOrder] = useCalculateQuoteMutation()
    const [confirmOrder] = useConfirmOrderMutation()
    const [createStripeCheckoutSession] = useCreateStripeCheckoutSessionMutation()

    const [isPreparingOrder, setIsPreparingOrder] = useState(false)
    const [isInitiatingPayment, setIsInitiatingPayment] = useState(false)
    const [shippingAndTaxCalculated, setShippingAndTaxCalculated] = useState(false)

    const largestDimensions = getLargestDimensions(quoteItems, storeSettings)

    const paymentsEnabled = storeSettings?.paymentsEnabledWebStore
    const deliveryOptionSelected = form.getValues('shippingOption')

    const showDeliveryPrice = deliveryOptionSelected === 'delivery' && quote?.deliveryPricingMethod === 'ChargeToOrder'

    const navigateBackToQuote = useCallback(
        () => navigate(`/store/${organisationId}/${quoteId}`),
        [navigate, organisationId, quoteId]
    )

    const updateQuoteContact = useCallback(
        (
            contactId,
            userType,
            shippingOption,
            customerId,
            deliveryProviderToUse,
            customerDeliveryProvider,
            customerDeliveryAccountNumber,
            billingAddressId,
            deliveryAddressId
        ) => {
            return updateQuote({
                quoteId: quote.id,
                organisationId: quote.organisationId,
                quote: {
                    name: quote.name,
                    quoteId: quote.id,
                    customerContactId: contactId,
                    quoteUserType: userType,
                    deliveryOption: shippingOption,
                    customerId: customerId,
                    deliveryPricingMethod: quote.deliveryPricingMethod,
                    ...(deliveryProviderToUse === 'customerProvider' && {
                        customerDeliveryProvider,
                        customerDeliveryAccountNumber,
                    }),
                    billingAddressId: billingAddressId,
                    deliveryAddressId: deliveryAddressId,
                },
            })
        },
        [quote, updateQuote]
    )

    const updateQuoteStatus = useCallback(
        (confirmedQuote, orderStatus, contactId, userType, shippingOption, customerId) => {
            return updateQuote({
                quoteId: confirmedQuote.id,
                organisationId: confirmedQuote.organisationId,
                quote: {
                    name: confirmedQuote.name,
                    quoteId: confirmedQuote.id,
                    taxRateId: confirmedQuote.taxRateId,
                    taxRate: confirmedQuote.taxRate,
                    customerId: customerId,
                    status: orderStatus,
                    customerContactId: contactId,
                    quoteUserType: userType,
                    deliveryOption: shippingOption,
                    deliveryPricingMethod: quote.deliveryPricingMethod,
                    deliveryAddressId: quote.deliveryAddressId,
                    billingAddressId: quote.billingAddressId,
                },
            })
        },
        [quote, updateQuote]
    )

    const getAddress = (address, type) => ({
        addressType: type,
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        state: address.state,
        country: address.country,
        postcode: address.postcode,
    })

    const createCustomerAndContact = (data) => {
        const { billingAddress, companyName, deliveryAddress, email, name, phone, sameAsBillingAddress } = data
        const { organisationId } = quote

        const hasDeliveryAddress = Object.values(deliveryAddress).some((field) => Boolean(field))

        const resolvedDeliveryAddress =
            sameAsBillingAddress || !hasDeliveryAddress
                ? getAddress(billingAddress, 'Delivery')
                : getAddress(deliveryAddress, 'Delivery')

        return createCustomer({
            organisationId,
            customer: {
                companyName,
                useOneAddress: sameAsBillingAddress,
                contacts: [{ name, email, phone }],
                billingAddress: getAddress(billingAddress, 'Billing'),
                deliveryAddress: resolvedDeliveryAddress,
            },
        })
    }

    const updateCustomerAndContact = (data) => {
        const { billingAddress, companyName, deliveryAddress, email, name, phone, sameAsBillingAddress } = data
        const { organisationId } = quote
        const user = isContactLoggedIn ? currentUser : guestUser

        const emailAddress = isContactLoggedIn ? user.email : email

        const resolvedDeliveryAddress = sameAsBillingAddress
            ? getAddress(billingAddress, 'Delivery')
            : getAddress(deliveryAddress, 'Delivery')

        return updateCustomer({
            organisationId,
            customerId: user.customerId,
            customer: {
                customerId: user.customerId,
                companyName,
                useOneAddress: sameAsBillingAddress,
                contacts: [{ contactId: user.contactId, name, email: emailAddress, phone }],
                billingAddress: getAddress(billingAddress, 'Billing'),
                deliveryAddress: resolvedDeliveryAddress,
            },
        })
    }

    const preparingOrder = async (data) => {
        const { customerDeliveryAccountNumber, customerDeliveryProvider, deliveryProviderToUse, shippingOption } =
            form.getValues()
        let customerId, contactId, quoteUserType

        try {
            if (!isContactLoggedIn && !guestUser?.customerId) {
                const result = await createCustomerAndContact(data).unwrap()

                if (result?.contacts?.[0]?.contactId) {
                    await updateQuoteContact(
                        result.contacts[0].contactId,
                        QUOTE_USER_TYPE.Guest,
                        shippingOption,
                        result.customerId,
                        deliveryProviderToUse,
                        customerDeliveryProvider,
                        customerDeliveryAccountNumber,
                        result?.billingAddress?.addressId,
                        result?.deliveryAddress?.addressId
                    ).unwrap()
                }

                customerId = result.customerId
                contactId = result.contacts[0].contactId
                quoteUserType = QUOTE_USER_TYPE.Guest

                dispatch(setGuestUserData({ ...data, customerId, contactId }))
            }

            if (isContactLoggedIn && currentUser) {
                const result = await updateCustomerAndContact(data).unwrap()

                await updateQuoteContact(
                    currentUser.contactId,
                    QUOTE_USER_TYPE.Customer,
                    shippingOption,
                    currentUser.customerId,
                    deliveryProviderToUse,
                    customerDeliveryProvider,
                    customerDeliveryAccountNumber,
                    result?.billingAddress?.addressId,
                    result?.deliveryAddress?.addressId
                ).unwrap()

                customerId = currentUser.customerId
                contactId = currentUser.contactId
                quoteUserType = QUOTE_USER_TYPE.Customer
            }

            if (isGuestLoggedIn && guestUser?.customerId) {
                const result = await updateCustomerAndContact(data).unwrap()

                await updateQuoteContact(
                    guestUser.contactId,
                    QUOTE_USER_TYPE.Guest,
                    shippingOption,
                    guestUser.customerId,
                    deliveryProviderToUse,
                    customerDeliveryProvider,
                    customerDeliveryAccountNumber,
                    result?.billingAddress?.addressId,
                    result?.deliveryAddress?.addressId
                ).unwrap()

                customerId = guestUser.customerId
                contactId = guestUser.contactId
                quoteUserType = QUOTE_USER_TYPE.Guest

                dispatch(setGuestUserData({ ...data, customerId, contactId }))
            }

            return { customerId, contactId, quoteUserType }
        } catch (error) {
            throw new Error('Preparing Order Error', { cause: error })
        }
    }

    const recalculateOrder = async () => {
        try {
            const calculatedOrder = await calculateOrder({ organisationId, quoteId }).unwrap()

            return calculatedOrder
        } catch (error) {
            const errorMessage = t(error.data) || t('Sorry, the order could not be placed. Please try again.')
            throw new Error(errorMessage, { cause: error })
        }
    }

    const calculateTaxes = async () => {
        try {
            const confirmedQuote = await confirmOrder({ organisationId, quoteId }).unwrap()

            return confirmedQuote
        } catch (error) {
            const errorMessage = t(error.data) || t('Sorry, the order could not be placed. Please try again.')
            throw new Error(errorMessage, { cause: error })
        }
    }

    const handleCalculateShippingAndTaxes = async (data) => {
        setIsPreparingOrder(true)
        try {
            await preparingOrder(data)
            await recalculateOrder()
            await calculateTaxes()

            setShippingAndTaxCalculated(true)

            enqueueSnackbar(t('Shipping and taxes calculated'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t(error.message), { variant: 'error' })

            setShippingAndTaxCalculated(false)
        }
        setIsPreparingOrder(false)
    }

    const handleCheckoutOrder = async () => {
        setIsInitiatingPayment(true)
        try {
            const stripeCheckoutSessionUrl = await createStripeCheckoutSession({
                organisationId: organisationId,
                quoteId: quoteId,
                successUrl: `${import.meta.env.VITE_AUTH_REDIRECT_URI}${Paths.PAYMENTS_PATHNAME}/${quoteId}/${Paths.PAYMENTS_SUCCESS_PATHNAME}`,
                cancelUrl: `${import.meta.env.VITE_AUTH_REDIRECT_URI}/store/${organisationId}/${quoteId}/checkout`,
            }).unwrap()

            enqueueSnackbar('Redirecting to stripe...', {
                variant: 'info',
                onExited: () => {
                    window.open(stripeCheckoutSessionUrl, '_self')
                    setIsInitiatingPayment(false)
                },
            })
        } catch (error) {
            setIsInitiatingPayment(false)
            console.error('WebStore', 'Payment Button Error', error)
            enqueueSnackbar('Sorry, the order could not be placed. Please try again.', {
                variant: 'error',
            })
        }
    }

    const handlePlaceOrder = async () => {
        try {
            const status = WEB_STORE_QUOTE_STATUS.PendingOrderConfirmation
            await updateQuoteStatus(
                quote,
                status,
                quote.customerContact.contactId,
                quote.quoteUserType,
                quote.deliveryOption,
                quote.customerContact.customerId
            ).unwrap()

            enqueueSnackbar(t('Order placed'), { variant: 'success' })
            navigate(`/store/${organisationId}/${quoteId}/order-confirmation`)
        } catch (error) {
            enqueueSnackbar(t('Sorry, the order could not be placed. Please try again.'), { variant: 'error' })
        }
    }

    const handleSubmitError = (_error) => {
        enqueueSnackbar(t('Sorry, the order could not be placed. Please complete the required fields.'), {
            variant: 'error',
        })
    }

    const handleBackToQuote = async (data) => {
        if (!isContactLoggedIn) {
            dispatch(setGuestUserData(guestUser || data))
        }
        navigateBackToQuote()
    }

    useEffect(() => {
        const { unsubscribe } = watch((_watched) => {
            setShippingAndTaxCalculated(false)
        })
        return () => unsubscribe()
    }, [watch])

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={classes.orderSummary}
        >
            <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
            >
                <Typography
                    sx={classes.summaryTitle}
                    variant="body1"
                >
                    {t('Order summary')}
                </Typography>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                sx={classes.pricingSummary}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Typography variant="body2">{t('Net weight')}</Typography>
                    <Typography
                        className="price"
                        variant="body2"
                    >
                        {formatNumber(quote.totalMass, storeSettings?.locale)}{' '}
                        {storeSettings?.defaultDrawingUnits === 'Metric' ? 'kg' : 'lb'}
                    </Typography>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Typography variant="body2">{t('Largest long-dimension')}</Typography>
                    <Typography
                        className="price"
                        variant="body2"
                    >
                        {largestDimensions.long}
                    </Typography>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Typography variant="body2">{t('Largest short-dimension')}</Typography>
                    <Typography
                        className="price"
                        variant="body2"
                    >
                        {largestDimensions.short}
                    </Typography>
                </Box>
                <Divider />

                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Typography variant="body2">{t('Parts')}</Typography>
                    <Typography
                        className="price"
                        variant="body2"
                    >
                        {formatCurrency(
                            quote.processingTotalPrice + quote.materialPrice,
                            storeSettings?.currencyCode,
                            storeSettings?.locale
                        )}
                    </Typography>
                </Box>
                {quote.minimumQuoteChargePrice > 0 ? (
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Typography variant="body2">{t('Minimum order charge')}</Typography>
                        <Typography
                            className="price"
                            variant="body2"
                        >
                            {formatCurrency(
                                quote.minimumQuoteChargePrice,
                                storeSettings?.currencyCode,
                                storeSettings?.locale
                            )}
                        </Typography>
                    </Box>
                ) : null}

                {showDeliveryPrice ? (
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Typography variant="body2">{t('Delivery')}</Typography>
                        <Typography
                            className="price"
                            variant="body2"
                        >
                            {shippingAndTaxCalculated
                                ? formatCurrency(
                                      quote.deliveryPrice,
                                      storeSettings?.currencyCode,
                                      storeSettings?.locale
                                  )
                                : String.fromCharCode(8212)}
                        </Typography>
                    </Box>
                ) : null}
                <Divider />
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Typography variant="body2">{t('Subtotal')}</Typography>
                    <Typography
                        className="price"
                        variant="body2"
                    >
                        {formatCurrency(
                            quote.linePrice +
                                (quote.minimumQuoteChargePrice || 0) -
                                (!shippingAndTaxCalculated ? quote.deliveryPrice : 0),
                            storeSettings?.currencyCode,
                            storeSettings?.locale
                        )}
                    </Typography>
                </Box>

                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Typography variant="body2">
                        {t('Taxes')} ({shippingAndTaxCalculated ? `${quote.taxRate * 100}%` : String.fromCharCode(8212)}
                        )
                    </Typography>
                    <Typography
                        className="price"
                        variant="body2"
                    >
                        {shippingAndTaxCalculated
                            ? formatCurrency(
                                  (quote.linePrice + (quote.minimumQuoteChargePrice || 0)) * quote.taxRate,
                                  storeSettings?.currencyCode,
                                  storeSettings?.locale
                              )
                            : String.fromCharCode(8212)}
                    </Typography>
                </Box>
                <Divider />
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Typography variant="body1">{t('Total')}</Typography>
                    <Typography
                        className="price"
                        variant="body1"
                    >
                        {shippingAndTaxCalculated
                            ? formatCurrency(quote.lineTaxedPrice, storeSettings?.currencyCode, storeSettings?.locale)
                            : String.fromCharCode(8212)}
                    </Typography>
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                sx={classes.checkoutButton}
            >
                {deliveryOptionSelected === 'pickup' ? (
                    <Typography
                        sx={classes.smallText}
                        variant="body2"
                    >
                        {t('Shipping: Pickup')}
                    </Typography>
                ) : null}

                {deliveryOptionSelected === 'delivery' && quote?.deliveryPricingMethod === 'SeparateCharge' ? (
                    <Typography
                        sx={classes.smallText}
                        variant="body2"
                    >
                        {t('Shipping: Delivery charged separately')}
                    </Typography>
                ) : null}

                {deliveryOptionSelected === 'delivery' && quote?.deliveryPricingMethod === 'PayUponDelivery' ? (
                    <Typography
                        sx={classes.smallText}
                        variant="body2"
                    >
                        {t('Shipping: Delivery payable to delivery provider')}
                    </Typography>
                ) : null}

                {deliveryOptionSelected === 'delivery' && quote?.deliveryPricingMethod === 'ChargeToOrder' ? (
                    <Typography
                        sx={classes.smallText}
                        variant="body2"
                    >
                        {t('Shipping: Delivery included')}
                    </Typography>
                ) : null}

                {!shippingAndTaxCalculated ? (
                    <Button
                        color="primary"
                        disabled={isPreparingOrder}
                        variant="contained"
                        fullWidth
                        onClick={handleSubmit(handleCalculateShippingAndTaxes, handleSubmitError)}
                    >
                        {isPreparingOrder
                            ? t('Please wait...')
                            : showDeliveryPrice
                              ? t('Calculate shipping & tax')
                              : t('Calculate tax')}
                    </Button>
                ) : paymentsEnabled ? (
                    <Button
                        color="primary"
                        disabled={isInitiatingPayment}
                        endIcon={<ArrowForwardRounded />}
                        variant="contained"
                        fullWidth
                        onClick={handleSubmit(handleCheckoutOrder, handleSubmitError)}
                    >
                        {isInitiatingPayment ? t('Please wait...') : t('Checkout')}
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        disabled={isInitiatingPayment}
                        endIcon={<ArrowForwardRounded />}
                        variant="contained"
                        fullWidth
                        onClick={handleSubmit(handlePlaceOrder, handleSubmitError)}
                    >
                        {isInitiatingPayment ? t('Placing order...') : t('Place order')}
                    </Button>
                )}

                <Button
                    color="primary"
                    disabled={isPreparingOrder || isInitiatingPayment}
                    startIcon={<ArrowBackRounded />}
                    variant="outlined"
                    fullWidth
                    onClick={handleSubmit(handleBackToQuote, navigateBackToQuote)}
                >
                    {t('Back to $t(quote)')}
                </Button>
            </Box>

            <Box
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="flex-end"
            >
                {storeSettings?.webStoreSupportEmail || storeSettings?.webStoreContactNumber ? (
                    <Box sx={classes.needHelp}>
                        <Alert
                            severity="info"
                            variant="standard"
                        >
                            <AlertTitle>{t('Need help with your files?')}</AlertTitle>
                            {storeSettings?.webStoreSupportEmail && !storeSettings?.webStoreContactNumber ? (
                                <>
                                    {t('Contact us at')}{' '}
                                    <a href={`mailto:${storeSettings?.webStoreSupportEmail}`}>
                                        {storeSettings?.webStoreSupportEmail}
                                    </a>
                                </>
                            ) : null}
                            {!storeSettings?.webStoreSupportEmail && storeSettings?.webStoreContactNumber ? (
                                <>
                                    {t('Call us at')}{' '}
                                    <a href={`tel:${storeSettings?.webStoreContactNumber}`}>
                                        {storeSettings?.webStoreContactNumber}
                                    </a>
                                </>
                            ) : null}
                            {storeSettings?.webStoreSupportEmail && storeSettings?.webStoreContactNumber ? (
                                <>
                                    {t('Contact us at')}{' '}
                                    <a href={`mailto:${storeSettings?.webStoreSupportEmail}`}>
                                        {storeSettings?.webStoreSupportEmail}
                                    </a>{' '}
                                    {t('or call us at')}{' '}
                                    <a href={`tel:${storeSettings?.webStoreContactNumber}`}>
                                        {storeSettings?.webStoreContactNumber}
                                    </a>
                                </>
                            ) : null}
                        </Alert>
                    </Box>
                ) : null}
            </Box>
        </Box>
    )
}

OrderSummary.propTypes = {
    form: PropTypes.object.isRequired,
}

export default OrderSummary
