import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { LocalShippingRounded, MyLocationRounded } from '@mui/icons-material'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import { getDetails } from 'use-places-autocomplete'

import { useGetStoreSettingsQuery } from '@/app/services/organisation'
import {
    selectCurrentCustomer,
    selectGuestUser,
    selectIsContactLoggedIn,
    selectIsGuestLoggedIn,
} from '@/app/slices/web-store/webStoreAuthSlice'
import FormInputAddress from '@/common/components/FormComponents/FormInputAddress'
import FormInputCheckbox from '@/common/components/FormComponents/FormInputCheckbox'
import FormInputRadio from '@/common/components/FormComponents/FormInputRadio'
import FormInputText from '@/common/components/FormComponents/FormInputText'
import {
    getAddressDetails,
    getAddressDetailsFromAddressComponents,
    hasDeliveryForAddress,
    validateAddress,
} from '@/common/helpers/addressUtilities'
import { DELIVERY_PRICING_METHODS } from '@/common/utils'

import TbxTooltip from '../../components/shared/TbxTooltip'
import { acceptedPatterns } from '../../helpers/utilities'

const classes = {
    orderDetails: {
        p: 3,
        gap: '24px',
        minHeight: {
            lg: 'calc(100vh - 114px)',
        },
    },
    orderDetailsSection: {
        padding: 1,
        gap: '24px',
    },
    sectionTitle: {
        width: '200px',
        flex: '1 0 auto',
    },
    smallText: {
        fontSize: '0.75rem',
    },
}

const OrderDetails = ({ customerDeliveryProviderOption, deliveryPriceMethod, deliveryProvider, form }) => {
    const { t } = useTranslation()
    const { organisationId } = useParams()
    const { clearErrors, control, formState, getValues, resetField, setError, setValue, watch } = form

    const { data: storeSettings } = useGetStoreSettingsQuery({
        organisationId,
    })

    const isContactLoggedIn = useSelector(selectIsContactLoggedIn)
    const isGuestLoggedIn = useSelector(selectIsGuestLoggedIn)
    const currentCustomer = useSelector(selectCurrentCustomer)
    const guestUser = useSelector(selectGuestUser)

    const [enterBillingAddressManually, setEnterBillingAddressManually] = useState(false)
    const [enterDeliveryAddressManually, setEnterDeliveryAddressManually] = useState(false)
    const [selectedBillingGoogleAddress, setSelectedBillingGoogleAddress] = useState(null)
    const [selectedDeliveryGoogleAddress, setSelectedDeliveryGoogleAddress] = useState(null)
    const [deliveryProviderAgreement, setDeliveryProviderAgreement] = useState(false)

    const pickupAddress = {
        line1: storeSettings?.pickupLine1,
        line2: storeSettings?.pickupLine2,
        city: storeSettings?.pickupCity,
        state: storeSettings?.pickupState,
        postcode: storeSettings?.pickupPostcode,
        country: storeSettings?.pickupCountry,
    }

    const shippingOptions = useMemo(() => {
        const options = []
        if (storeSettings?.webStorePickupEnabled) {
            options.push({
                label: 'Pickup',
                value: 'pickup',
                icon: <MyLocationRounded />,
            })
        }
        if (storeSettings?.webStoreDeliveryEnabled && !!storeSettings?.webStoreDeliveryRegions.length) {
            options.push({
                label: 'Delivery',
                value: 'delivery',
                icon: <LocalShippingRounded />,
            })
        }
        return options
    }, [storeSettings])

    const billingAddressFields = watch([
        'billingAddress.line1',
        'billingAddress.city',
        'billingAddress.state',
        'billingAddress.country',
    ])
    const deliveryAddressFields = watch([
        'deliveryAddress.line1',
        'deliveryAddress.city',
        'deliveryAddress.state',
        'deliveryAddress.country',
    ])

    const billingGoogleAddressField = watch('billingAddress.googleAddress')
    const deliveryGoogleAddressField = watch('deliveryAddress.googleAddress')
    const selectedShippingOption = watch('shippingOption')
    const sameAsBillingAddress = watch('sameAsBillingAddress')
    const deliveryProviderToUse = watch('deliveryProviderToUse')

    const hasCompletedBillingAddress = useMemo(() => {
        return billingAddressFields.every((value) => Boolean(value))
    }, [billingAddressFields])

    const hasCompletedDeliveryAddress = useMemo(() => {
        return deliveryAddressFields.every((value) => Boolean(value))
    }, [deliveryAddressFields])

    const setDeliveryAddressError = (addressType, errorMessage) => {
        const errorField =
            addressType === 'billingAddress' ? 'cannotDeliveryToBillingAddress' : 'cannotDeliveryToDeliveryAddress'
        setError(errorField, {
            type: 'invalidAddress',
            message: errorMessage,
        })
    }

    const clearDeliveryAddressError = (addressType) => {
        const errorField =
            addressType === 'billingAddress' ? 'cannotDeliveryToBillingAddress' : 'cannotDeliveryToDeliveryAddress'
        clearErrors(errorField)
    }

    const setInvalidAddressError = (addressType) => {
        const errorField = addressType === 'billingAddress' ? 'invalidBillingAddress' : 'invalidDeliveryAddress'
        const errorMessage = t(
            'We could not find the specified address. Please ensure that the address is correct and try again.'
        )

        setError(errorField, {
            type: 'invalidAddress',
            message: errorMessage,
        })
    }

    const clearInvalidAddressError = (addressType) => {
        const errorField = addressType === 'billingAddress' ? 'invalidBillingAddress' : 'invalidDeliveryAddress'
        clearErrors(errorField)
    }

    const setAddressPlaceId = async (addressType) => {
        const address = getValues(`${addressType}.line1`) ?? ''
        const city = getValues(`${addressType}.city`) ?? ''
        const state = getValues(`${addressType}.state`) ?? ''
        const country = getValues(`${addressType}.country`) ?? ''
        const postcode = getValues(`${addressType}.postcode`) ?? ''
        const fullAddress = [address, city, state, country, postcode].filter(Boolean).join(', ')

        const { isValid, placeId } = await validateAddress({ address: fullAddress })

        if (!isValid) return

        return placeId
    }

    const setAddressDetails = (addressType, addressDetails) => {
        const { addressLine1, city, country, postcode, state, subpremise } =
            getAddressDetailsFromAddressComponents(addressDetails)

        setValue(`${addressType}.line1`, addressLine1)
        setValue(`${addressType}.line2`, subpremise)
        setValue(`${addressType}.city`, city)
        setValue(`${addressType}.state`, state)
        setValue(`${addressType}.country`, country)
        setValue(`${addressType}.postcode`, postcode)

        if (addressType === 'billingAddress') {
            setSelectedBillingGoogleAddress(addressDetails)

            if (sameAsBillingAddress) {
                setSelectedDeliveryGoogleAddress(addressDetails)
                validateAddressDeliveryStatus(addressType)
            }
        }

        if (addressType === 'deliveryAddress') {
            setSelectedDeliveryGoogleAddress(addressDetails)
            validateAddressDeliveryStatus(addressType)
        }
    }

    const clearAddressDetails = (addressType) => {
        resetField(`${addressType}.line1`, { defaultValue: '' })
        resetField(`${addressType}.line2`, { defaultValue: '' })
        resetField(`${addressType}.city`, { defaultValue: '' })
        resetField(`${addressType}.state`, { defaultValue: '' })
        resetField(`${addressType}.country`, { defaultValue: '' })
        resetField(`${addressType}.postcode`, { defaultValue: '' })
        if (addressType === 'billingAddress') {
            resetField('sameAsBillingAddress', { defaultValue: false })
            setSelectedBillingGoogleAddress(null)
        }

        if (addressType === 'deliveryAddress') {
            setSelectedDeliveryGoogleAddress(null)
        }
        clearDeliveryAddressError(addressType)
    }

    const fetchGoogleAddressFromCustomer = useCallback(
        async (addressType) => {
            const customerData = isContactLoggedIn ? currentCustomer : guestUser
            const addressComponents = ['line1', 'city', 'state', 'country', 'postcode']

            const getAddressComponent = (component) => customerData?.[`${addressType}`]?.[component] || ''

            const addressValues = addressComponents.map(getAddressComponent)
            const fullAddress = addressValues.filter(Boolean).join(', ')

            if (!fullAddress) return

            const { formattedAddress, isValid, placeId } = await validateAddress({ address: fullAddress })

            addressComponents.forEach((component, index) => {
                setValue(`${addressType}.${component}`, addressValues[index])
            })

            if (!isValid) {
                setDeliveryAddressError(
                    addressType,
                    t(
                        'We could not find the specified address. Please ensure that the address is correct and try again.'
                    )
                )
                return
            }

            const detailedAddress = await getDetails({ placeId })

            if (addressType === 'billingAddress') {
                setSelectedBillingGoogleAddress(detailedAddress)
                setValue('billingAddress.googleAddress', { description: formattedAddress, place_id: placeId })
            } else if (addressType === 'deliveryAddress') {
                setSelectedDeliveryGoogleAddress(detailedAddress)
                setValue('deliveryAddress.googleAddress', { description: formattedAddress, place_id: placeId })
            }
        },
        [currentCustomer, form, guestUser, isContactLoggedIn, isGuestLoggedIn]
    )

    const validateAddressDeliveryStatus = useCallback(
        async (addressType) => {
            const googleAddress = getValues(`${addressType}.googleAddress`)
            const line1 = getValues(`${addressType}.line1`)
            let placeId = googleAddress?.place_id || null
            let errorMessage = ''

            if (!googleAddress && !line1) {
                clearDeliveryAddressError('billingAddress')
                clearDeliveryAddressError('deliveryAddress')
                return
            }

            if ((isContactLoggedIn || isGuestLoggedIn) && !placeId && line1) {
                placeId = await setAddressPlaceId(addressType)
            }

            const validDeliveryAddress = await hasDeliveryForAddress(storeSettings?.webStoreDeliveryRegions, placeId)

            if (!validDeliveryAddress) {
                errorMessage = t(
                    'We are not currently delivering to the specified location. Please indicate another address.'
                )
                setDeliveryAddressError(addressType, errorMessage)
                return
            }

            clearDeliveryAddressError('billingAddress')
            clearDeliveryAddressError('deliveryAddress')
            clearInvalidAddressError(addressType)
        },
        [isContactLoggedIn, isGuestLoggedIn, storeSettings?.webStoreDeliveryRegions]
    )

    const validateManualAddress = useCallback(
        async (addressType) => {
            const addressComponents = ['line1', 'city', 'state', 'country', 'postcode']
            const getAddressComponent = (component) => getValues(`${addressType}.${component}`) || ''
            const addressValues = addressComponents.map(getAddressComponent)
            const fullAddress = addressValues.filter(Boolean).join(', ')

            const { formattedAddress, isValid, placeId } = await validateAddress({ address: fullAddress })

            if (!isValid) {
                setInvalidAddressError(addressType)
                resetField(`${addressType}.googleAddress`, { defaultValue: null })
                return
            }

            const detailedAddress = await getDetails({ placeId })
            clearInvalidAddressError(addressType)

            if (addressType === 'billingAddress') {
                setSelectedBillingGoogleAddress(detailedAddress)
                setValue('billingAddress.googleAddress', { description: formattedAddress, place_id: placeId })

                if (sameAsBillingAddress) {
                    setSelectedDeliveryGoogleAddress(detailedAddress)
                    setValue('deliveryAddress.googleAddress', { description: formattedAddress, place_id: placeId })
                }
            } else if (addressType === 'deliveryAddress') {
                setSelectedDeliveryGoogleAddress(detailedAddress)
                setValue('deliveryAddress.googleAddress', { description: formattedAddress, place_id: placeId })
            }

            await validateAddressDeliveryStatus(addressType)
        },
        [
            getValues,
            setValue,
            validateAddressDeliveryStatus,
            clearInvalidAddressError,
            setInvalidAddressError,
            selectedBillingGoogleAddress,
            selectedDeliveryGoogleAddress,
        ]
    )

    const debounceValidateManualAddress = useDebouncedCallback(validateManualAddress, 1500)

    const handleAddressFieldChange = (_e, addressType) => {
        debounceValidateManualAddress.cancel()
        if (addressType === 'billingAddress' && hasCompletedBillingAddress && enterBillingAddressManually) {
            debounceValidateManualAddress(addressType)
        }

        if (addressType === 'deliveryAddress' && hasCompletedDeliveryAddress && enterDeliveryAddressManually) {
            debounceValidateManualAddress(addressType)
        }
    }

    const handleDeliveryProviderAgreement = (agreement) => {
        setDeliveryProviderAgreement(agreement)
        if (!agreement && deliveryPriceMethod === DELIVERY_PRICING_METHODS['PayUponDelivery'].value) {
            setError('deliveryProviderAgreement', {
                type: 'required',
                message: t('You must agree to the terms and conditions'),
            })
        } else {
            clearErrors('deliveryProviderAgreement')
        }
    }

    const handlePickupOption = () => {
        setValue('sameAsBillingAddress', false, { shouldValidate: true })
        setEnterDeliveryAddressManually(false)
        clearDeliveryAddressError('billingAddress')
        clearDeliveryAddressError('deliveryAddress')
        clearInvalidAddressError('deliveryAddress')
        clearErrors('deliveryProviderAgreement')
        setDeliveryProviderAgreement(false)

        if (!isContactLoggedIn || (isGuestLoggedIn && !guestUser)) {
            clearAddressDetails('deliveryAddress')
        }
    }

    const handleDeliveryOption = async () => {
        if ((isContactLoggedIn || isGuestLoggedIn) && !deliveryGoogleAddressField && !sameAsBillingAddress) {
            await fetchGoogleAddressFromCustomer('deliveryAddress')
        }
    }

    useEffect(() => {
        if (selectedShippingOption === 'delivery') {
            handleDeliveryProviderAgreement(false)
        }
    }, [selectedShippingOption])

    useEffect(() => {
        if (selectedShippingOption === 'pickup') {
            handlePickupOption()
        }
        if (selectedShippingOption === 'delivery') {
            handleDeliveryOption()
        }

        if (sameAsBillingAddress && isGuestLoggedIn && !guestUser) {
            clearAddressDetails('deliveryAddress')
            setEnterDeliveryAddressManually(false)
        }

        if (selectedShippingOption === 'delivery' && sameAsBillingAddress) {
            clearDeliveryAddressError('billingAddress')
            validateAddressDeliveryStatus('billingAddress')
        } else {
            clearDeliveryAddressError('deliveryAddress')
            validateAddressDeliveryStatus('deliveryAddress')
        }
    }, [selectedShippingOption, sameAsBillingAddress])

    useEffect(() => {
        if (enterBillingAddressManually) {
            clearErrors(['billingAddress.googleAddress'])
            validateAddressDeliveryStatus('billingAddress')
        } else {
            clearErrors(['billingAddress', 'cannotDeliveryToBillingAddress'])
        }
        resetField('sameAsBillingAddress', { defaultValue: false })
        setAddressDetails('billingAddress', selectedBillingGoogleAddress)
    }, [enterBillingAddressManually])

    useEffect(() => {
        if (enterDeliveryAddressManually) {
            clearErrors(['deliveryAddress'])
            validateAddressDeliveryStatus('deliveryAddress')
        } else {
            clearErrors(['deliveryAddress', 'cannotDeliveryToDeliveryAddress'])
        }
        setAddressDetails('deliveryAddress', selectedDeliveryGoogleAddress)
    }, [enterDeliveryAddressManually])

    useEffect(() => {
        const fetchBillingAddress = async () => {
            if ((isContactLoggedIn || (isGuestLoggedIn && guestUser)) && !billingGoogleAddressField) {
                await fetchGoogleAddressFromCustomer('billingAddress')
            }
        }
        fetchBillingAddress()
    }, [isContactLoggedIn, isGuestLoggedIn, guestUser])

    return (
        <Box
            component="article"
            display="flex"
            flexDirection="column"
            sx={classes.orderDetails}
        >
            <Box
                component="section"
                display="flex"
                sx={classes.orderDetailsSection}
            >
                <Box sx={classes.sectionTitle}>
                    <Typography
                        fontWeight={700}
                        mb={0.5}
                        textTransform={'uppercase'}
                        variant="body1"
                    >
                        {t('Personal details')}
                    </Typography>
                    <Typography variant="small">{t('All fields are required')}</Typography>
                </Box>
                <Grid
                    className="sectionFormGroup"
                    spacing={2}
                    container
                >
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            label={t('Full name')}
                            name="name"
                            rules={{ required: 'This field is required' }}
                        />
                    </Grid>
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            disabled={isContactLoggedIn}
                            inputType="email"
                            label={t('Email')}
                            name="email"
                            rules={{
                                required: 'This field is required',
                                pattern: {
                                    value: acceptedPatterns.email,
                                    message: 'Invalid email address',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            label={t('Company name')}
                            name="companyName"
                            rules={{ required: 'This field is required' }}
                        />
                    </Grid>
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            label={t('Phone number')}
                            name="phone"
                            rules={{ required: 'This field is required' }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box
                component="section"
                display="flex"
                sx={classes.orderDetailsSection}
            >
                <Typography
                    fontWeight={700}
                    sx={classes.sectionTitle}
                    textTransform={'uppercase'}
                    variant="body1"
                >
                    {t('Billing address')}
                </Typography>
                <Grid
                    className="sectionFormGroup"
                    spacing={2}
                    container
                >
                    <Grid
                        xs={12}
                        item
                    >
                        <FormInputAddress
                            control={control}
                            label={t('Search address')}
                            name="billingAddress.googleAddress"
                            readOnly={enterBillingAddressManually}
                            rules={{ required: 'This field is required' }}
                            shouldUnregister={true}
                            onAddressChanged={setAddressDetails}
                            onAddressClear={clearAddressDetails}
                        />
                    </Grid>

                    <Grid
                        xs={12}
                        item
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={enterBillingAddressManually}
                                    color="primary"
                                    size="small"
                                    onChange={() => setEnterBillingAddressManually(!enterBillingAddressManually)}
                                />
                            }
                            label={t('Enter billing address manually')}
                        />
                    </Grid>

                    {enterBillingAddressManually ? (
                        <>
                            <Grid
                                xs={6}
                                item
                            >
                                <FormInputText
                                    control={control}
                                    label={t('Address')}
                                    name="billingAddress.line1"
                                    rules={{ required: 'This field is required' }}
                                    onHandleChange={(e) => handleAddressFieldChange(e, 'billingAddress')}
                                />
                            </Grid>
                            <Grid
                                xs={6}
                                item
                            >
                                <FormInputText
                                    control={control}
                                    label={t('Unit/Suite/Floor/Office (optional)')}
                                    name="billingAddress.line2"
                                />
                            </Grid>
                            <Grid
                                xs={6}
                                item
                            >
                                <FormInputText
                                    control={control}
                                    label={t('Zip/Postal code')}
                                    name="billingAddress.postcode"
                                    onHandleChange={(e) => handleAddressFieldChange(e, 'billingAddress')}
                                />
                            </Grid>
                            <Grid
                                xs={6}
                                item
                            >
                                <FormInputText
                                    control={control}
                                    label={t('City/County/Town/Suburb')}
                                    name="billingAddress.city"
                                    rules={{ required: 'This field is required' }}
                                    onHandleChange={(e) => handleAddressFieldChange(e, 'billingAddress')}
                                />
                            </Grid>
                            <Grid
                                xs={6}
                                item
                            >
                                <FormInputText
                                    control={control}
                                    label={t('State/Province/Region')}
                                    name="billingAddress.state"
                                    rules={{ required: 'This field is required' }}
                                    onHandleChange={(e) => handleAddressFieldChange(e, 'billingAddress')}
                                />
                            </Grid>
                            <Grid
                                xs={6}
                                item
                            >
                                <FormInputText
                                    control={control}
                                    label={t('Country')}
                                    name="billingAddress.country"
                                    rules={{ required: 'This field is required' }}
                                    onHandleChange={(e) => handleAddressFieldChange(e, 'billingAddress')}
                                />
                            </Grid>

                            <Grid
                                xs={12}
                                item
                            >
                                <FormHelperText error={Boolean(formState?.errors?.invalidBillingAddress)}>
                                    {t(formState.errors?.invalidBillingAddress?.message)}
                                </FormHelperText>
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </Box>
            <Box
                component="section"
                display="flex"
                sx={classes.orderDetailsSection}
            >
                <Typography
                    fontWeight={700}
                    sx={classes.sectionTitle}
                    textTransform={'uppercase'}
                    variant="body1"
                >
                    {t('Shipping options')}
                </Typography>

                <Grid
                    className="sectionFormGroup"
                    spacing={2}
                    container
                >
                    <Grid
                        xs={12}
                        item
                    >
                        <FormInputRadio
                            control={control}
                            name="shippingOption"
                            options={shippingOptions}
                        />
                    </Grid>

                    {storeSettings?.webStorePickupEnabled && selectedShippingOption === 'pickup' ? (
                        <Grid
                            xs={12}
                            item
                        >
                            <Typography variant="body1">{t('Pickup from our address:')}</Typography>

                            <Typography
                                style={{ fontWeight: 700 }}
                                variant="body1"
                            >
                                {getAddressDetails(pickupAddress) || t('View our website for our current address')}
                            </Typography>
                        </Grid>
                    ) : null}

                    {storeSettings?.webStoreDeliveryEnabled &&
                    !!storeSettings?.webStoreDeliveryRegions.length &&
                    selectedShippingOption === 'delivery' ? (
                        <>
                            <Grid
                                xs={12}
                                item
                            >
                                <TbxTooltip
                                    title={
                                        hasCompletedBillingAddress || Boolean(billingGoogleAddressField?.place_id)
                                            ? ''
                                            : t('Please fulfill billing address details before selecting this option.')
                                    }
                                    arrow
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormInputCheckbox
                                            control={control}
                                            disabled={
                                                !hasCompletedBillingAddress
                                                    ? !billingGoogleAddressField?.place_id
                                                    : null
                                            }
                                            label={t('My delivery address is the same as my billing address')}
                                            name="sameAsBillingAddress"
                                        />
                                        {formState?.errors?.cannotDeliveryToBillingAddress ? (
                                            <Typography
                                                color="error"
                                                variant="caption"
                                            >
                                                {formState.errors.cannotDeliveryToBillingAddress?.message}
                                            </Typography>
                                        ) : null}
                                    </div>
                                </TbxTooltip>
                            </Grid>

                            {!sameAsBillingAddress ? (
                                <>
                                    <Grid
                                        xs={12}
                                        item
                                    >
                                        <FormInputAddress
                                            control={control}
                                            hideError={enterDeliveryAddressManually}
                                            label={t('Search address')}
                                            name="deliveryAddress.googleAddress"
                                            readOnly={enterDeliveryAddressManually}
                                            rules={{ required: t('This field is required') }}
                                            shouldUnregister={true}
                                            onAddressChanged={setAddressDetails}
                                            onAddressClear={clearAddressDetails}
                                        />

                                        {!enterDeliveryAddressManually ? (
                                            <Grid
                                                xs={12}
                                                item
                                            >
                                                <FormHelperText
                                                    error={Boolean(formState?.errors?.cannotDeliveryToDeliveryAddress)}
                                                >
                                                    {t(formState.errors?.cannotDeliveryToDeliveryAddress?.message)}
                                                </FormHelperText>
                                            </Grid>
                                        ) : null}
                                    </Grid>

                                    <Grid
                                        xs={12}
                                        item
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={enterDeliveryAddressManually}
                                                    color="primary"
                                                    size="small"
                                                    onChange={() =>
                                                        setEnterDeliveryAddressManually(!enterDeliveryAddressManually)
                                                    }
                                                />
                                            }
                                            label={t('Enter delivery address manually')}
                                        />
                                    </Grid>

                                    {enterDeliveryAddressManually ? (
                                        <>
                                            <Grid
                                                xs={6}
                                                item
                                            >
                                                <FormInputText
                                                    control={control}
                                                    disabled={!enterDeliveryAddressManually}
                                                    label={t('Address')}
                                                    name="deliveryAddress.line1"
                                                    rules={{ required: 'This field is required' }}
                                                    onHandleChange={(e) =>
                                                        handleAddressFieldChange(e, 'deliveryAddress')
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                xs={6}
                                                item
                                            >
                                                <FormInputText
                                                    control={control}
                                                    label={t('Unit/Suite/Floor/Office (optional)')}
                                                    name="deliveryAddress.line2"
                                                />
                                            </Grid>
                                            <Grid
                                                xs={6}
                                                item
                                            >
                                                <FormInputText
                                                    control={control}
                                                    disabled={!enterDeliveryAddressManually}
                                                    label={t('Post Code')}
                                                    name="deliveryAddress.postcode"
                                                    onHandleChange={(e) =>
                                                        handleAddressFieldChange(e, 'deliveryAddress')
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                xs={6}
                                                item
                                            >
                                                <FormInputText
                                                    control={control}
                                                    disabled={!enterDeliveryAddressManually}
                                                    label={t('City')}
                                                    name="deliveryAddress.city"
                                                    rules={{ required: 'This field is required' }}
                                                    onHandleChange={(e) =>
                                                        handleAddressFieldChange(e, 'deliveryAddress')
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                xs={6}
                                                item
                                            >
                                                <FormInputText
                                                    control={control}
                                                    disabled={!enterDeliveryAddressManually}
                                                    label={t('State')}
                                                    name="deliveryAddress.state"
                                                    rules={{ required: 'This field is required' }}
                                                    onHandleChange={(e) =>
                                                        handleAddressFieldChange(e, 'deliveryAddress')
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                xs={6}
                                                item
                                            >
                                                <FormInputText
                                                    control={control}
                                                    disabled={!enterDeliveryAddressManually}
                                                    label={t('Country')}
                                                    name="deliveryAddress.country"
                                                    rules={{ required: 'This field is required' }}
                                                    onHandleChange={(e) =>
                                                        handleAddressFieldChange(e, 'deliveryAddress')
                                                    }
                                                />
                                            </Grid>

                                            <Grid
                                                xs={12}
                                                item
                                            >
                                                <FormHelperText
                                                    error={
                                                        Boolean(formState?.errors?.cannotDeliveryToDeliveryAddress) ||
                                                        Boolean(formState?.errors?.invalidDeliveryAddress)
                                                    }
                                                >
                                                    {t(formState.errors?.cannotDeliveryToDeliveryAddress?.message) ||
                                                        t(formState.errors?.invalidDeliveryAddress?.message) ||
                                                        ''}
                                                </FormHelperText>
                                            </Grid>
                                        </>
                                    ) : null}
                                </>
                            ) : null}

                            {deliveryPriceMethod === DELIVERY_PRICING_METHODS['SeparateCharge'].value ? (
                                <Grid
                                    xs={12}
                                    item
                                >
                                    <Typography
                                        style={{ fontWeight: 700 }}
                                        variant="body1"
                                    >
                                        {t(
                                            'We will contact you at your provided email address with a $t(quote) for an additional fee for delivery to your shipping address.'
                                        )}
                                    </Typography>
                                </Grid>
                            ) : null}

                            {deliveryPriceMethod === DELIVERY_PRICING_METHODS['PayUponDelivery'].value ? (
                                customerDeliveryProviderOption ? (
                                    <Grid
                                        xs={12}
                                        item
                                    >
                                        <Typography
                                            mb={1}
                                            style={{ fontWeight: 700 }}
                                            variant="body1"
                                        >
                                            {t('Freight collect: delivery is payable to the delivery provider')}
                                        </Typography>

                                        <FormControl>
                                            <Controller
                                                control={control}
                                                name="deliveryProviderToUse"
                                                render={({ field: { onChange, value } }) => (
                                                    <RadioGroup
                                                        value={value}
                                                        onChange={onChange}
                                                    >
                                                        <FormControlLabel
                                                            control={<Radio />}
                                                            label={t(
                                                                'Delivery is to be handled by {{deliveryProvider}}, our preferred delivery provider. The delivery fee will be payable directly to them upon delivery.',
                                                                {
                                                                    deliveryProvider,
                                                                }
                                                            )}
                                                            value="defaultProvider"
                                                        />
                                                        <FormControlLabel
                                                            control={<Radio />}
                                                            label={t(
                                                                ' Specify your preferred delivery provider and account number. The delivery fee will be payable directly to them upon delivery.'
                                                            )}
                                                            value="customerProvider"
                                                        />
                                                    </RadioGroup>
                                                )}
                                            />
                                        </FormControl>

                                        {deliveryProviderToUse === 'customerProvider' ? (
                                            <Box
                                                display="flex"
                                                gap={3}
                                                marginBlock={2}
                                                paddingInline={4}
                                            >
                                                <FormInputText
                                                    control={control}
                                                    label={t('Delivery provider name')}
                                                    name="customerDeliveryProvider"
                                                    rules={{ required: 'This field is required' }}
                                                    shouldUnregister={true}
                                                    size="small"
                                                />
                                                <FormInputText
                                                    control={control}
                                                    label={t('Account number')}
                                                    name="customerDeliveryAccountNumber"
                                                    rules={{ required: 'This field is required' }}
                                                    shouldUnregister={true}
                                                    size="small"
                                                />
                                            </Box>
                                        ) : null}

                                        <FormControl
                                            error={formState.errors}
                                            required
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={deliveryProviderAgreement || false}
                                                        color="primary"
                                                        onChange={(e) =>
                                                            handleDeliveryProviderAgreement(e.target.checked)
                                                        }
                                                    />
                                                }
                                                label={
                                                    deliveryProviderToUse === 'customerProvider'
                                                        ? t(
                                                              'I agree to have my delivery handled by this delivery provider and authorise them to charge me directly for the delivery fee.'
                                                          )
                                                        : t(
                                                              'I agree to have {{deliveryProvider}} handle my delivery and authorise them to charge me directly for the delivery fee.',
                                                              {
                                                                  deliveryProvider,
                                                              }
                                                          )
                                                }
                                            />

                                            {formState.errors.deliveryProviderAgreement ? (
                                                <FormHelperText sx={{ marginLeft: 4, marginTop: -1 }}>
                                                    {t(formState.errors?.deliveryProviderAgreement.message)}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>
                                    </Grid>
                                ) : (
                                    <Grid
                                        xs={12}
                                        item
                                    >
                                        <Typography
                                            mb={1}
                                            style={{ fontWeight: 700 }}
                                            variant="body1"
                                        >
                                            {t('Freight collect: delivery is payable to the delivery provider')}
                                        </Typography>

                                        <Typography
                                            mb={1}
                                            variant="body1"
                                        >
                                            {t(
                                                'Your delivery will be handled by {{deliveryProvider}}. The delivery fee will be payable directly to them upon delivery.',
                                                {
                                                    deliveryProvider,
                                                }
                                            )}
                                        </Typography>

                                        <FormControl
                                            error={formState.errors}
                                            required
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={deliveryProviderAgreement}
                                                        color="primary"
                                                        onChange={(e) =>
                                                            handleDeliveryProviderAgreement(e.target.checked)
                                                        }
                                                    />
                                                }
                                                label={t(
                                                    'I agree to have {{deliveryProvider}} handle my delivery and authorise them to charge me directly for the delivery fee.',
                                                    {
                                                        deliveryProvider,
                                                    }
                                                )}
                                            />

                                            {formState.errors.deliveryProviderAgreement ? (
                                                <FormHelperText sx={{ marginLeft: 4, marginTop: -1 }}>
                                                    {t(formState.errors?.deliveryProviderAgreement.message)}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>
                                    </Grid>
                                )
                            ) : null}
                        </>
                    ) : null}
                </Grid>
            </Box>
        </Box>
    )
}

//props validation
OrderDetails.propTypes = {
    form: PropTypes.object.isRequired,
    customerDeliveryProviderOption: PropTypes.bool,
    deliveryPriceMethod: PropTypes.string,
    deliveryProvider: PropTypes.string,
}

export default OrderDetails
