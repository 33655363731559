import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectLocale } from '@/app/slices/appSlice'
import { selectCurrencyCode } from '@/app/slices/organisationSlice'
import { getCurrencyFormatter } from '@/common/utils'

export function useCurrencyFormatter(props) {
    const { currency, locale, ...options } = props || {}
    const appCurrency = useSelector(selectCurrencyCode)
    const appLocale = useSelector(selectLocale)
    const defaultCurrency = 'USD'
    const defaultLocale = 'en-US'

    const localeToUse = locale || appLocale || defaultLocale
    const currencyCodeToUse = currency || appCurrency || defaultCurrency

    const formatter = getCurrencyFormatter(currencyCodeToUse, localeToUse, options)

    const formatCurrency = useCallback(
        (value) => (value != null && !isNaN(value) ? formatter.format(value) : null),

        [formatter]
    )

    return {
        formatCurrency,
        formatter,
    }
}
