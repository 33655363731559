import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import * as DOMPurify from 'dompurify'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { DateTime, OrganisationLogo } from '@/common/components'
import { useNumberFormatter, useToolBoxTreatments } from '@/common/hooks'
import {
    DELIVERY_PRICING_METHODS,
    getFormattedPrice,
    hasSevereValidationIssues,
    PUBLIC_DOCUMENTS,
    quoteItemHasErrors,
} from '@/common/utils'

import AccountingDocumentsCustomerInfo from '../../components/AccountingDocumentsCustomerInfo'
import TotalTableRow from '../../components/TotalTableRow'

const classes = {
    documentContainer: {
        width: 1024,
        margin: '24px auto',
    },
    card: {
        backgroundColor: 'common.white',
        border: 1,
        borderColor: 'grey.300',
        borderRadius: 2,
        py: 3,
        px: 3,
        my: 3,
    },
    noPageBreak: {
        breakInside: 'avoid',
    },
    footerTextContent: {
        '& p': {
            margin: 0,
        },
        '& p:first-of-type': {
            color: 'text.primary',
            '@media print': {
                color: '#2b2b2b',
            },
        },
    },
    footer: {
        '@media print': {
            position: 'relative',
            textAlign: 'center',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 0)',
        },
    },
}

const TaxInvoice = ({
    customer,
    customerContact,
    issueSeverityDictionary,
    organisation,
    paymentsEnabled = false,
    quote,
    showShipping,
    taxRate,
}) => {
    const { t } = useTranslation()

    const locale = useSelector(selectLocale)

    const { n: q } = useNumberFormatter({ numberOfDecimalPlaces: 0 })

    const partHasAnyErrors = (part) => {
        if ('materialId' in part) {
            const isNull = part === null
            const hasErrors =
                quoteItemHasErrors(part, organisation.defaultDrawingUnits, issueSeverityDictionary) ||
                hasSevereValidationIssues(part)
            return isNull || hasErrors
        }
        return false
    }

    const sortQuoteItems = (quoteItems, miscItems) => {
        const qi = quoteItems.toSorted((a, b) => a.index - b.index)
        const mi = miscItems.toSorted((a, b) => a.index - b.index)
        return qi.concat(mi)
    }

    const deliveryPricingMethod = showShipping
        ? DELIVERY_PRICING_METHODS[quote.deliveryPricingMethod]?.documentLabel
        : ''

    const pickupAddress = organisation?.pickupLine1
        ? {
              line1: organisation?.pickupLine1,
              line2: organisation?.pickupLine2,
              city: organisation?.pickupCity,
              state: organisation?.pickupState,
              postcode: organisation?.pickupPostcode,
              country: organisation?.pickupCountry,
          }
        : organisation?.pickupAddress

    return quote && quote.organisationId && quote.quoteItems ? (
        <Box sx={classes.documentContainer}>
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                id="QuoteHeader"
                sx={classes.card}
            >
                <Typography
                    textTransform="uppercase"
                    variant="h4"
                >
                    {t('Tax Invoice')}
                </Typography>

                <Box
                    alignItems="flex-end"
                    display="flex"
                    justifyContent="space-between"
                >
                    {customer ? (
                        <AccountingDocumentsCustomerInfo
                            billingAddress={quote.billingAddress}
                            customer={customer}
                            customerContact={customerContact}
                            deliveryAddress={quote.deliveryAddress}
                            deliveryOption={quote.deliveryOption}
                            deliveryPricingMethod={deliveryPricingMethod}
                            lastModifiedByUserName={quote.lastModifiedByUserName}
                            pickupAddress={pickupAddress}
                        />
                    ) : null}

                    <Box
                        alignItems="flex-end"
                        alignSelf="stretch"
                        className="quote-logo-info"
                        display="flex"
                        flex={1}
                        flexDirection="column"
                        gap={2}
                        justifyContent="space-between"
                    >
                        <OrganisationLogo
                            organisationId={quote.organisationId}
                            organisationLogoUri={organisation.logoUri}
                            wrapperHeight={100}
                            wrapperWidth="auto"
                        />
                        <Box
                            alignItems="flex-end"
                            className="quote-details-info"
                            display="flex"
                            flexDirection="column"
                            gap={0.5}
                        >
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {t('Invoice number:')}
                                </Typography>
                                <Typography
                                    minWidth={80}
                                    textAlign="right"
                                    variant="strong2"
                                >
                                    {quote.invoiceNumberText}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {t('PO number:')}
                                </Typography>
                                <Typography
                                    minWidth={80}
                                    textAlign="right"
                                    variant="strong2"
                                >
                                    {quote.poNumber}
                                </Typography>
                            </Box>

                            {quote.invoiceIssueDateUtc ? (
                                <Box
                                    display="flex"
                                    gap={1}
                                >
                                    <Typography
                                        color="text.secondary"
                                        variant="body2"
                                    >
                                        {t('Issue date:')}
                                    </Typography>
                                    <Typography
                                        minWidth={80}
                                        textAlign="right"
                                        variant="body2"
                                    >
                                        <DateTime
                                            data-testid="invoice-issue-date"
                                            format="DD-MMM-YYYY"
                                            locale={locale}
                                        >
                                            {quote.invoiceIssueDateUtc}
                                        </DateTime>
                                    </Typography>
                                </Box>
                            ) : null}

                            {quote.invoiceDueDateUtc ? (
                                <Box
                                    display="flex"
                                    gap={1}
                                >
                                    <Typography
                                        color="text.secondary"
                                        variant="body2"
                                    >
                                        {t('Due date:')}
                                    </Typography>
                                    <Typography
                                        minWidth={80}
                                        textAlign="right"
                                        variant="body2"
                                    >
                                        <DateTime
                                            data-testid="invoice-due-date"
                                            format="DD-MMM-YYYY"
                                            locale={locale}
                                        >
                                            {quote.invoiceDueDateUtc}
                                        </DateTime>
                                    </Typography>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box
                id="QuoteContent"
                sx={classes.card}
            >
                <TableContainer>
                    <Table
                        aria-label="Prices table"
                        sx={{ minWidth: 952 }}
                    >
                        <TableHead>
                            <TableRow sx={{ verticalAlign: 'bottom', height: 60 }}>
                                <TableCell
                                    align="left"
                                    width={30}
                                >
                                    #
                                </TableCell>
                                <TableCell
                                    align="left"
                                    width={150}
                                >
                                    {t('Part ID')}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    width={400}
                                >
                                    {t('Part name')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{ borderRight: '1px dashed rgba(52, 73, 94, 0.38)' }}
                                    width={80}
                                >
                                    {t('Quantity')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width={120}
                                >
                                    {t('Unit price')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width={120}
                                >
                                    {t('Line total')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortQuoteItems(quote.quoteItems, quote.miscellaneousItems).map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                        sx={{ py: 1.5 }}
                                    >
                                        {index + 1}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{ py: 1.5 }}
                                    >
                                        {row?.partId ? row?.partId : t('N/A')}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{ py: 1.5 }}
                                    >
                                        {row?.name}
                                    </TableCell>

                                    <TableCell
                                        align="right"
                                        sx={{
                                            py: 1.5,
                                            borderRight: '1px dashed rgba(52, 73, 94, 0.38)',
                                            typography: 'strong2',
                                        }}
                                    >
                                        {q(row.quantity)}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{ py: 1.5, typography: 'strong2' }}
                                    >
                                        {!partHasAnyErrors(row)
                                            ? getFormattedPrice(
                                                  row.itemPrice,
                                                  organisation.currencyCode,
                                                  organisation.locale
                                              )
                                            : null}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{ py: 1.5, typography: 'strong2' }}
                                    >
                                        {!partHasAnyErrors(row)
                                            ? getFormattedPrice(
                                                  row.linePrice,
                                                  organisation.currencyCode,
                                                  organisation.locale
                                              )
                                            : null}
                                    </TableCell>
                                </TableRow>
                            ))}

                            <TotalTableRow
                                colspan={[2, 5]}
                                currencyCode={organisation.currencyCode}
                                deliveryOption={quote.deliveryOption}
                                deliveryPrice={quote.deliveryPrice}
                                deliveryPricingMethod={quote.deliveryPricingMethod}
                                document={PUBLIC_DOCUMENTS.Quote}
                                linePrice={quote.linePrice}
                                locale={locale}
                                minimumQuoteChargePrice={quote.minimumQuoteChargePrice}
                                organisationId={quote.organisationId}
                                paymentsEnabled={paymentsEnabled}
                                paymentStatus={quote.paymentStatus}
                                quoteId={quote.id}
                                quoteStatus={quote.status}
                                taxRate={taxRate}
                                totalAmount={quote.lineTaxedPrice}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {organisation.paymentNotes || organisation.contactDetails ? (
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    id="QuoteFooter"
                    justifyContent="space-between"
                    sx={[classes.card, classes.noPageBreak]}
                >
                    {organisation?.paymentNotes ? (
                        <Typography
                            alignItems="flex-start"
                            color="text.secondary"
                            component="div"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(organisation.paymentNotes) }}
                            display="flex"
                            flex={1}
                            flexDirection="column"
                            gap={1}
                            sx={classes.footerTextContent}
                            textAlign="left"
                            variant="body2"
                            width="50%"
                        />
                    ) : null}
                    {organisation?.contactDetails ? (
                        <Typography
                            alignItems="flex-end"
                            color="text.secondary"
                            component="div"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(organisation.contactDetails) }}
                            display="flex"
                            flex={1}
                            flexDirection="column"
                            gap={1}
                            sx={classes.footerTextContent}
                            textAlign="right"
                            variant="body2"
                            width="50%"
                        />
                    ) : null}
                </Box>
            ) : null}

            {!import.meta.env.VITE_RESELLER ? (
                <Box
                    component="footer"
                    sx={!organisation?.showBrandingInDocumentFooter ? { displayPrint: 'none' } : classes.footer}
                    textAlign="center"
                >
                    <Typography variant="small">
                        {t('Powered by')}{' '}
                        <Link
                            color="primary"
                            href={`https://tempustools.com?org_id=${quote.organisationId}&utm_source=quotemate&utm_medium=virality&utm_campaign=document-branding&utm_content=tax-invoice`}
                            target="_blank"
                            underline="always"
                        >
                            Tempus Tools
                        </Link>
                    </Typography>
                </Box>
            ) : null}
        </Box>
    ) : null
}

TaxInvoice.propTypes = {
    customer: PropTypes.object,
    customerContact: PropTypes.object,
    issueSeverityDictionary: PropTypes.object,
    organisation: PropTypes.object,
    paymentsEnabled: PropTypes.bool,
    quote: PropTypes.object,
    taxRate: PropTypes.object,
}

export default TaxInvoice
