import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExpandMore } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'

import { ThumbnailImage } from '@/common/components'

const classes = {
    root: {
        width: '100%',
    },
    partList: {
        width: '100%',
        backgroundColor: 'hsl(0, 0%, 15%)',
    },
    partListNqp: {
        width: '100%',
        backgroundColor: 'hsl(0, 0%, 20%)',
        padding: 0,
    },
    heading: {
        fontSize: '1rem',
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    accordion: {
        boxShadow: 'none',
    },
    accordionNqp: {
        boxShadow: 'none',
    },
    accordionSummary: {
        backgroundColor: 'hsl(0, 0%, 15%)',
        borderBottom: '1px solid hsl(0, 0%, 20%)',
        minHeight: '64px',
    },
    accordionPanelDetails: {
        width: '100%',
        padding: 0,
    },
    expandIcon: {
        color: 'white',
    },
    expandIconNqp: {
        color: 'white',
    },
    part: {
        cursor: 'pointer',
    },
    selectedPart: {
        cursor: 'pointer',
        backgroundColor: 'hsl(0, 0%, 25%)',
    },
    partImage: {
        marginRight: 3,
    },
}

const PartCategoryPanel = ({ categoryName, expanded, nqp, onPartSelected, parts, selectedPart }) => {
    const { t } = useTranslation()

    const [panelExpanded, setPanelExpanded] = useState(false)

    useEffect(() => {
        setPanelExpanded(expanded)
    }, [expanded])

    const handlePartSelected = (partId) => {
        if (typeof onPartSelected === 'function') {
            onPartSelected(partId)
        }
    }

    const handlePanelChange = (_, isExpanded) => {
        setPanelExpanded(isExpanded)
    }

    const getPartThumbnailUrl = (partId) => {
        return `${import.meta.env.VITE_API_SERVER_URL}/quickparts/${partId}/thumbnail`
    }

    return (
        <Box sx={classes.root}>
            <Accordion
                expanded={panelExpanded}
                sx={nqp ? classes.accordionNqp : classes.accordion}
                square
                onChange={handlePanelChange}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore sx={nqp ? classes.expandIconNqp : classes.expandIcon} />}
                    sx={classes.accordionSummary}
                >
                    <Typography sx={classes.heading}>{t(categoryName)}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={classes.accordionPanelDetails}>
                    <List sx={nqp ? classes.partListNqp : classes.partList}>
                        {parts.map((part) => {
                            return (
                                <ListItem
                                    key={part.id}
                                    sx={
                                        selectedPart && selectedPart.id === part.id
                                            ? classes.selectedPart
                                            : classes.part
                                    }
                                    onClick={() => handlePartSelected(part.id)}
                                >
                                    <ThumbnailImage
                                        lazyLoading={true}
                                        mixBlendMode="normal"
                                        sx={classes.partImage}
                                        uri={getPartThumbnailUrl(part.id)}
                                        wrapperHeight={40}
                                        wrapperWidth={40}
                                    />

                                    <ListItemText primary={t(part.description)} />
                                </ListItem>
                            )
                        })}
                    </List>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

PartCategoryPanel.propTypes = {
    categoryName: PropTypes.string,
    expanded: PropTypes.bool,
    nqp: PropTypes.bool,
    parts: PropTypes.array,
    selectedPart: PropTypes.object,
    onPartSelected: PropTypes.func,
}

export default PartCategoryPanel
