import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Accordion } from '@mui/material'
import PropTypes from 'prop-types'

import {
    addExpandedItem,
    removeExpandedItem,
    selectExpandedItems,
} from '@/app/slices/web-store/webStoreQuoteItemsSlice'

import { quoteItemErrors, quoteItemHasIssues, quoteItemWarnings } from '../../../helpers/quoteUtilities'

import ItemDetails from './ItemDetails'
import ItemSummary from './ItemSummary'

const classes = {
    AccordionRoot: {
        overflow: 'hidden',
        my: 2,
        borderRadius: 2,
        backgroundColor: 'background.default',
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded:first-of-type': {
            marginTop: 2,
        },
    },

    AccordionDetailsRoot: {
        backgroundColor: 'background.paper',
    },
}

const QuoteItem = ({ id, quoteItem }) => {
    const { attributes, isDragging, listeners, setActivatorNodeRef, setNodeRef, transform, transition } = useSortable({
        id: id,
    })

    const dispatch = useDispatch()

    const expandedItemsArray = useSelector(selectExpandedItems)

    const [isExpanded, setIsExpanded] = useState(true)

    const itemHasIssues = quoteItemHasIssues(quoteItem)

    const itemWarnings = quoteItemWarnings(quoteItem)

    const itemErrors = quoteItemErrors(quoteItem)

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : undefined,
        zIndex: isDragging ? 0 : undefined,
    }

    const handleExpandedState = () => {
        setIsExpanded(!isExpanded)
        if (isExpanded) {
            dispatch(removeExpandedItem(quoteItem.id))
        } else {
            dispatch(addExpandedItem(quoteItem.id))
        }
    }

    useEffect(() => {
        setIsExpanded(expandedItemsArray.includes(quoteItem.id))
    }, [expandedItemsArray, quoteItem.id])

    return (
        <Accordion
            expanded={isExpanded}
            ref={setNodeRef}
            style={style}
            sx={classes.AccordionRoot}
            variant={'outlined'}
            square
            {...attributes}
            onChange={() => handleExpandedState()}
        >
            <ItemSummary
                hasErrors={itemErrors}
                hasIssues={itemHasIssues}
                hasWarnings={itemWarnings}
                key={`summary-${quoteItem.id}`}
                listeners={listeners}
                quoteItem={quoteItem}
                setActivatorNodeRef={setActivatorNodeRef}
            />

            <ItemDetails
                key={`details-${quoteItem.id}`}
                quoteItem={quoteItem}
            />
        </Accordion>
    )
}

QuoteItem.propTypes = {
    id: PropTypes.string,
    index: PropTypes.number,
    quoteItem: PropTypes.object,
}

export default QuoteItem
