import { api } from './api'

const apiVersion = 'v2'

export const quotesApi = api
    .enhanceEndpoints({
        addTagTypes: ['Quotes'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getQuotes: builder.query({
                query: ({ organisationId, params }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/quotes`,
                    params: {
                        organisationId: organisationId,
                        pageNumber: params.pageNumber,
                        pageSize: params.pageSize,
                        searchTerm: params.searchTerm || '',
                        orderedByProperty: params.orderedByProperty,
                        orderedBy: params.orderedBy,
                        includeArchived: params.includeArchived,
                        status: params.status,
                        lastModifiedBy: params.lastModifiedBy,
                        lastModifiedFrom: params.lastModifiedFrom,
                        lastModifiedTo: params.lastModifiedTo,
                        source: params.source,
                    },
                }),
                providesTags: (result, _error, _arg) =>
                    result
                        ? [{ type: 'Quotes', id: 'LIST' }, ...result.quotes.map(({ id }) => ({ type: 'Quotes', id }))]
                        : [{ type: 'Quotes', id: 'LIST' }],
            }),

            getQuotesByCustomer: builder.query({
                query: ({ organisationId, params }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/quotes`,
                    params: {
                        organisationId: organisationId,
                        customerId: params.customerId,
                        pageNumber: params.pageNumber,
                        pageSize: params.pageSize,
                        searchTerm: params.searchTerm || '',
                        orderedByProperty: params.orderedByProperty,
                        orderedBy: params.orderedBy,
                        includeArchived: params.includeArchived,
                        status: params.status,
                    },
                }),
                providesTags: (result, _error, _arg) =>
                    result
                        ? [{ type: 'Quotes', id: 'LIST' }, ...result.quotes.map(({ id }) => ({ type: 'Quotes', id }))]
                        : [{ type: 'Quotes', id: 'LIST' }],
            }),

            createQuote: builder.mutation({
                query: ({ name, organisationId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}`,
                    method: 'POST',
                    body: {
                        name,
                    },
                }),
                invalidatesTags: [{ type: 'Quotes', id: 'LIST' }],
            }),

            getQuote: builder.query({
                query: ({ organisationId, params, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}`,
                    params,
                }),
                providesTags: (_result, _error, arg) => [{ type: 'Quotes', id: arg.quoteId }],
            }),

            getSharedQuote: builder.query({
                query: ({ params, quoteId }) => ({
                    url: `/${apiVersion}/quote/${null}/${quoteId}/shared`,
                    params,
                }),
                providesTags: (_result, _error, arg) => [{ type: 'Quotes', id: arg.quoteId }],
            }),

            updateQuote: builder.mutation({
                query: ({ organisationId, quote, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}`,
                    method: 'PUT',
                    body: quote,
                }),
                async onQueryStarted({ organisationId, quote, quoteId }, { dispatch, queryFulfilled }) {
                    dispatch(
                        quotesApi.util.updateQueryData('getQuote', { organisationId, quoteId }, (draft) => {
                            Object.assign(draft, quote)
                        })
                    )
                    try {
                        const res = await queryFulfilled

                        dispatch(
                            quotesApi.util.updateQueryData('getQuote', { organisationId, quoteId }, (draft) => {
                                Object.assign(draft, res.data)
                            })
                        )
                    } catch {
                        //patchResult.undo()
                        dispatch(quotesApi.util.invalidateTags([{ type: 'Quotes', id: quoteId }]))
                    }
                },
            }),

            enableQuote: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/enable`,
                    method: 'POST',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Quotes', id: arg.quoteId },
                    { type: 'QuoteItems', id: 'LIST' },
                ],
            }),

            orderQuote: builder.mutation({
                query: ({ organisationId, params, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/order`,
                    method: 'POST',
                    params,
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Quotes', id: arg.quoteId },
                    { type: 'QuoteItems', id: 'LIST' },
                ],
            }),

            invoiceQuote: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/invoice`,
                    method: 'POST',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Quotes', id: arg.quoteId },
                    { type: 'QuoteItems', id: 'LIST' },
                ],
            }),

            paragonInvoiceQuote: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/paragon-invoice`,
                    method: 'POST',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Quotes', id: arg.quoteId },
                    { type: 'QuoteItems', id: 'LIST' },
                ],
            }),

            unarchiveQuote: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/unarchive`,
                    method: 'PUT',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Quotes', id: 'LIST' },
                    { type: 'Quotes', id: arg.quoteId },
                ],
            }),

            deleteQuote: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Quotes', id: 'LIST' },
                    { type: 'Quotes', id: arg.quoteId },
                ],
            }),

            duplicateQuote: builder.mutation({
                query: ({ newName, organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/duplicate`,
                    method: 'POST',
                    body: {
                        newName,
                    },
                }),
                invalidatesTags: (_result, _error, _arg) => [{ type: 'Quotes', id: 'LIST' }],
            }),

            calculateQuote: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/calculate`,
                    method: 'POST',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Quotes', id: arg.quoteId },
                    { type: 'QuoteItems', id: 'LIST' },
                    { type: 'MiscItems', id: 'LIST' },
                    { type: 'PartLibraryEntries', id: 'LIST' },
                    { type: 'PriceDetails', id: 'LIST' },
                ],
            }),

            calculateDeliveryPrice: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/calculate-delivery-price`,
                    method: 'POST',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Quotes', id: arg.quoteId },
                    { type: 'PriceDetails', id: 'LIST' },
                ],
            }),

            calculateTaxRate: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/calculate-tax-rate`,
                    method: 'POST',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Quotes', id: arg.quoteId },
                    { type: 'PriceDetails', id: 'LIST' },
                ],
            }),

            getIssues: builder.query({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/issues`,
                }),
            }),

            sendInternalQuoteEmail: builder.mutation({
                query: ({ includePdf, message, organisationId, quoteId, subject, toEmailAddress }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/send`,
                    method: 'POST',
                    body: {
                        subject,
                        message,
                        toEmailAddress,
                        includePdf,
                    },
                }),
            }),
        }),
    })

export const selectQuotes = (state, query) => {
    return quotesApi.endpoints.getQuotes.select(query)(state)?.data ?? []
}

export const selectQuote = (state, query) => {
    return quotesApi.endpoints.getQuote.select(query)(state)?.data ?? {}
}

export const {
    useCalculateDeliveryPriceMutation,
    useCalculateQuoteMutation,
    useCalculateTaxRateMutation,
    useCreateQuoteMutation,
    useDeleteQuoteMutation,
    useDuplicateQuoteMutation,
    useEnableQuoteMutation,
    useGetIssuesQuery,
    useGetQuoteQuery,
    useGetQuotesByCustomerQuery,
    useGetQuotesQuery,
    useGetSharedQuoteQuery,
    useInvoiceQuoteMutation,
    useLazyGetIssuesQuery,
    useLazyGetQuoteQuery,
    useLazyGetQuotesByCustomerQuery,
    useLazyGetQuotesQuery,
    useLazyGetSharedQuoteQuery,
    useOrderQuoteMutation,
    useParagonInvoiceQuoteMutation,
    useSendInternalQuoteEmailMutation,
    useUnarchiveQuoteMutation,
    useUpdateQuoteMutation,
} = quotesApi
