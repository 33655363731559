import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

import { useGetTaxRatesQuery } from '@/app/services/taxRates'
import { selectUserRole, setAppBarState } from '@/app/slices/appSlice'
import {
    selectLastPaymentStatus,
    selectOrganisation,
    selectOrganisationId,
    setDefaultTaxRateId,
} from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { theme as lightTheme } from '@/common/themes/LightTheme'
import { StrikerThemeOverride } from '@/common/themes/overrides/StrikerThemeOverride'
import { WhitelabelThemeOverride } from '@/common/themes/overrides/WhitelabelThemeOverride'
import { Paths, setPageTitleAndFavicon, showResourceCentre } from '@/common/utils'

const pathsToHideAppBar = [
    Paths.SIGNOUT_PATHNAME,
    Paths.ERROR_PATHNAME,
    Paths.CUSTOMER_FORMATTED_QUOTE_PATHNAME,
    Paths.SHARED_PATHNAME,
]

const pathsToHideAllRegex = [
    new RegExp(`^${Paths.CUSTOMER_FORMATTED_QUOTE_PATHNAME}/`, 'g'),
    new RegExp(`^${Paths.SHARED_PATHNAME}/`, 'g'),
    new RegExp('^/projects/[0-9a-fA-F-]+/documents/[\\w-]+', 'g'),
    new RegExp('^/quotes/[0-9a-fA-F-]+/documents/[\\w-]+', 'g'),
]

const pathsToHideMyAccount = [Paths.WELCOME_PATHNAME, Paths.CUSTOMER_FORMATTED_QUOTE_PATHNAME, Paths.SHARED_PATHNAME]

const pathsToHideSubscription = [
    Paths.ACTIVATE_PATHNAME,
    Paths.ACTIVATE_BILLING_DETAILS_PATHNAME,
    Paths.ACTIVATE_PAYMENT_DETAILS_PATHNAME,
    Paths.NO_ACTIVE_SUBSCRIPTION_PATHNAME,
    Paths.ERROR_PATHNAME,
    Paths.WELCOME_PATHNAME,
    Paths.CUSTOMER_FORMATTED_QUOTE_PATHNAME,
    Paths.SHARED_PATHNAME,
]

const UserObserverContainer = ({ children }) => {
    const location = useLocation()
    const dispatch = useDispatch()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const lastPaymentStatus = useSelector(selectLastPaymentStatus)
    const userRole = useSelector(selectUserRole)

    const userIsAuthenticated = useIsAuthenticated()
    const { previewResellerName, showLibellulaWhitelabelling, showStrikerWhitelabelling } = useToolBoxTreatments()

    const { data: taxRates } = useGetTaxRatesQuery({ organisationId }, { skip: !organisationId })

    const theme = useMemo(
        () =>
            showLibellulaWhitelabelling
                ? WhitelabelThemeOverride(lightTheme)
                : showStrikerWhitelabelling
                  ? StrikerThemeOverride(lightTheme)
                  : lightTheme,
        [showLibellulaWhitelabelling, showStrikerWhitelabelling, lightTheme]
    )

    useEffect(() => {
        setPageTitleAndFavicon(previewResellerName)
    }, [previewResellerName])

    useEffect(() => {
        const hideAll = pathsToHideAllRegex.some((path) => location.pathname.match(path))

        dispatch(
            setAppBarState({
                menuHidden:
                    userIsAuthenticated &&
                    !!organisation &&
                    !organisation.hasActiveSubscription &&
                    (!organisation.trial || organisation.trial.hasExpired) &&
                    !organisation.freePlan,
                hidden: !userIsAuthenticated || hideAll || pathsToHideAppBar.includes(location.pathname),
                myAccountHidden: pathsToHideMyAccount.includes(location.pathname),
                showUpdatePaymentBar: lastPaymentStatus === 'failed' && userRole === 'owner',
                showSubscriptionBar:
                    organisation &&
                    !organisation.hasActiveSubscription &&
                    !pathsToHideSubscription.includes(location.pathname) &&
                    !organisation.freePlan,
                showTrialWithSubscriptionBar:
                    organisation &&
                    organisation.trial &&
                    !organisation.trial?.hasExpired &&
                    organisation.hasActiveSubscription &&
                    !pathsToHideSubscription.includes(location.pathname) &&
                    !organisation.freePlan,
                freePlan: organisation.freePlan,
                title: '',
                toolbarItems: [],
            })
        )
        showResourceCentre(!hideAll)
    }, [location, organisation, userIsAuthenticated, lastPaymentStatus, userRole])

    useEffect(() => {
        dispatch(setDefaultTaxRateId(taxRates?.find((taxRate) => taxRate.isDefault)?.id || null))
    }, [taxRates])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
}

UserObserverContainer.propTypes = {}

export default UserObserverContainer
