import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'

import { selectCurrentUserOrganisations } from '@/app/slices/appSlice'

const OrganisationSelect = ({
    disabled = false,
    inputProps,
    label = 'Organisation',
    onChange,
    onClick,
    size = 'medium',
    sx,
    value = '',
    variant = 'standard',
}) => {
    const { t } = useTranslation()

    const currentUserOrganisations = useSelector(selectCurrentUserOrganisations)

    const [selectedValue, setSelectedValue] = useState('')

    const handleChange = (event) => {
        const selectedOrganisationId = event.target.value
        setSelectedValue(selectedOrganisationId)

        if (typeof onChange === 'function') {
            onChange(selectedOrganisationId)
        }
    }

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    return (
        <FormControl
            size={size}
            variant={variant}
            fullWidth
        >
            <InputLabel data-testid="select-organisation-label">{t(label)}</InputLabel>
            <Select
                disabled={disabled}
                fullWidth={true}
                inputProps={inputProps}
                label={t(label)}
                sx={[sx, ...(Array.isArray(sx) ? sx : [sx])]}
                value={selectedValue ?? ''}
                onChange={handleChange}
                onClick={onClick}
            >
                {currentUserOrganisations?.map((org) => (
                    <MenuItem
                        key={org.organisationId}
                        value={org.organisationId}
                    >
                        {org.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

OrganisationSelect.propTypes = {
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,
    label: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium']),
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    value: PropTypes.string,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
    onChange: PropTypes.func,
    onClick: PropTypes.func,
}

export default OrganisationSelect
