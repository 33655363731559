import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Divider, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import { useGetMiscItemsQuery, useUpdateMiscItemMutation } from '@/app/services/miscItems'
import { useCalculateQuoteMutation, useGetQuoteQuery } from '@/app/services/quotes'
import { selectLocale } from '@/app/slices/appSlice'
import { selectCurrencyCode, selectCurrencySymbol, selectOrganisationId } from '@/app/slices/organisationSlice'
import { selectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import { fractionToPercentage, getCurrencyFormat, getFormattedPrice, QUOTE_STATUS } from '@/common/utils'
import { formatCurrency } from '@/features/web-store/helpers/utilities'

const classes = {
    pricesSection: {
        width: '300px',
        gap: '12px',
    },
    pricePercentage: {
        fontSize: '14px',
        color: 'text.secondary',
    },
    bold: {
        fontWeight: 'bold',
    },
    itemColumn: {
        display: 'flex',
        flex: 1,
    },
    alignRight: {
        justifyContent: 'flex-end',
    },
    inputColumn: {
        pl: 2,
    },
}

const ItemRow = ({ children }) => (
    <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
    >
        {children}
    </Box>
)

const MiscItemPrices = ({ miscItemId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const currencySymbol = useSelector(selectCurrencySymbol)
    const locale = useSelector(selectLocale)
    const currencyCode = useSelector(selectCurrencyCode)

    const { isFetching: isFetchingQuote } = useGetQuoteQuery({ organisationId, quoteId })
    const quoteNotCalculated = useSelector(selectedQuoteStatus) !== QUOTE_STATUS.Calculated
    const { miscItem } = useGetMiscItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                miscItem: data?.find((item) => item.id === miscItemId),
            }),
        }
    )

    const [calculateQuoteMutation, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-quote',
    })

    const [updateMiscItem] = useUpdateMiscItemMutation()

    const { itemPrice, linePrice, price, priceAdjustment } = miscItem

    const currencyFormat = getCurrencyFormat(currencyCode, locale)
    const placeholder = getFormattedPrice(0, currencyCode, locale, true)

    const [percentageAdjustment, setPercentageAdjustment] = useState(fractionToPercentage(priceAdjustment / price, 0))
    const [amountAdjustment, setAmountAdjustment] = useState(priceAdjustment)

    const calculateQuote = async () => {
        try {
            await calculateQuoteMutation({ organisationId, quoteId }).unwrap()
        } catch (error) {
            const errorMessage = t(error.data)
            enqueueSnackbar(errorMessage, { variant: 'error' })
        }
    }

    const handleUpdateQuoteItem = async (miscItem) => {
        try {
            await updateMiscItem({
                organisationId,
                quoteId,
                miscItemId: miscItem?.id,
                miscItem,
            }).unwrap()
            calculateQuote()
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const debounceHandleUpdateQuoteItem = useDebouncedCallback(handleUpdateQuoteItem, 1000)

    const handlePercentageAdjustmentChange = async (values, sourceInfo) => {
        if (sourceInfo.source === 'event') {
            debounceHandleUpdateQuoteItem.cancel()
            const { floatValue } = values
            setPercentageAdjustment(floatValue)
            const amount = (floatValue * price) / 100
            setAmountAdjustment(amount)
            debounceHandleUpdateQuoteItem({ ...miscItem, priceAdjustment: amount })
        }
    }

    const handleAmountAdjustmentChange = async (values, sourceInfo) => {
        if (sourceInfo.source === 'event') {
            debounceHandleUpdateQuoteItem.cancel()
            const { floatValue } = values
            setAmountAdjustment(floatValue)
            const percentage = fractionToPercentage(floatValue / price, 0)
            setPercentageAdjustment(percentage)
            debounceHandleUpdateQuoteItem({ ...miscItem, priceAdjustment: floatValue })
        }
    }

    return (
        <Box
            alignItems="flex-start"
            display="flex"
            flex="0 0 auto"
            flexDirection="column"
            justifyContent="space-between"
            sx={classes.pricesSection}
        >
            <ItemRow>
                <Typography
                    data-testid="quote-item-prices-section-title"
                    sx={classes.bold}
                >
                    {t('Prices')}
                </Typography>
            </ItemRow>

            <ItemRow>
                <Typography
                    data-testid="nqp-quote-item-subtotal-label"
                    sx={classes.bold}
                >
                    {t('Subtotal')}
                </Typography>
                <Typography sx={classes.bold}>
                    {quoteNotCalculated || isCalculating || isFetchingQuote || !price
                        ? String.fromCharCode(8212)
                        : formatCurrency(price, currencyCode, locale)}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography data-testid="nqp-quote-item-adjustment-label">{t('Adjust')}</Typography>
                <Typography sx={[classes.itemColumn, classes.alignRight, classes.inputColumn]}>
                    <NumericFormat
                        allowNegative={true}
                        customInput={TextField}
                        decimalSeparator={currencyFormat.decimal}
                        disabled={quoteNotCalculated}
                        inputProps={{
                            style: { textAlign: 'right' },
                            'data-testid': 'nqp-quote-misc-item-adjustment-text',
                        }}
                        placeholder="0 %"
                        size="small"
                        suffix=" %"
                        sx={{ '.MuiInputRoot.MuiDisabled': classes.disabled }}
                        thousandSeparator={currencyFormat.group}
                        value={quoteNotCalculated ? 0 : percentageAdjustment}
                        variant="outlined"
                        onValueChange={handlePercentageAdjustmentChange}
                    />
                </Typography>
                <Typography
                    data-testid="nqp-quote-item-adjustment-value"
                    sx={[classes.itemColumn, classes.alignRight, classes.inputColumn]}
                >
                    <NumericFormat
                        allowNegative={true}
                        customInput={TextField}
                        decimalScale={currencyFormat.decimalPlaces}
                        decimalSeparator={currencyFormat.decimal}
                        disabled={quoteNotCalculated}
                        fixedDecimalScale={true}
                        inputProps={{
                            style: { textAlign: 'right' },
                        }}
                        placeholder={placeholder}
                        prefix={currencyFormat.currencySymbolPosition === 'before' ? currencySymbol : undefined}
                        size="small"
                        suffix={currencyFormat.currencySymbolPosition === 'after' ? currencySymbol : undefined}
                        sx={{ '.MuiInputRoot.MuiDisabled': classes.disabled }}
                        thousandSeparator={currencyFormat.group}
                        value={amountAdjustment ?? null}
                        variant="outlined"
                        onValueChange={handleAmountAdjustmentChange}
                    />
                </Typography>
            </ItemRow>
            <Divider
                orientation="horizontal"
                flexItem
            />
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-item-unit-price-label"
                    sx={[classes.itemColumn, classes.bold]}
                >
                    {t('Unit price')}
                </Typography>
                <Typography sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight, classes.bold]}>
                    100%
                </Typography>
                <Typography sx={[classes.itemColumn, classes.alignRight, classes.bold]}>
                    {quoteNotCalculated || isCalculating || isFetchingQuote || !itemPrice
                        ? String.fromCharCode(8212)
                        : formatCurrency(itemPrice, currencyCode, locale)}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-item-line-price-label"
                    sx={classes.bold}
                >
                    {t('Line price')}
                </Typography>
                <Typography sx={classes.bold}>
                    {quoteNotCalculated || isCalculating || isFetchingQuote || !linePrice
                        ? String.fromCharCode(8212)
                        : formatCurrency(linePrice, currencyCode, locale)}
                </Typography>
            </ItemRow>
        </Box>
    )
}

MiscItemPrices.propTypes = {
    miscItemId: PropTypes.string,
}

export default memo(MiscItemPrices)
