import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useCreateCustomerMutation } from '@/app/services/customers'
import { selectCustomers, setSelectedCustomer, setSelectedCustomerId } from '@/app/slices/customersSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

const classes = {
    title: {
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px',
        color: 'text.primary',
        marginBottom: '24px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '24px',
        paddingBottom: '36px', // leave room at the bottom of the form for any hovering action buttons being injected
    },
    input: {
        marginBottom: '16px',
    },
    button: {
        fontWeight: 500,
        minWidth: '142px',
        height: '36px',
    },
    addressTitle: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
        color: 'text.primary',
        margin: '16px 0',
    },
    checkboxLabel: {
        color: '#5E7387',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        letterSpacing: '0px',
    },
}

const AddCustomerForm = ({ onCancel, onCreate }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)

    const customers = useSelector(selectCustomers)

    const [createCustomer] = useCreateCustomerMutation()

    const [isSaving, setIsSaving] = useState(false)
    const [useOneAddress, setUseOneAddress] = useState(true)

    const { control, formState, handleSubmit, setValue } = useForm({
        mode: 'all',
        defaultValues: {
            companyName: '',
            accountCode: '',
            billingAddress: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                country: '',
                postcode: '',
            },
            deliveryAddress: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                country: '',
                postcode: '',
            },
            useOneAddress: true,
        },
    })

    //check if company name is unique
    const nameIsUnique = (name) => {
        return (
            customers.every((customer) => customer.companyName?.toLowerCase() !== name?.toLowerCase().trim()) ||
            t('Company name already exists')
        )
    }

    const handleAddCustomerButtonClick = async (data, _) => {
        setIsSaving(true)

        try {
            const newCustomer = await createCustomer({ organisationId, customer: data }).unwrap()

            dispatch(setSelectedCustomerId(newCustomer.customerId))
            dispatch(setSelectedCustomer(newCustomer))

            enqueueSnackbar(t('Customer {{customerName}} has been added', { customerName: data.companyName }), {
                variant: 'success',
            })
            onCreate(newCustomer)
        } catch (error) {
            enqueueSnackbar(t('Failed to add customer'), {
                variant: 'error',
            })
            onCancel()
        } finally {
            setIsSaving(false)
        }
    }

    const handleUseOneAddressClick = (event) => {
        setUseOneAddress(event.target.checked)
        setValue('useOneAddress', event.target.checked)
    }

    return (
        <>
            <Typography sx={classes.title}>{t('Add new customer')}</Typography>
            <Controller
                control={control}
                name="companyName"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('Company Name')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Required', validate: nameIsUnique }}
            />
            <Controller
                control={control}
                name="accountCode"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('Account Code')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Typography sx={classes.addressTitle}>{t('Delivery Address')}</Typography>

            <Controller
                control={control}
                name="deliveryAddress.line1"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('Address line 1')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="deliveryAddress.line2"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('Address line 2')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="deliveryAddress.city"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('City')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="deliveryAddress.state"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('State')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="deliveryAddress.postcode"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('Post Code')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="deliveryAddress.country"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('Country')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={useOneAddress}
                        color="primary"
                        name="checked"
                        size="small"
                        onChange={handleUseOneAddressClick}
                    />
                }
                label={
                    <Typography sx={classes.checkboxLabel}>
                        {t('My billing address is the same as my delivery address')}
                    </Typography>
                }
            />

            {!useOneAddress ? (
                <>
                    <Typography sx={classes.addressTitle}>{t('Billing Address')}</Typography>

                    <Controller
                        control={control}
                        name="billingAddress.line1"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                label={t('Address line 1')}
                                sx={classes.input}
                                value={value}
                                fullWidth
                                required
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="billingAddress.line2"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                label={t('Address line 2')}
                                sx={classes.input}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="billingAddress.city"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                label={t('City')}
                                sx={classes.input}
                                value={value}
                                fullWidth
                                required
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="billingAddress.state"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                label={t('State')}
                                sx={classes.input}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="billingAddress.postcode"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                label={t('Post Code')}
                                sx={classes.input}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="billingAddress.country"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                label={t('Country')}
                                sx={classes.input}
                                value={value}
                                fullWidth
                                required
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                    />
                </>
            ) : null}

            <Box sx={classes.buttonsContainer}>
                <Button
                    color="primary"
                    disabled={isSaving}
                    key="cancel"
                    sx={classes.button}
                    variant="outlined"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!formState.isValid || isSaving}
                    key="update"
                    sx={classes.button}
                    variant="contained"
                    onClick={handleSubmit(handleAddCustomerButtonClick)}
                >
                    {isSaving ? t('Saving customer...') : t('Add customer')}
                </Button>
            </Box>
        </>
    )
}

AddCustomerForm.propTypes = {
    onCancel: PropTypes.func,
    onCreate: PropTypes.func,
}

export default AddCustomerForm
