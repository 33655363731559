import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'

import { useGetMiscItemsQuery, useUpdateMiscItemsMutation } from '@/app/services/miscItems'
import { useCalculateQuoteMutation, useGetQuoteQuery, useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectMiscFilterOptions } from '@/app/slices/miscItemsSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { setIsCalculatingQuote } from '@/app/slices/quoteItemsSlice'
import { QUOTE_STATUS } from '@/common/utils'

import MiscItem from './MiscItemContent/MiscItem'
import MiscellaneousItemOverlay from './MiscellaneousItemOverlay'

const MiscellaneousItemList = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { quoteId } = useParams()

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const organisationId = useSelector(selectOrganisationId)

    const filterOptions = useSelector(selectMiscFilterOptions)
    const { data: selectedQuote } = useGetQuoteQuery({ organisationId, quoteId })
    const { data: miscItems, isLoading: isLoadingMiscitems } = useGetMiscItemsQuery({ organisationId, quoteId })

    const miscItemsIds = useMemo(() => {
        if (isLoadingMiscitems) return []
        return miscItems.map((item) => item?.id)
    }, [miscItems, isLoadingMiscitems])

    const [calculateQuoteMutation, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-quote',
    })

    const [updateMiscItems] = useUpdateMiscItemsMutation()
    const [updateQuote] = useUpdateQuoteMutation()

    const [activeItemId, setActiveItemId] = useState(null)
    const [activeItemName, setActiveItemName] = useState(null)

    const onDragStart = (event) => {
        const id = event.active.id
        setActiveItemId(id)

        const activeItem = miscItems.find((item) => item?.id === id)
        setActiveItemName(activeItem.name)
    }

    const handleUpdateQuote = async (updatedFields) => {
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...selectedQuote,
                    ...updatedFields,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) updating the $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error' })
        }
    }

    const handleUpdateMiscItems = async (sortedList) => {
        try {
            await updateMiscItems({
                organisationId,
                quoteId,
                miscItems: sortedList,
            }).unwrap()
        } catch (e) {
            enqueueSnackbar(t('Failed to reorder $t(quote) items. Undoing changes'), { variant: 'error' })
        } finally {
            setActiveItemId(null)
        }
    }

    const onDragEnd = useCallback(
        (event) => {
            const { active, over } = event

            if (!active || !over) {
                return
            }

            if (active.id === over.id) {
                return
            }

            const oldIndex = active.data.current.sortable.index
            const newIndex = over.data.current.sortable.index

            const items = structuredClone(arrayMove(miscItems, oldIndex, newIndex))

            items?.forEach((item, index) => {
                item.index = index
            })

            if (selectedQuote.sortMiscItemsBy !== 'Custom') {
                handleUpdateQuote({ sortMiscItemsBy: 'Custom', sortMiscItemsAsc: true })
            }

            handleUpdateMiscItems(items)
        },
        [miscItems, updateMiscItems, quoteId, enqueueSnackbar, t]
    )

    const filteredMiscItems = useMemo(() => {
        if (filterOptions === 'showAllMiscItems') {
            return miscItems
        }

        return miscItems.filter((_miscItem) => {
            return false
        })
    }, [miscItems, filterOptions])

    const calculateQuote = async () => {
        try {
            await calculateQuoteMutation({ organisationId, quoteId }).unwrap()
        } catch (error) {
            const errorMessage = t(error.data)
            enqueueSnackbar(errorMessage, { variant: 'error' })
        }
    }

    useEffect(() => {
        dispatch(setIsCalculatingQuote(isCalculating))
    }, [isCalculating])

    useEffect(() => {
        if (organisationId && quoteId && [QUOTE_STATUS.Draft].includes(selectedQuote?.status)) {
            calculateQuote()
        }
    }, [organisationId, quoteId, selectedQuote?.status])

    return !isLoadingMiscitems ? (
        <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            sensors={sensors}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
        >
            <Box>
                <SortableContext
                    items={miscItemsIds}
                    strategy={verticalListSortingStrategy}
                >
                    {filteredMiscItems.map((miscItem) => (
                        <MiscItem
                            id={miscItem?.id}
                            key={miscItem?.id}
                        />
                    ))}
                </SortableContext>
                <DragOverlay>
                    {activeItemId ? (
                        <MiscellaneousItemOverlay
                            id={activeItemId}
                            name={activeItemName}
                        />
                    ) : null}
                </DragOverlay>
            </Box>
        </DndContext>
    ) : null
}

MiscellaneousItemList.propTypes = {}

export default memo(MiscellaneousItemList)
