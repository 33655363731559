import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import PropTypes from 'prop-types'
import useLocalStorage from 'use-local-storage'

import { selectOrganisation } from '@/app/slices/organisationSlice'
import { LinearProgress } from '@/common/components'
import { useToolBoxTreatments } from '@/common/hooks'
import { PARTS_PER_PAGE_DEFAULT_VALUE, PARTS_PER_PAGE_OPTIONS, PARTS_PER_PAGE_VARIABLE } from '@/common/utils'

import QuotesDashboardRow from './QuotesDashboardRow'

const classes = {
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    table: {
        tableLayout: 'fixed',
        border: `1px solid ${blueGrey[200]}`,
        borderRadius: '8px 8px 0 0',
        backgroundColor: 'background.paper',
        borderCollapse: 'separate',
        borderBottom: 'none',
    },
    headerTableCell: {
        paddingInline: '16px',
        height: 80,
        verticalAlign: 'bottom',
    },
    tableFooter: {
        border: `1px solid ${blueGrey[200]}`,
        borderTop: 'none',
        boxSizing: 'border-box',
        borderRadius: '0px 0px 8px 8px',
        backgroundColor: 'background.paper',
    },
    tablePaginationCaption: {
        fontSize: '12px',
        color: '#5E7387',
    },
    tableLoader: {
        border: 'none',
        position: 'relative',
        marginBottom: -1,
        height: 8,
        borderRadius: '8px 8px 0 0',
    },
}

const QuotesDashboardTable = ({
    currentPage,
    filteredQuotes,
    handlePageOrderChanged,
    handleQuoteDuplicateClicked,
    isLoading,
    onArchiveQuoteClick,
    onUnarchiveQuoteClick,
    order,
    orderBy,
    totalQuoteCount,
}) => {
    const { t } = useTranslation()
    const organisation = useSelector(selectOrganisation)
    const { showIntegrationStatus, showIntegrations, showPayments } = useToolBoxTreatments()

    const [rowsPerPage, setRowsPerPage] = useLocalStorage(PARTS_PER_PAGE_VARIABLE, PARTS_PER_PAGE_DEFAULT_VALUE)

    const setTableRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc'

        handlePageOrderChanged(currentPage, rowsPerPage, isAsc ? 'desc' : 'asc', property)
    }

    const handleChangeRowsPerPage = (event) => {
        setTableRowsPerPage(event)
        const newRowsPerPage = parseInt(event.target.value, 10)
        handlePageOrderChanged(0, newRowsPerPage, order, orderBy)
    }

    const createColumnHeader = (columnId, columnLabel, isSortable) => (
        <TableSortLabel
            active={isSortable ? orderBy === columnId : null}
            direction={isSortable && orderBy === columnId ? order : 'asc'}
            hideSortIcon={!isSortable}
            onClick={
                isSortable
                    ? () => {
                          handleRequestSort(columnId)
                      }
                    : null
            }
        >
            {columnLabel}
        </TableSortLabel>
    )

    const showIntegrationStatusColumn = useMemo(
        () =>
            (organisation.hasXeroIntegration || organisation.hasQuickBooksIntegration) &&
            showIntegrationStatus &&
            showIntegrations &&
            filteredQuotes?.some((q) => q.integrationExportSucceeded || q.integrationExportFailureMessage),
        [showIntegrationStatus, showIntegrations, filteredQuotes, organisation]
    )

    return (
        <>
            {isLoading ? <LinearProgress className={classes.tableLoader} /> : null}
            <Table
                aria-label="contacts"
                sx={classes.table}
            >
                <colgroup>
                    <col style={{ width: '140px' }} />
                    <col style={{ width: '120px' }} />
                    <col style={{ width: '120px' }} />
                    <col style={{ width: '160px' }} />
                    <col style={{ width: '80px' }} />
                    <col style={{ width: '120px' }} />
                    <col style={{ width: '160px' }} />
                    <col style={{ width: '120px' }} />
                    <col style={{ width: '100px' }} />
                    <col style={{ width: '100px' }} />
                    {showPayments ? <col style={{ width: '100px' }} /> : null}
                    <col style={{ width: '120px' }} />
                </colgroup>
                <TableHead>
                    <TableRow>
                        <TableCell
                            align="left"
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('name', t('$t(Quote) ID'), true)}
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('workOrderNumber', t('Work Order #'), true)}
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('invoiceNumber', t('Invoice #'), true)}
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('customerName', t('Customer'), true)}
                        </TableCell>
                        <TableCell
                            align="right"
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('numberOfItems', t('Parts'), true)}
                        </TableCell>
                        <TableCell
                            align="right"
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('lineTaxedPrice', t('Total price'), true)}
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('lastModifiedByUserName', t('Last modified by'), true)}
                        </TableCell>
                        <TableCell
                            align="right"
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('lastModifiedDateUtc', t('Last modified'), true)}
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('quoteSourceType', t('Source'), true)}
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={classes.headerTableCell}
                        >
                            {createColumnHeader('status', t('Status'), true)}
                        </TableCell>
                        {showIntegrationStatusColumn ? (
                            <TableCell
                                align="left"
                                data-testid="quote-integration-status-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('integrationStatus', t('Integration status'), true)}
                            </TableCell>
                        ) : null}
                        {showPayments ? (
                            <TableCell
                                align="left"
                                data-testid="quote-payment-status-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('paymentStatus', t('Payment'), true)}
                            </TableCell>
                        ) : null}
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredQuotes?.map((quote) => {
                        return (
                            <QuotesDashboardRow
                                key={quote.id}
                                quote={quote}
                                showIntegrationStatusColumn={showIntegrationStatusColumn}
                                onArchiveQuoteClick={onArchiveQuoteClick}
                                onQuoteDuplicateClicked={handleQuoteDuplicateClicked}
                                onUnarchiveQuoteClick={onUnarchiveQuoteClick}
                            />
                        )
                    })}
                </TableBody>
            </Table>
            <TablePagination
                classes={{
                    caption: classes.tablePaginationCaption,
                }}
                component="div"
                count={totalQuoteCount}
                labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                labelRowsPerPage={t('$t(Quotes) per page')}
                page={currentPage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={PARTS_PER_PAGE_OPTIONS}
                sx={classes.tableFooter}
                onPageChange={(_, newPage) => {
                    handlePageOrderChanged(newPage, rowsPerPage, order, orderBy)
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

QuotesDashboardTable.propTypes = {
    currentPage: PropTypes.number,
    currentRowsPerPage: PropTypes.number,
    filteredQuotes: PropTypes.arrayOf(PropTypes.object),
    handlePageOrderChanged: PropTypes.func,
    handleQuoteDuplicateClicked: PropTypes.func,
    isLoading: PropTypes.bool,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    totalQuoteCount: PropTypes.number,
    onArchiveQuoteClick: PropTypes.func,
    onUnarchiveQuoteClick: PropTypes.func,
}

export default QuotesDashboardTable
