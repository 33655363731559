import { api } from './api'

export const deliveryPriceDetails = api
    .enhanceEndpoints({
        addTagTypes: ['PriceDetails'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getDeliveryPriceDetails: builder.query({
                query: ({ organisationId }) => ({
                    url: `/shipping/${organisationId}/delivery-price-details`,
                }),
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              { type: 'PriceDetails', id: 'LIST' },
                              ...result.map(({ deliveryPriceDetailsId }) => ({
                                  type: 'PriceDetails',
                                  id: deliveryPriceDetailsId,
                              })),
                          ]
                        : [{ type: 'PriceDetails', id: 'LIST' }],
            }),

            getDeliveryPriceDetailById: builder.query({
                query: ({ deliveryPriceDetailsId, organisationId }) => ({
                    url: `/shipping/${organisationId}/delivery-price-details/${deliveryPriceDetailsId}`,
                }),
                providesTags: (result, _error, _arg) => [{ type: 'PriceDetails', id: result.deliveryPriceDetailsId }],
            }),

            getDeliveryPriceDetailByQuote: builder.query({
                query: ({ organisationId, quoteId }) => ({
                    url: `/shipping/${organisationId}/delivery-price-details/quote/${quoteId}`,
                }),
                providesTags: (result, _error, _arg) => [
                    { type: 'PriceDetails', id: 'LIST' },
                    { type: 'PriceDetails', id: result.deliveryPriceDetailsId },
                ],
            }),
        }),
    })

export const {
    useGetDeliveryPriceDetailByIdQuery,
    useGetDeliveryPriceDetailByQuoteQuery,
    useGetDeliveryPriceDetailsQuery,
} = deliveryPriceDetails
