import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormControlLabel, FormHelperText, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import { getDetails } from 'use-places-autocomplete'

import { selectUseImperialUnits } from '@/app/slices/organisationSlice'
import FormInputAddress from '@/common/components/FormComponents/FormInputAddress'
import FormInputText from '@/common/components/FormComponents/FormInputText'
import { getAddressDetailsFromAddressComponents, validateAddress } from '@/common/helpers/addressUtilities'
import { useToolBoxTreatments } from '@/common/hooks'
import { DELIVERY_PRICING_METHODS, DELIVERY_PRICING_OPTIONS, QUOTE_SOURCE_TYPES } from '@/common/utils'

const ShippingDefaults = ({ form }) => {
    const { t } = useTranslation()
    const { showShipping } = useToolBoxTreatments()
    const { clearErrors, control, formState, getValues, resetField, setError, setValue, watch } = form

    const isImperial = useSelector(selectUseImperialUnits)

    const [selectedPickupAddress, setSelectedPickupAddress] = useState(null)

    const DELIVERY_PRICING_METHODS_INTERNAL = Object.values(DELIVERY_PRICING_METHODS).filter((method) =>
        method.sources.includes(QUOTE_SOURCE_TYPES.Internal)
    )

    const defaultpickupAddress = 'View our website for our current address'
    const deliveryPricingOption = watch('deliveryPricingOption')
    const internalDefaultDeliveryMethod = watch('internalDefaultDeliveryMethod')
    const pickupAddress = watch('pickupAddress')
    const pickupAddressFields = watch(['pickupLine1', 'pickupCity', 'pickupState', 'pickupCountry'])

    const hasCompletedPickupAddress = useMemo(() => {
        return pickupAddressFields.every((value) => Boolean(value))
    }, [pickupAddressFields])

    const setInvalidAddressError = () => {
        const errorMessage = t(
            'We could not find the specified address. Please ensure that the address is correct and try again.'
        )

        setError('invalidPickupAddress', {
            type: 'invalidAddress',
            message: errorMessage,
        })
    }

    const clearInvalidAddressError = () => {
        clearErrors('invalidPickupAddress')
    }

    const setPickupAddressDetails = (_, addressDetails) => {
        const { addressLine1, city, country, postcode, state, subpremise } =
            getAddressDetailsFromAddressComponents(addressDetails)

        setValue('pickupAddress', { description: addressDetails.formatted_address, place_id: addressDetails.place_id })
        setValue('pickupLine1', addressLine1, { shouldValidate: true })
        setValue('pickupLine2', subpremise, { shouldValidate: true })
        setValue('pickupCity', city, { shouldValidate: true })
        setValue('pickupState', state, { shouldValidate: true })
        setValue('pickupCountry', country, { shouldValidate: true })
        setValue('pickupPostcode', postcode, { shouldValidate: true })

        setSelectedPickupAddress(addressDetails)
    }

    const clearPickupAddressDetails = () => {
        resetField('pickupLine1')
        resetField('pickupLine2')
        resetField('pickupCity')
        resetField('pickupState')
        resetField('pickupCountry')
        resetField('pickupPostcode')

        setSelectedPickupAddress(null)
        clearInvalidAddressError()
    }

    const validateManualAddress = useCallback(async () => {
        const addressComponents = ['pickupLine1', 'pickupCity', 'pickupState', 'pickupCountry', 'pickupPostcode']
        const getAddressComponent = (component) => getValues(component) || ''
        const addressValues = addressComponents.map(getAddressComponent)
        const fullAddress =
            addressValues.filter(Boolean).join(', ') || pickupAddress?.description || pickupAddress || ''

        if (!fullAddress) return

        const { isValid, placeId } = await validateAddress({ address: fullAddress })

        if (!isValid) {
            setInvalidAddressError()
            resetField('pickupAddress', { defaultValue: null })
            return
        }

        const detailedAddress = await getDetails({ placeId })
        clearInvalidAddressError()

        setSelectedPickupAddress(detailedAddress)
        setValue('pickupAddress', { description: detailedAddress.formatted_address, place_id: placeId })

        if (getValues('pickupLine1') === '') {
            setPickupAddressDetails(null, detailedAddress)
        }
    }, [getValues, setValue, clearInvalidAddressError, setInvalidAddressError, selectedPickupAddress])

    const debounceValidateManualAddress = useDebouncedCallback(validateManualAddress, 1500)

    const handleAddressFieldChange = (_e) => {
        debounceValidateManualAddress.cancel()
        if (hasCompletedPickupAddress) {
            debounceValidateManualAddress()
        }
    }

    return (
        <Grid
            marginBlock={0}
            paddingInline={2}
            spacing={3}
            xs={12}
            container
        >
            <Grid xs={12}>
                <Typography
                    data-testid="shipping-defaults-setting"
                    id="shipping-defaults"
                    variant="h6"
                >
                    {t('Defaults')}
                </Typography>
            </Grid>

            <Grid xs={12}>
                <Typography
                    data-testid="shipping-pickup-setting-title"
                    variant="strong1"
                >
                    {t('Pickup')}
                </Typography>
            </Grid>
            <Grid xs={12}>
                <FormInputAddress
                    control={control}
                    data-testid="shipping-pickup-setting-input"
                    label={t('Pickup address')}
                    name="pickupAddress"
                    placeholder={t(defaultpickupAddress)}
                    variant="standard"
                    onAddressChanged={setPickupAddressDetails}
                    onAddressClear={clearPickupAddressDetails}
                />
            </Grid>

            <Grid
                mt={-2}
                xs={12}
            >
                <Typography
                    color="text.secondary"
                    variant="small"
                >
                    {t('Please provide a pickup address to display when orders are marked for pickup')}
                </Typography>
            </Grid>

            <Grid xs={6}>
                <FormInputText
                    control={control}
                    label={t('Address')}
                    name="pickupLine1"
                    rules={{ required: 'This field is required' }}
                    onHandleChange={(e) => handleAddressFieldChange(e)}
                />
            </Grid>
            <Grid xs={6}>
                <FormInputText
                    control={control}
                    label={t('Unit/Suite/Floor/Office (optional)')}
                    name="pickupLine2"
                />
            </Grid>
            <Grid xs={6}>
                <FormInputText
                    control={control}
                    label={t('Zip/Postal code')}
                    name="pickupPostcode"
                    onHandleChange={(e) => handleAddressFieldChange(e)}
                />
            </Grid>
            <Grid xs={6}>
                <FormInputText
                    control={control}
                    label={t('City/County/Town/Suburb')}
                    name="pickupCity"
                    rules={{ required: 'This field is required' }}
                    onHandleChange={(e) => handleAddressFieldChange(e)}
                />
            </Grid>
            <Grid xs={6}>
                <FormInputText
                    control={control}
                    label={t('State/Province/Region')}
                    name="pickupState"
                    rules={{ required: 'This field is required' }}
                    onHandleChange={(e) => handleAddressFieldChange(e)}
                />
            </Grid>
            <Grid xs={6}>
                <FormInputText
                    control={control}
                    label={t('Country')}
                    name="pickupCountry"
                    rules={{ required: 'This field is required' }}
                    onHandleChange={(e) => handleAddressFieldChange(e)}
                />
            </Grid>

            {formState?.errors?.invalidPickupAddress ? (
                <Grid
                    xs={12}
                    item
                >
                    <FormHelperText error={Boolean(formState?.errors?.invalidPickupAddress)}>
                        {t(formState.errors?.invalidPickupAddress?.message)}
                    </FormHelperText>
                </Grid>
            ) : null}

            {showShipping ? (
                <Grid xs={12}>
                    <Typography
                        data-testid="shipping-delivery-setting"
                        mb={1}
                        variant="strong1"
                    >
                        {t('Delivery pricing method')}
                    </Typography>
                    <Typography
                        color="text.secondary"
                        mb={2}
                        variant="body2"
                    >
                        {t(
                            "Set the default delivery pricing method when a quote's shipping option is set to Delivery. This selection can be overridden inside the quote."
                        )}
                    </Typography>

                    <Controller
                        control={control}
                        key="internalDefaultDeliveryMethod"
                        name="internalDefaultDeliveryMethod"
                        render={({ field: { onChange, ref, value } }) => (
                            <RadioGroup
                                data-testid="shipping-delivery-setting-options"
                                sx={{ marginBottom: 2 }}
                                value={value}
                                onChange={(e, value) => {
                                    onChange(value)
                                }}
                            >
                                {DELIVERY_PRICING_METHODS_INTERNAL.map((option) => (
                                    <>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    color="primary"
                                                    inputRef={ref}
                                                />
                                            }
                                            data-testid={`shipping-delivery-setting-option-${option.value}`}
                                            key={option.value}
                                            label={t(option.label)}
                                            slotProps={{
                                                typography: {
                                                    variant: 'body1',
                                                    color: 'text.secondary',
                                                },
                                            }}
                                            value={option.value}
                                        />

                                        {option.value === 'ChargeToOrder' ? (
                                            <Typography
                                                color="text.secondary"
                                                ml={4}
                                                mt={-1}
                                                variant="body2"
                                            >
                                                {t('Selected pricing option:')}{' '}
                                                <strong>
                                                    {Object.values(DELIVERY_PRICING_OPTIONS)
                                                        .find((option) => option.value === deliveryPricingOption)
                                                        ?.label(isImperial)}
                                                </strong>
                                                {'. '}
                                                {t('Manage the charge settings')}{' '}
                                                <Link to="#delivery-prices">{t('here.')}</Link>
                                            </Typography>
                                        ) : null}
                                    </>
                                ))}
                            </RadioGroup>
                        )}
                    />

                    <Controller
                        control={control}
                        key={internalDefaultDeliveryMethod}
                        name="internalPreferredDeliveryProvider"
                        render={({ field: { onBlur, onChange, ref, value } }) => (
                            <TextField
                                disabled={internalDefaultDeliveryMethod !== 'PayUponDelivery'}
                                helperText={t('Add the name of the preferred delivery provider. Optional')}
                                InputLabelProps={{
                                    'data-testid': 'preferred-delivery-provider-label',
                                }}
                                inputProps={{
                                    'data-testid': 'preferred-delivery-provider-input',
                                }}
                                inputRef={ref}
                                label={t('Preferred delivery provider')}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                    />
                </Grid>
            ) : null}
        </Grid>
    )
}

ShippingDefaults.propTypes = {
    form: PropTypes.object.isRequired,
}

export default ShippingDefaults
