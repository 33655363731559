import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Link, TableCell, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { selectOrganisation, selectPaidFeatures, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { DateTime, ThumbnailImage } from '@/common/components'
import { useNumberFormatter, useToolBoxTreatments } from '@/common/hooks'
import { getFormattedPrice, organisationLengthPrecision } from '@/common/utils'

const classes = {
    row: {
        '&:hover': {
            backgroundColor: 'hsl(0, 0%, 25%)',
        },
        '&:last-child td': {
            borderBottom: 'none',
        },
    },
    materialCell: {
        width: 130,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    link: {
        textDecoration: 'underline',
        color: 'inherit',
    },
    cellWithEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    tableCell: {
        borderBottom: '1px solid hsl(0, 0%, 45%)',
        py: 1.5,
    },
    tableCellBorder: {
        borderRight: (theme) => `1px dashed ${theme.palette.text.disabled}`,
    },
}

const PartLibraryRow = ({
    handleChangeSelectedPartLibraryItem,
    handleDetailSecondaryProcessClick,
    handleProjectMoreDetailsClick,
    partFromLibrary,
}) => {
    const { t } = useTranslation()

    const organisation = useSelector(selectOrganisation)
    const paidFeatures = useSelector(selectPaidFeatures)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const locale = useSelector(selectLocale)

    const { showFixedPriceParts } = useToolBoxTreatments()

    const showFixedPricePartsColumn = showFixedPriceParts && paidFeatures?.hasFixedPriceParts

    const { n: dimensionFormat } = useNumberFormatter({
        numberOfDecimalPlaces: organisation ? organisationLengthPrecision(organisation) : 2,
    })

    const { n: metricNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 2,
    })

    const { n: imperialNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 3,
    })

    const handleDetailProjectClick = (event, selectedPartFromLibrary) => {
        typeof handleProjectMoreDetailsClick === 'function' &&
            handleProjectMoreDetailsClick(event, selectedPartFromLibrary)
    }

    const renderProjectDetails = (selectedPartFromLibrary) => {
        return (
            <>
                <Typography variant="body2">{selectedPartFromLibrary.lastQuote.quoteName} </Typography>
                <Typography variant="body2">{`${t(selectedPartFromLibrary.lastQuote.quoteStatus)}`}</Typography>
                <Typography variant="body2">
                    <DateTime
                        format="DD-MMM-YYYY"
                        locale={locale}
                    >
                        {selectedPartFromLibrary.lastQuote.lastModifiedDate}
                    </DateTime>
                </Typography>
                <Link
                    href="#"
                    sx={classes.link}
                    onClick={(e) => handleDetailProjectClick(e, selectedPartFromLibrary)}
                >
                    {t('More details')}
                </Link>
            </>
        )
    }
    const renderBounds = (rowData) => {
        if (rowData.minimumBoundBoxWidth && rowData.minimumBoundBoxHeight) {
            const n = rowData.isImperial ? imperialNumberFormatter : metricNumberFormatter
            const bounds = rowData.profile
                ? `${n(rowData.rotaryProfileWidth)} x ${n(rowData.rotaryProfileLength)} x ${n(
                      rowData.rotaryPartLength
                  )}`
                : `${n(rowData.displayBoundBoxWidth ?? rowData.minimumBoundBoxWidth)} x ${n(rowData.displayBoundBoxHeight ?? rowData.minimumBoundBoxHeight)}`
            const units = rowData.isImperial !== useImperialUnits ? (rowData.isImperial ? '(in)' : '(mm)') : ''
            return <Typography variant="body2">{`${bounds} ${units}`}</Typography>
        }
        return <Typography variant="body2">{'-'}</Typography>
    }
    const renderMaterialCell = (materialName, thickness) => (
        <Box>
            <Tooltip title={materialName ?? ''}>
                <div>
                    <Typography variant="body2">{materialName || '[Unnamed part]'}</Typography>
                </div>
            </Tooltip>

            <Typography variant="body2">
                {dimensionFormat(thickness)} {useImperialUnits ? 'in' : 'mm'}
            </Typography>
        </Box>
    )

    const renderSecondaryProcess = (partFromLibrary) => {
        const { numberOfFolds, partLibraryEntrySecondaryProcesses } = partFromLibrary
        if (partLibraryEntrySecondaryProcesses.length > 0 || numberOfFolds > 0) {
            return (
                <>
                    {numberOfFolds > 0 ? <Typography variant="body2">{t('Folding')}</Typography> : null}
                    {partLibraryEntrySecondaryProcesses.map((i) => (
                        <Box
                            key={i.partLibraryEntrySecondaryProcessId}
                            sx={classes.cellWithEllipsis}
                        >
                            <Typography variant="body2">{i.secondaryProcess.name}</Typography>
                        </Box>
                    ))}
                    <Link
                        href="#"
                        sx={classes.link}
                        onClick={(e) =>
                            handleDetailSecondaryProcessClick(e, partLibraryEntrySecondaryProcesses, numberOfFolds)
                        }
                    >
                        {t('More details')}
                    </Link>
                </>
            )
        }
    }

    const isFixedPriceExpired = useMemo(() => {
        if (!partFromLibrary.fixedPriceEndTime) return false
        const currentDate = new Date().setHours(0, 0, 0, 0)
        const fixedPriceEndTime = new Date(partFromLibrary.fixedPriceEndTime).setHours(0, 0, 0, 0)
        return currentDate > fixedPriceEndTime
    }, [partFromLibrary.fixedPriceEndTime])

    return (
        <TableRow sx={classes.row}>
            <TableCell
                align="center"
                sx={classes.tableCell}
            >
                <TextField
                    id="standard-basic"
                    inputProps={{ min: 0, style: { textAlign: 'right' } }}
                    style={{ width: '60%' }}
                    type="number"
                    variant="standard"
                    onChange={(e) => handleChangeSelectedPartLibraryItem(e, { ...partFromLibrary })}
                />
            </TableCell>
            <TableCell
                sx={classes.tableCell}
                width={160}
            >
                <ThumbnailImage
                    fromPartLibrary={true}
                    uri={partFromLibrary.thumbnailUri}
                />
            </TableCell>
            <TableCell
                align="left"
                sx={classes.tableCell}
            >
                {partFromLibrary.partId}
            </TableCell>
            <TableCell
                align="left"
                sx={classes.tableCell}
            >
                {partFromLibrary.name}
            </TableCell>
            <TableCell
                align="left"
                sx={classes.tableCell}
            >
                {renderBounds(partFromLibrary)}
            </TableCell>
            <TableCell
                align="left"
                sx={classes.tableCell}
            >
                {partFromLibrary.cuttingTechnology.name}
            </TableCell>
            <TableCell
                align="left"
                sx={classes.tableCell}
            >
                {renderMaterialCell(partFromLibrary.material.materialName, partFromLibrary.thickness)}
            </TableCell>
            <TableCell
                align="left"
                sx={[classes.cellWithEllipsis, classes.tableCell]}
            >
                {renderSecondaryProcess(partFromLibrary)}
            </TableCell>
            <TableCell
                align="left"
                sx={classes.tableCell}
            >
                {renderProjectDetails(partFromLibrary)}
            </TableCell>
            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                {partFromLibrary.lastQuote.lastPrice
                    ? getFormattedPrice(
                          partFromLibrary.lastQuote.lastPrice,
                          organisation.currencyCode,
                          organisation.locale
                      )
                    : getFormattedPrice(0, organisation.currencyCode, organisation.locale)}
            </TableCell>
            <TableCell
                align="right"
                sx={[classes.tableCell, showFixedPricePartsColumn ? classes.tableCellBorder : null]}
            >
                {partFromLibrary.lastQuote.lastQuantity}
            </TableCell>

            {showFixedPricePartsColumn ? (
                <TableCell
                    align="center"
                    sx={[classes.tableCell, { paddingInline: 1 }]}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1.5}
                    >
                        {partFromLibrary.fixedPrice ? (
                            <>
                                <Typography variant="strong2">
                                    {getFormattedPrice(
                                        partFromLibrary.fixedPrice,
                                        organisation.currencyCode,
                                        organisation.locale
                                    )}
                                </Typography>
                                <Typography
                                    color={isFixedPriceExpired ? 'warning.main' : 'text.primary'}
                                    variant="strong2"
                                >
                                    {partFromLibrary.fixedPriceEndTime ? (
                                        isFixedPriceExpired ? (
                                            t('Fixed price expired')
                                        ) : (
                                            <DateTime
                                                format="DD-MMM-YYYY"
                                                locale={locale}
                                            >
                                                {partFromLibrary.fixedPriceEndTime}
                                            </DateTime>
                                        )
                                    ) : (
                                        t('No end date')
                                    )}
                                </Typography>
                            </>
                        ) : (
                            <Typography variant="body2">{t('No')}</Typography>
                        )}
                    </Box>
                </TableCell>
            ) : null}
        </TableRow>
    )
}

PartLibraryRow.propTypes = {
    handleChangeSelectedPartLibraryItem: PropTypes.func,
    handleDetailSecondaryProcessClick: PropTypes.func,
    handleProjectMoreDetailsClick: PropTypes.func,
    partFromLibrary: PropTypes.object,
    project: PropTypes.object,
}

export default PartLibraryRow
