import { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { Archive, Save, Unarchive } from '@mui/icons-material'
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import {
    useDeleteOrganisationUserMutation,
    useLazyGetOrganisationsQuery,
    useLazyGetUsersQuery,
    useSetUserLastAccessedOrganisationAsyncMutation,
    useSetUserRoleMutation,
    useUnDeleteOrganisationUserMutation,
} from '@/app/services/admin'
import {
    useChangeDrawingUnitsMutation,
    useDownloadOrganisationAsJsonMutation,
    useExportOrganisationAsJsonMutation,
    useImportOrganisationMutation,
    useReinitializeOrganisationMutation,
    useToggleLegacyMaterialsMutation,
    useUpdateOrganisationMutation,
} from '@/app/services/organisation'
import { selectOrganisation } from '@/app/slices/organisationSlice'
import { DashedBorderContainer, FileUploader, Input, TbxTooltip } from '@/common/components'
import { OrganisationManagementToolbar } from '@/common/components/MainAppBar'
import { useUserContextSwitcher } from '@/common/hooks'
import { AuthorisationUtils, CUTTING_TECHNOLOGY_POWERS } from '@/common/utils'

const classes = {
    cardRoot: {
        width: 576,
        mb: 3,
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        boxSizing: 'border-box',
    },
    iconButton: {
        margin: '0px 4px',
        color: 'text.secondary',
        '&:hover': {
            color: 'text.primary',
        },
    },
    autoComplete: {
        my: 2,
    },
    warningLabel: {
        p: 1,
        fontSize: 'small',
        color: 'error.main',
        textTransform: 'uppercase',
    },
    dialogActionsContainer: {
        width: '100%',
        my: 2,
    },
    uploaderButtonsContainer: {
        display: 'flex',
    },
    selectButton: {
        textAlign: 'center',
    },
    dialogTextContainer: {
        p: 1,
    },
}

const Administration = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const organisation = useSelector(selectOrganisation)

    const [cuttingTechnologyPower, setCuttingTechnologyPower] = useState(organisation.cuttingTechnologyPower)
    const [appIsLoading, setAppIsLoading] = useState(false)

    const { instance: msalInstance } = useMsal()
    const { setSelectedOrganisation } = useUserContextSwitcher()

    const defaults = useMemo(() => organisation, [organisation])

    const [getOrganisations, { data: allOrganisations, isLoading: isLoadingOrganisations }] =
        useLazyGetOrganisationsQuery()
    const [getUsers, { data: allUsers, isLoading: isLoadingUsers }] = useLazyGetUsersQuery()
    const [unDeleteOrganisationUser] = useUnDeleteOrganisationUserMutation()
    const [deleteOrganisationUser] = useDeleteOrganisationUserMutation()
    const [setUserLastAccessedOrganisationAsync] = useSetUserLastAccessedOrganisationAsyncMutation()
    const [setUserRole] = useSetUserRoleMutation()
    const [updateOrganisation] = useUpdateOrganisationMutation()
    const [changeDrawingUnits] = useChangeDrawingUnitsMutation()
    const [toggleLegacyMaterials] = useToggleLegacyMaterialsMutation()
    const [downloadOrganisationAsJson] = useDownloadOrganisationAsJsonMutation()
    const [reinitializeOrganisation] = useReinitializeOrganisationMutation()
    const [importOrganisation] = useImportOrganisationMutation()
    const [exportOrganisationAsJson] = useExportOrganisationAsJsonMutation()

    const organisationDataFileUploaderRef = useRef(null)

    const {
        control,
        formState: { isDirty, isValid },
        handleSubmit,
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: defaults,
    })

    const showSiteAdminOrSupportSettings = useMemo(
        () => AuthorisationUtils.isSiteAdminOrSupport(msalInstance.getActiveAccount()),
        [msalInstance]
    )

    const showSiteAdminOnlySettings = useMemo(
        () => AuthorisationUtils.isSiteAdmin(msalInstance.getActiveAccount()),
        [msalInstance]
    )

    const updateOrganisationSettings = async (organisationData) => {
        setAppIsLoading(true)
        try {
            if (organisationData.defaultDrawingUnits !== organisation.defaultDrawingUnits) {
                const result = await changeDrawingUnits({
                    organisationId: organisation.organisationId,
                    drawingUnits: organisationData.defaultDrawingUnits,
                }).unwrap()

                const newOrganisation = {
                    ...organisation,
                    defaultDrawingUnits: result.defaultDrawingUnits,
                }

                reset(newOrganisation)

                enqueueSnackbar(`${t('Successfully switched drawing units to')} ${result.defaultDrawingUnits}`, {
                    variant: 'success',
                })
            }
            if (cuttingTechnologyPower !== organisation.cuttingTechnologyPower) {
                const result = await updateOrganisation({
                    organisationDto: {
                        organisationId: organisation.organisationId,
                        cuttingTechnologyPower: cuttingTechnologyPower,
                    },
                })
                const newOrganisation = {
                    ...organisation,
                    cuttingTechnologyPower: result.data.cuttingTechnologyPower,
                }

                reset(newOrganisation)

                enqueueSnackbar(
                    `${t('Successfully cutting technolgy power to: ')} ${result.data.cuttingTechnologyPower}`,
                    {
                        variant: 'success',
                    }
                )
            }
            if (organisationData.isLegacyMaterials !== organisation.isLegacyMaterials) {
                const result = await toggleLegacyMaterials({ organisationId: organisation.organisationId }).unwrap()

                const newOrganisation = {
                    ...organisation,
                    isLegacyMaterials: result.isLegacyMaterials,
                }

                reset(newOrganisation)

                enqueueSnackbar(`${t('Successfully set Legacy Materials to')} ${result.isLegacyMaterials.toString()}`, {
                    variant: 'success',
                })
            }
            if (organisationData.showBrandingInDocumentFooter !== organisation.showBrandingInDocumentFooter) {
                const result = await updateOrganisation({
                    organisationDto: {
                        organisationId: organisation.organisationId,
                        showBrandingInDocumentFooter: organisationData.showBrandingInDocumentFooter,
                    },
                })
                const newOrganisation = {
                    ...organisation,
                    showBrandingInDocumentFooter: result.showBrandingInDocumentFooter,
                }

                reset(newOrganisation)

                enqueueSnackbar(
                    `${t(
                        'Successfully set showBrandingInDocumentFooter to'
                    )} ${newOrganisation.showBrandingInDocumentFooter.toString()}`,
                    {
                        variant: 'success',
                    }
                )
            }

            return organisationData
        } catch {
            enqueueSnackbar(t('$t(An error occurred) updating your organisation settings.'), {
                variant: 'error',
            })
        } finally {
            setAppIsLoading(false)
        }
    }

    const handleOrganisationDownloadClick = async () => {
        setAppIsLoading(true)

        try {
            const result = await downloadOrganisationAsJson({ organisationId: organisation.organisationId })

            enqueueSnackbar(`${t('Successfully downloaded organisation settings')}`, {
                variant: 'success',
            })

            return result
        } catch {
            enqueueSnackbar(`${t('$t(An error occurred) failed to export your organisation settings.')}`, {
                variant: 'error',
            })
        } finally {
            setAppIsLoading(false)
        }
    }

    const handleReinitialiseOrganisationClick = async () => {
        setAppIsLoading(true)

        try {
            const result = await reinitializeOrganisation({
                organisationId: organisation.organisationId,
                cuttingTechnologyPower: cuttingTechnologyPower,
            })

            enqueueSnackbar(`${t('Successfully reinitialised the organisation')}`, {
                variant: 'success',
            })

            return result
        } catch {
            enqueueSnackbar(`${t('$t(An error occurred) failed to reinitialise the organisation')}`, {
                variant: 'error',
            })
        } finally {
            setAppIsLoading(false)
        }
    }

    const handleOrganisationSelectClick = (organisationId) => async () => {
        setAppIsLoading(true)

        try {
            await setSelectedOrganisation(organisationId)

            enqueueSnackbar(`${t('Successfully selected organisation')} ${organisationId}`, {
                variant: 'success',
            })
        } catch {
            enqueueSnackbar(`${t('$t(An error occurred) attempting to log in as a user of')} ${organisationId}`, {
                variant: 'error',
            })
        } finally {
            setAppIsLoading(false)
        }
    }

    const handleOrganisationUserToggleClick = (organisationUser) => async () => {
        setAppIsLoading(true)
        const action = organisationUser.isDeleted ? 'enable' : 'disable'

        try {
            organisationUser.isDeleted
                ? await unDeleteOrganisationUser({
                      organisationId: organisationUser.organisationId,
                      userId: organisationUser.userId,
                  }).unwrap()
                : await deleteOrganisationUser({
                      organisationId: organisationUser.organisationId,
                      userId: organisationUser.userId,
                  }).unwrap()

            enqueueSnackbar(
                t('Successfully {{action}} the user {{emailAddress}} for {{organisationName}}.', {
                    action,
                    emailAddress: organisationUser.emailAddress,
                    organisationName: organisationUser.organisationName,
                }),
                {
                    variant: 'success',
                }
            )
        } catch {
            enqueueSnackbar(
                t('$t(An error occurred) attempting to {{action}} the user {{emailAddress}} for {{organisationName}}', {
                    action,
                    emailAddress: organisationUser.emailAddress,
                    organisationName: organisationUser.organisationName,
                }),
                {
                    variant: 'error',
                }
            )
        } finally {
            setAppIsLoading(false)
        }
    }

    const handleOrganisationUserSetAsSelectedClick = (organisationUser) => async () => {
        setAppIsLoading(true)

        try {
            await setUserLastAccessedOrganisationAsync({
                organisationId: organisationUser.organisationId,
                userId: organisationUser.userId,
            }).unwrap()

            enqueueSnackbar(
                t('Successfully set {{organisationId}} as last selected org of user {{userId}}', {
                    organisationId: organisationUser.organisationId,
                    userId: organisationUser.userId,
                }),
                {
                    variant: 'success',
                }
            )
        } catch {
            enqueueSnackbar(
                t('$t(An error occurred) setting {{organisationId}} as last selected org of user {{userId}}.', {
                    organisationId: organisationUser.organisationId,
                    userId: organisationUser.userId,
                }),
                {
                    variant: 'error',
                }
            )
        } finally {
            setAppIsLoading(false)
        }
    }

    const handleSetUserRole = (user, userRole) => async () => {
        setAppIsLoading(true)

        try {
            await setUserRole({ userId: user.userId, userRole }).unwrap()

            enqueueSnackbar(
                t('Successfully set {{emailAddress}} as {{userRole}}', {
                    emailAddress: user.emailAddress,
                    userRole,
                }),
                {
                    variant: 'success',
                }
            )
        } catch {
            enqueueSnackbar(
                t('$t(An error occurred) attempting to elevate the user {{emailAddress}} as {{userRole}}.', {
                    emailAddress: user.emailAddress,
                    userRole,
                }),
                {
                    variant: 'error',
                }
            )
        } finally {
            setAppIsLoading(false)
        }
    }

    const handleUserRoleRevoke = (user) => async () => {
        setAppIsLoading(true)

        try {
            await setUserRole({ userId: user.userId, userRole: null })

            enqueueSnackbar(
                t('Successfully revoked the user {{emailAddress}} as {{userRole}}', {
                    emailAddress: user.emailAddress,
                    userRole: user.userRole,
                }),
                {
                    variant: 'success',
                }
            )
        } catch {
            enqueueSnackbar(
                t('$t(An error occurred) attempting to revoke the user {{emailAddress}} as {{userRole}}.', {
                    emailAddress: user.emailAddress,
                    userRole: user.userRole,
                }),
                {
                    variant: 'error',
                }
            )
        } finally {
            setAppIsLoading(false)
        }
    }

    const handleCuttingTechnologyPowerChange = (event, newValue) => {
        setCuttingTechnologyPower(newValue)
    }

    const handleOrganisationImportFileAccepted = async (files) => {
        setAppIsLoading(true)

        try {
            if (files?.length !== 1) {
                return
            }

            const result = await importOrganisation({ organisationId: organisation.organisationId, file: files[0] })

            enqueueSnackbar(t('Successfully imported all organisation data'), {
                variant: 'success',
            })

            return result
        } catch {
            enqueueSnackbar(t('$t(An error occurred) failed to import your organisation data.'), {
                variant: 'error',
            })
        } finally {
            setAppIsLoading(false)
        }
    }

    const handleOrganisationImportSelectFileButton = () => {
        if (organisationDataFileUploaderRef.current) {
            organisationDataFileUploaderRef.current.value = null
            organisationDataFileUploaderRef.current.click()
        }
    }

    const handleOrganisationExportClick = async () => {
        setAppIsLoading(true)

        try {
            const result = await exportOrganisationAsJson({ organisationId: organisation.organisationId })

            enqueueSnackbar(t('Successfully exported all organisation data'), {
                variant: 'success',
            })

            return result
        } catch {
            enqueueSnackbar(t('$t(An error occurred) failed to export your organisation data.'), {
                variant: 'error',
            })
        } finally {
            setAppIsLoading(false)
        }
    }

    const handleLoadOrganisationClick = async () => {
        try {
            await getOrganisations().unwrap()
        } catch (error) {
            enqueueSnackbar(t('$t(An error occurred) loading organisations.'), {
                variant: 'error',
            })
        }
    }

    const handleLoadUsersClick = async () => {
        try {
            await getUsers().unwrap()
        } catch (error) {
            enqueueSnackbar(t('$t(An error occurred) loading users.'), {
                variant: 'error',
            })
        }
    }

    useEffect(() => {
        if (!showSiteAdminOrSupportSettings) {
            navigate('/')
            return
        }
    }, [showSiteAdminOrSupportSettings])

    return showSiteAdminOrSupportSettings ? (
        <>
            <OrganisationManagementToolbar
                handleUpdateClick={handleSubmit(updateOrganisationSettings)}
                isLoading={appIsLoading}
                isUpdateButtonDisabled={appIsLoading || !isDirty || !isValid}
            />
            <Box sx={classes.container}>
                <Typography variant="h5">
                    <>{t('Selected Organisation: {{name}} {{organisationId}}', organisation)}</>
                </Typography>
                {showSiteAdminOnlySettings ? (
                    <>
                        <Card sx={classes.cardRoot}>
                            <CardHeader title={t('Administrative Settings')} />
                            <CardActions>
                                <Button
                                    color="primary"
                                    disabled={!isValid || appIsLoading}
                                    variant="contained"
                                    onClick={handleOrganisationDownloadClick}
                                >
                                    {appIsLoading ? <CircularProgress size={20} /> : null}
                                    {t('Download Organisation As JSON')}
                                </Button>
                            </CardActions>
                            <CardContent>
                                <FormControl component="fieldset">
                                    <Button
                                        color="primary"
                                        disabled={!isValid || appIsLoading}
                                        startIcon={<Save />}
                                        variant="contained"
                                        onClick={handleOrganisationExportClick}
                                    >
                                        {appIsLoading ? <CircularProgress size={20} /> : null}
                                        {t('Export all organisation data')}
                                    </Button>
                                    <FormLabel sx={classes.warningLabel}>
                                        {t('Warning: Extremely slow, use with caution.')}
                                    </FormLabel>
                                </FormControl>
                            </CardContent>
                            {import.meta.env.VITE_ENV === 'development' ? (
                                <CardContent>
                                    <FormLabel>{t('Import Organisation Data')}</FormLabel>
                                    <Controller
                                        control={control}
                                        name="logo"
                                        render={() => (
                                            <FileUploader
                                                acceptedFileTypes={['.json']}
                                                labelText={t('Upload organisation data file')}
                                                ref={organisationDataFileUploaderRef}
                                                singleFileUpload={true}
                                                onFilesAccepted={handleOrganisationImportFileAccepted}
                                            >
                                                <Box sx={classes.dialogActionsContainer}>
                                                    <DashedBorderContainer>
                                                        <Box sx={classes.uploaderButtonsContainer}>
                                                            <Button
                                                                color="primary"
                                                                key="add"
                                                                sx={classes.selectButton}
                                                                variant="contained"
                                                                onClick={handleOrganisationImportSelectFileButton}
                                                            >
                                                                {t('Select file')}
                                                            </Button>
                                                        </Box>
                                                        <Box sx={classes.dialogTextContainer}>
                                                            <Typography variant="subtitle1">
                                                                {t(
                                                                    'Select or drag and drop an organisation data file here'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </DashedBorderContainer>
                                                </Box>
                                            </FileUploader>
                                        )}
                                    />
                                </CardContent>
                            ) : null}
                            <CardContent>
                                <FormControl component="fieldset">
                                    <FormLabel>{t('Default Drawing Units')}</FormLabel>
                                    <Controller
                                        control={control}
                                        name="defaultDrawingUnits"
                                        render={({ field: { onChange, value } }) => (
                                            <RadioGroup
                                                value={value}
                                                onChange={(e, value) => {
                                                    onChange(value)
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={<Radio />}
                                                    key="Imperial"
                                                    label="Imperial"
                                                    value="Imperial"
                                                />
                                                <FormControlLabel
                                                    control={<Radio />}
                                                    key="Metric"
                                                    label="Metric"
                                                    value="Metric"
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                </FormControl>
                            </CardContent>
                            <CardContent>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                control={control}
                                                name="isLegacyMaterials"
                                                render={({ field: { onChange, value } }) => (
                                                    <Switch
                                                        checked={value}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        name="isLegacyMaterials"
                                                        onChange={(e, value) => {
                                                            onChange(value)
                                                        }}
                                                    />
                                                )}
                                            />
                                        }
                                        label={t('Legacy Materials')}
                                    />
                                </FormControl>
                            </CardContent>
                            <CardContent>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                control={control}
                                                name="showBrandingInDocumentFooter"
                                                render={({ field: { onChange, value } }) => (
                                                    <Switch
                                                        checked={value}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        name="showBrandingInDocumentFooter"
                                                        onChange={(e, value) => {
                                                            onChange(value)
                                                        }}
                                                    />
                                                )}
                                            />
                                        }
                                        label={t('Powered by Tempus Tools message is displayed on exported PDF')}
                                    />
                                </FormControl>
                            </CardContent>
                        </Card>
                        <Card sx={classes.cardRoot}>
                            <CardHeader title={t('Reinitialise Organisation Defaults')} />
                            <CardContent>
                                <Autocomplete
                                    disableClearable={true}
                                    getOptionLabel={(option) => option.toString()}
                                    options={CUTTING_TECHNOLOGY_POWERS}
                                    renderInput={(params) => (
                                        <Input
                                            {...params}
                                            error={isValid === false && !cuttingTechnologyPower ? t('Required') : null}
                                            fullWidth={false}
                                            label={t('Cutting technology power (kW)')}
                                            labelProps={{
                                                sx: classes.inputLabel,
                                                'data-testid': 'user-welcome-cutting-technology-power-label',
                                            }}
                                            placeholder={t('4kW')}
                                            required={true}
                                            style={{ width: '250px' }}
                                            textAlign="left"
                                            value={cuttingTechnologyPower}
                                        />
                                    )}
                                    sx={classes.autoComplete}
                                    value={cuttingTechnologyPower ?? ''}
                                    onChange={handleCuttingTechnologyPowerChange}
                                />
                                <CardActions>
                                    <Button
                                        color="primary"
                                        disabled={!isValid || appIsLoading || !cuttingTechnologyPower}
                                        variant="contained"
                                        onClick={handleSubmit(handleReinitialiseOrganisationClick)}
                                    >
                                        {appIsLoading ? <CircularProgress size={20} /> : null}
                                        {t('Reinitialise Defaults')}
                                    </Button>
                                </CardActions>
                            </CardContent>
                        </Card>
                    </>
                ) : null}

                {showSiteAdminOrSupportSettings ? (
                    <Card sx={{ mb: 3 }}>
                        <CardHeader title={t('Organisation Selection')} />
                        <CardContent>
                            {allOrganisations && allOrganisations.length > 0 ? (
                                <TableContainer sx={classes.tableContainer}>
                                    <Table sx={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={290}>Id</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Stripe Customer ID</TableCell>
                                                <TableCell>Owner name</TableCell>
                                                <TableCell>Owner email address</TableCell>
                                                <TableCell>Owner user ID</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allOrganisations?.map((orgCounter) => (
                                                <TableRow key={orgCounter.organisationId}>
                                                    <TableCell>{orgCounter.organisationId}</TableCell>
                                                    <TableCell>{orgCounter.name}</TableCell>
                                                    <TableCell>{orgCounter.paymentGatewayCustomerId}</TableCell>
                                                    <TableCell>{`${orgCounter.organisationUsers?.[0]?.firstName} ${orgCounter.organisationUsers?.[0]?.lastName}`}</TableCell>
                                                    <TableCell>
                                                        {orgCounter.organisationUsers?.[0]?.emailAddress}
                                                    </TableCell>
                                                    <TableCell>{orgCounter.organisationUsers?.[0]?.userId}</TableCell>
                                                    <TableCell>
                                                        {organisation.organisationId !== orgCounter.organisationId ? (
                                                            <Button
                                                                color="primary"
                                                                disabled={!isValid || appIsLoading}
                                                                size="small"
                                                                variant="contained"
                                                                onClick={handleOrganisationSelectClick(
                                                                    orgCounter.organisationId
                                                                )}
                                                            >
                                                                {appIsLoading ? <CircularProgress size={20} /> : null}
                                                                {t('Select')}
                                                            </Button>
                                                        ) : (
                                                            t('Current selection')
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Button
                                    color="primary"
                                    disabled={!isValid || isLoadingOrganisations}
                                    size="small"
                                    variant="contained"
                                    onClick={handleLoadOrganisationClick}
                                >
                                    {isLoadingOrganisations ? t('Loading Organisations') : t('Load Organisations')}
                                    {isLoadingOrganisations ? (
                                        <CircularProgress
                                            size={20}
                                            sx={{ ml: 2 }}
                                        />
                                    ) : null}
                                </Button>
                            )}
                        </CardContent>
                    </Card>
                ) : null}

                {showSiteAdminOnlySettings ? (
                    <Card>
                        <CardHeader title={t('Users')} />
                        <CardContent>
                            {allUsers && allUsers.length > 0 ? (
                                <TableContainer sx={classes.tableContainer}>
                                    <Table sx={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={290}>Id</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>First Name</TableCell>
                                                <TableCell>Last Name</TableCell>
                                                <TableCell>User Role</TableCell>
                                                <TableCell width={520}>Organisations</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allUsers?.map((user) => (
                                                <TableRow key={user.userId}>
                                                    <TableCell>{user.userId}</TableCell>
                                                    <TableCell>{user.emailAddress}</TableCell>
                                                    <TableCell>{user.firstName}</TableCell>
                                                    <TableCell>{user.lastName}</TableCell>
                                                    <TableCell>
                                                        {Object.keys(AuthorisationUtils.UserRole).map((role) => (
                                                            <Chip
                                                                color={user.userRole === role ? 'success' : 'default'}
                                                                disabled={appIsLoading}
                                                                key={role}
                                                                label={role}
                                                                onClick={handleSetUserRole(user, role)}
                                                                onDelete={handleUserRoleRevoke(user)}
                                                            />
                                                        ))}
                                                    </TableCell>
                                                    <TableCell>
                                                        <TableContainer sx={classes.tableContainer}>
                                                            <Table sx={classes.table}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell width={290}>OrgId</TableCell>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell>Role</TableCell>
                                                                        <TableCell width={150}>Actions</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {user.organisationUsers?.map((ou) => (
                                                                        <TableRow
                                                                            key={`${ou.userId}_${ou.organisationId}`}
                                                                        >
                                                                            <TableCell>{ou.organisationId}</TableCell>
                                                                            <TableCell>{ou.organisationName}</TableCell>
                                                                            <TableCell>{ou.role}</TableCell>
                                                                            <TableCell>
                                                                                {user.lastAccessedOrganisationId !==
                                                                                ou.organisationId ? (
                                                                                    <>
                                                                                        <TbxTooltip
                                                                                            title={t(
                                                                                                ou?.isDeleted
                                                                                                    ? 'Enable user'
                                                                                                    : 'Disable user'
                                                                                            )}
                                                                                        >
                                                                                            <IconButton
                                                                                                disabled={
                                                                                                    !isValid ||
                                                                                                    appIsLoading
                                                                                                }
                                                                                                size="small"
                                                                                                sx={classes.iconButton}
                                                                                                onClick={handleOrganisationUserToggleClick(
                                                                                                    ou
                                                                                                )}
                                                                                            >
                                                                                                {ou?.isDeleted ? (
                                                                                                    <Unarchive
                                                                                                        sx={
                                                                                                            classes.icon
                                                                                                        }
                                                                                                    />
                                                                                                ) : (
                                                                                                    <Archive
                                                                                                        sx={
                                                                                                            classes.icon
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                            </IconButton>
                                                                                        </TbxTooltip>
                                                                                        <Button
                                                                                            color="primary"
                                                                                            disabled={
                                                                                                !isValid || appIsLoading
                                                                                            }
                                                                                            size="small"
                                                                                            sx={classes.button}
                                                                                            variant="contained"
                                                                                            onClick={handleOrganisationUserSetAsSelectedClick(
                                                                                                ou
                                                                                            )}
                                                                                        >
                                                                                            {t('Select')}
                                                                                        </Button>
                                                                                    </>
                                                                                ) : (
                                                                                    t('Currently selected')
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Button
                                    color="primary"
                                    disabled={!isValid || isLoadingUsers}
                                    size="small"
                                    variant="contained"
                                    onClick={handleLoadUsersClick}
                                >
                                    {isLoadingUsers ? t('Loading Users') : t('Load Users')}
                                    {isLoadingUsers ? (
                                        <CircularProgress
                                            size={20}
                                            sx={{ ml: 2 }}
                                        />
                                    ) : null}
                                </Button>
                            )}
                        </CardContent>
                    </Card>
                ) : null}
            </Box>
        </>
    ) : null
}

Administration.propTypes = {
    onSettingsUpdated: PropTypes.func,
    onSettingsUpdateError: PropTypes.func,
}

export default Administration
