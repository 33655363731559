import { Box } from '@mui/system'

const ItemRow = ({ children }) => (
    <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
    >
        {children}
    </Box>
)

export default ItemRow
