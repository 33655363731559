import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { selectUseImperialUnits } from '@/app/slices/organisationSlice'

import DeliveryContainersRow from './DeliveryContainersRow'

const classes = {
    tableContainer: {
        boxSizing: 'border-box',
    },
    table: {
        tableLayout: 'fixed',
        boxSizing: 'border-box',
        borderCollapse: 'separate',
    },
    headerTableCell: {
        paddingBlock: 1,
        paddingInline: 1,
        verticalAlign: 'bottom',
        fontWeight: 700,
        borderBottom: 'none',
        lineHeight: 1.4,
    },
}

const DeliveryContainersTable = ({ deliveryContainerDetails, deliveryPriceOption }) => {
    const { t } = useTranslation()

    const useImperialUnits = useSelector(selectUseImperialUnits)

    const lengthUnit = useImperialUnits ? 'in' : 'mm'
    const weightUnit = useImperialUnits ? 'lb' : 'kg'

    return (
        <TableContainer sx={classes.tableContainer}>
            <Table sx={classes.table}>
                <colgroup>
                    <col style={{ width: '120px' }} />
                    <col style={{ width: '100px' }} />
                    <col style={{ width: '160px' }} />
                    <col style={{ width: '80px' }} />
                    <col style={{ width: '100px' }} />
                    {deliveryPriceOption === 'ByDistance' ? <col style={{ width: '100px' }} /> : null}
                    {deliveryPriceOption === 'ByWeight' ? (
                        <>
                            <col style={{ width: '100px' }} />
                            <col style={{ width: '100px' }} />
                        </>
                    ) : null}
                    <col style={{ width: '100px' }} />
                </colgroup>

                <TableHead>
                    <TableRow>
                        <TableCell sx={classes.headerTableCell}></TableCell>
                        <TableCell
                            align="right"
                            sx={classes.headerTableCell}
                        >
                            {t('Maximum weight')} ({weightUnit})
                        </TableCell>
                        <TableCell
                            align="right"
                            sx={classes.headerTableCell}
                        >
                            {t('Maximum size')} <br />({t('L x W x H')} {lengthUnit})
                        </TableCell>

                        <TableCell
                            align="right"
                            sx={classes.headerTableCell}
                        >
                            {t('Quantity')}
                        </TableCell>

                        <TableCell
                            align="right"
                            sx={classes.headerTableCell}
                        >
                            {t('Flat price')}
                        </TableCell>
                        {deliveryPriceOption === 'ByDistance' ? (
                            <TableCell
                                align="right"
                                sx={classes.headerTableCell}
                            >
                                {useImperialUnits ? t('Price per mile') : t('Price per kilometre')}
                            </TableCell>
                        ) : null}
                        {deliveryPriceOption === 'ByWeight' ? (
                            <>
                                <TableCell
                                    align="right"
                                    sx={classes.headerTableCell}
                                >
                                    {useImperialUnits ? t('Price per pound') : t('Price per kilogram')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={classes.headerTableCell}
                                >
                                    {t('Net weight')} ({weightUnit})
                                </TableCell>
                            </>
                        ) : null}

                        <TableCell
                            align="right"
                            sx={classes.headerTableCell}
                        >
                            {t('Container price')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deliveryContainerDetails?.map((deliveryContainer) => (
                        <DeliveryContainersRow
                            deliveryContainer={deliveryContainer}
                            deliveryPriceOption={deliveryPriceOption}
                            key={`${_.kebabCase(deliveryContainer?.containerName)}-${deliveryContainer?.deliveryContainerDetailsId}`}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

DeliveryContainersTable.propTypes = {
    deliveryPriceOption: PropTypes.string.isRequired,
    deliveryContainerDetails: PropTypes.array,
}

export default DeliveryContainersTable
