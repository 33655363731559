import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Delete, DragHandle, ExpandMore, LineWeightRounded, Undo } from '@mui/icons-material'
import {
    AccordionSummary,
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetStoreSettingsQuery } from '@/app/services/organisation'
import { selectMaterials } from '@/app/services/web-store/webStoreMaterial'
import { useCalculateQuoteMutation } from '@/app/services/web-store/webStoreQuote'
import {
    useDeleteQuoteItemMutation,
    useUpdateQuoteItemsAttributesMutation,
} from '@/app/services/web-store/webStoreQuoteItems'
import {
    addSelectedItem,
    removeSelectedItem,
    selectExpandedItems,
    selectSelectedItems,
} from '@/app/slices/web-store/webStoreQuoteItemsSlice'

import { formatCurrency } from '../../../helpers/utilities'
import TbxDialog from '../../shared/TbxDialog'
import QuoteItemStatus from '../QuoteItemStatus'

const classes = {
    summaryRoot: {
        '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
            margin: '6px 0',
            '&.Mui-expanded': {
                margin: '6px 0',
            },
        },
    },
    AccordionSummaryLeft: {
        gap: 1.5,
    },

    AccordionSummaryRight: {
        gap: 6,
    },

    itemSummaryCollapsed: {
        gap: 5,
    },
    itemSummaryCollapsed_LinePrice: {
        width: '70px',
    },
    smallText: {
        fontSize: '0.75rem',
    },
    calculatingIcon: {
        marginTop: '4px',
    },
}

const ItemSummary = ({ hasErrors, hasIssues, hasWarnings, listeners, quoteItem, setActivatorNodeRef }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { organisationId, quoteId } = useParams()

    const { data: storeSettings } = useGetStoreSettingsQuery({
        organisationId,
    })

    const selectedItemsArray = useSelector(selectSelectedItems)
    const expandedItemsArray = useSelector(selectExpandedItems)

    const [deleteQuoteItem, { isLoading: isDeleting }] = useDeleteQuoteItemMutation()
    const [updateQuoteItems] = useUpdateQuoteItemsAttributesMutation()

    const [isSelected, setIsSelected] = useState(false)
    const [isExpanded, setIsExpanded] = useState(true)

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

    const unit = quoteItem.isImperial ? 'in' : 'mm'

    const materials = useSelector((state) => selectMaterials(state, { organisationId }))

    const quoteMaterial = materials.find((material) => material.materialId === quoteItem.materialId)

    const [calculateQuoteMutation, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-webstore-quote',
    })

    const handleResetButtonClick = async (event) => {
        event.stopPropagation()

        try {
            const quoteItems = {
                quoteItemIds: [quoteItem.id],
                attributes: {
                    materialId: null,
                    thickness: null,
                    cuttingTechnologyId: null,
                    sheetId: null,
                },
            }
            await updateQuoteItems({ organisationId, quoteId, quoteItems })
            enqueueSnackbar(`${quoteItem.name} ${t('reset')}`, { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(`${t('Failed to reset')} ${quoteItem.name} from quote`, { variant: 'error' })
        }
    }

    const handleDeleteDialogOpen = (event) => {
        event.stopPropagation()
        setIsDeleteDialogOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setIsDeleteDialogOpen(false)
    }

    const calculateQuote = async () => {
        try {
            await calculateQuoteMutation({ organisationId, quoteId }).unwrap()
        } catch (error) {
            const errorMessage = t(error.data)
            enqueueSnackbar(errorMessage, { variant: 'error' })
        }
    }

    const handleRemoveQuoteItem = async () => {
        try {
            await deleteQuoteItem({
                organisationId,
                quoteId,
                quoteItemId: quoteItem.id,
            }).unwrap()
            enqueueSnackbar(`${quoteItem.name} ${t('deleted')}`, {
                variant: 'success',
            })
            setIsDeleteDialogOpen(false)
            calculateQuote()
        } catch (error) {
            enqueueSnackbar(`${t('Failed to delete')} ${quoteItem.name} from quote`, {
                variant: 'error',
            })
            setIsDeleteDialogOpen(false)
        }
    }

    const handleSelectionChange = () => {
        setIsSelected(!isSelected)
        if (!isSelected) {
            dispatch(addSelectedItem(quoteItem.id))
        } else {
            dispatch(removeSelectedItem(quoteItem.id))
        }
    }

    useEffect(() => {
        if (selectedItemsArray.includes(quoteItem.id)) {
            setIsSelected(true)
        } else {
            setIsSelected(false)
        }
    }, [selectedItemsArray, quoteItem.id])

    useEffect(() => {
        setIsExpanded(expandedItemsArray.includes(quoteItem.id))
    }, [expandedItemsArray, quoteItem.id])

    return (
        <>
            <AccordionSummary
                aria-controls={`panel${quoteItem.id}-content`}
                aria-label="Expand"
                expandIcon={<ExpandMore />}
                id={`panel${quoteItem.id}-header`}
                sx={classes.summaryRoot}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    sx={classes.AccordionSummaryLeft}
                >
                    <IconButton
                        aria-label="Drag"
                        size="small"
                        {...listeners}
                        ref={setActivatorNodeRef}
                        sx={{ cursor: 'grab' }}
                    >
                        <DragHandle />
                    </IconButton>

                    {!hasIssues ? <QuoteItemStatus /> : null}
                    {hasIssues && hasWarnings ? (
                        <QuoteItemStatus
                            messages={hasWarnings}
                            status="warning"
                        />
                    ) : null}
                    {hasIssues && hasErrors ? (
                        <QuoteItemStatus
                            messages={hasErrors}
                            status="error"
                        />
                    ) : null}

                    <FormControlLabel
                        aria-label="Select part"
                        control={
                            <Checkbox
                                checked={isSelected}
                                color="primary"
                                size="small"
                                onChange={handleSelectionChange}
                                //disabled={Boolean(hasErrors)}
                            />
                        }
                        label={quoteItem.name}
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                    />
                </Box>

                <Box
                    alignItems="center"
                    display="flex"
                    sx={classes.AccordionSummaryRight}
                >
                    {!hasErrors && !isExpanded ? (
                        <Box
                            display="flex"
                            sx={classes.itemSummaryCollapsed}
                        >
                            {quoteItem?.materialId ? (
                                <Box
                                    alignItems="flex-end"
                                    className="itemSummaryCollapsed_LinePrice"
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Typography
                                        sx={classes.smallText}
                                        variant="body2"
                                    >
                                        {quoteMaterial?.materialName}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        style={{ gap: '4px' }}
                                    >
                                        <LineWeightRounded fontSize="small" />
                                        <Typography variant="body2">
                                            {quoteItem?.thickness} {unit}
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : null}
                            <Box
                                alignItems="flex-end"
                                className="itemSummaryCollapsed_Quantity"
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography
                                    sx={classes.smallText}
                                    variant="body2"
                                >
                                    {t('Quantity')}
                                </Typography>
                                <Typography variant="body2">x {quoteItem?.quantity}</Typography>
                            </Box>
                            <Box
                                alignItems="flex-end"
                                className="itemSummaryCollapsed_UnitPrice"
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography
                                    sx={classes.smallText}
                                    variant="body2"
                                >
                                    {t('Unit price')}
                                </Typography>

                                {isCalculating ? (
                                    <CircularProgress
                                        size={14}
                                        sx={classes.calculatingIcon}
                                    />
                                ) : null}

                                {!isCalculating ? (
                                    <Typography variant="body2">
                                        {quoteItem.itemPrice
                                            ? formatCurrency(
                                                  quoteItem.itemPrice,
                                                  storeSettings?.currencyCode,
                                                  storeSettings?.locale
                                              )
                                            : String.fromCharCode(8212)}
                                    </Typography>
                                ) : null}
                            </Box>
                            <Box
                                alignItems="flex-end"
                                display="flex"
                                flexDirection="column"
                                sx={classes.itemSummaryCollapsed_LinePrice}
                            >
                                <Typography
                                    sx={classes.smallText}
                                    variant="body2"
                                >
                                    {t('Line price')}
                                </Typography>

                                {isCalculating ? (
                                    <CircularProgress
                                        size={14}
                                        sx={classes.calculatingIcon}
                                    />
                                ) : null}

                                {!isCalculating ? (
                                    <Typography
                                        style={{ fontWeight: 700 }}
                                        variant="body2"
                                    >
                                        {quoteItem.linePrice
                                            ? formatCurrency(
                                                  quoteItem.linePrice,
                                                  storeSettings?.currencyCode,
                                                  storeSettings?.locale
                                              )
                                            : String.fromCharCode(8212)}
                                    </Typography>
                                ) : null}
                            </Box>
                        </Box>
                    ) : null}
                    <Box
                        alignItems="center"
                        display="flex"
                    >
                        {hasErrors ? (
                            <IconButton
                                aria-label="reset"
                                onClick={(event) => handleResetButtonClick(event)}
                                onFocus={(event) => event.stopPropagation()}
                            >
                                <Undo />
                            </IconButton>
                        ) : null}

                        <IconButton
                            aria-label="delete"
                            onClick={(event) => handleDeleteDialogOpen(event)}
                            onFocus={(event) => event.stopPropagation()}
                        >
                            <Delete />
                        </IconButton>
                    </Box>
                </Box>
            </AccordionSummary>
            <TbxDialog
                closeButtonText={t('cancel')}
                confirmButtonText={isDeleting ? t('Deleting...') : t('Yes, delete')}
                content={`${t('Are you sure you want to delete part')} ${quoteItem.name}`}
                handleClose={handleDeleteDialogClose}
                handleConfirmClose={handleRemoveQuoteItem}
                isLoading={isDeleting}
                isOpen={isDeleteDialogOpen}
                key={quoteItem.id}
                title={`${t('Delete part')} ${quoteItem.name}?`}
            />
        </>
    )
}

ItemSummary.propTypes = {
    hasErrors: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    hasIssues: PropTypes.bool,
    hasWarnings: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    listeners: PropTypes.object,
    quoteItem: PropTypes.object,
    setActivatorNodeRef: PropTypes.func,
}

export default ItemSummary
