import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMsal } from '@azure/msal-react'
import { ArrowForwardRounded } from '@mui/icons-material'
import { Box, Button, Link, Typography } from '@mui/material'

import { selectCurrentUser, selectCurrentUserOrganisations, selectUserRole } from '@/app/slices/appSlice'
import { selectOrganisation } from '@/app/slices/organisationSlice'
import { OrganisationSelect, PageSpinner } from '@/common/components'
import { useToolBoxTreatments, useUserContextSwitcher } from '@/common/hooks'
import { LIBELLULA_PRICING_REDIRECT_URL, TOOLBOX_PRICING_REDIRECT_URL } from '@/common/utils'

const classes = {
    root: {
        width: '100%',
        height: '100%',
        gap: 4,
        mt: 8,
    },
    contentContainer: {
        width: 520,
    },
    title: {
        marginBottom: 2,
    },
    organisationChange: {
        width: 240,
    },
    organisationSelect: {
        cursor: 'pointer',
    },
}

const NoActiveSubscription = () => {
    const { t } = useTranslation()

    const { showLibellulaWhitelabelling } = useToolBoxTreatments()

    const organisation = useSelector(selectOrganisation)
    const userRole = useSelector(selectUserRole)
    const currentUser = useSelector(selectCurrentUser)
    const currentUserOrganisations = useSelector(selectCurrentUserOrganisations)

    const [isLoading, setIsLoading] = useState(true)
    const [userIsOwner, setUserIsOwner] = useState(false)

    const { instance: msalInstance } = useMsal()

    const { setSelectedOrganisation } = useUserContextSwitcher()

    const handleSignOutClick = () => {
        msalInstance.logoutRedirect()
    }

    const handleRequestPricingClick = () => {
        window.open(
            showLibellulaWhitelabelling
                ? LIBELLULA_PRICING_REDIRECT_URL
                : `${TOOLBOX_PRICING_REDIRECT_URL}${organisation?.paymentGatewayCustomerId}`
        )
    }

    useEffect(() => {
        setIsLoading(true)
        setUserIsOwner(userRole === 'owner')
        setIsLoading(false)
    }, [userRole])

    if (isLoading) {
        return <PageSpinner />
    }

    return (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={classes.root}
        >
            <Box sx={classes.contentContainer}>
                <Typography
                    align="center"
                    sx={classes.title}
                    variant="h4"
                >
                    {t('No active subscription')}
                </Typography>

                <Typography
                    align="center"
                    variant="body1"
                >
                    {t('Logged in as')} <b>{currentUser.emailAddress}</b>
                </Typography>

                <Typography
                    align="center"
                    variant="body1"
                >
                    <b>{organisation.name}</b> {t('has no active subscription')}
                </Typography>
            </Box>
            <Button
                color="primary"
                disabled={!userIsOwner}
                endIcon={<ArrowForwardRounded />}
                variant="contained"
                onClick={handleRequestPricingClick}
            >
                {t('Request pricing')}
            </Button>

            {currentUserOrganisations.length > 1 ? (
                <Box sx={classes.organisationChange}>
                    <Typography
                        align="center"
                        style={{ marginBottom: 16 }}
                        variant="body1"
                    >
                        {t('Change organisation')}
                    </Typography>

                    <OrganisationSelect
                        label="Select organisation"
                        size="small"
                        sx={classes.organisationSelect}
                        value={organisation.organisationId}
                        variant="outlined"
                        onChange={setSelectedOrganisation}
                    />
                </Box>
            ) : null}
            <Box
                style={{ textAlign: 'center' }}
                sx={classes.contentContainer}
            >
                <Typography
                    align="center"
                    style={{ marginBottom: 8 }}
                    variant="body2"
                >
                    or
                </Typography>
                <Link
                    color="secondary"
                    component="button"
                    underline="always"
                    variant="body2"
                    onClick={handleSignOutClick}
                >
                    {t('Sign in with a different account')}
                </Link>

                {!userIsOwner ? (
                    <Typography
                        align="center"
                        style={{ marginTop: 8 }}
                        variant="body2"
                    >
                        {t('Contact your subscription owner to activate your organisation subscription')}
                    </Typography>
                ) : null}
            </Box>
        </Box>
    )
}

export default NoActiveSubscription
