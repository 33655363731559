import { webStoreApi } from './webStoreApi'

export const webStoreQuoteItemsApi = webStoreApi
    .enhanceEndpoints({
        addTagTypes: ['WebStoreQuoteItems', 'WebStoreQuoteItemDrawing'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getQuoteItems: builder.query({
                query: ({ organisationId, quoteId }) => `/${organisationId}/${quoteId}/quote-items`,
                providesTags: (result, _error, _arg) => [
                    { type: 'WebStoreQuoteItems', id: 'LIST' },
                    ...(result?.map(({ id }) => ({ type: 'WebStoreQuoteItems', id })) ?? []),
                ],
                transformResponse: (responseData) => {
                    if (responseData.length) {
                        responseData.sort((a, b) => a.index - b.index)
                    }
                    return responseData
                },
            }),

            addQuoteItemsFromFiles: builder.mutation({
                query: ({ drawingFiles, organisationId, quoteId }) => ({
                    url: `/${organisationId}/${quoteId}/quote-items/drawing-files`,
                    method: 'POST',
                    body: drawingFiles,
                }),
                invalidatesTags: [{ type: 'WebStoreQuoteItems', id: 'LIST' }],
            }),

            addQuoteItemsFromParts: builder.mutation({
                query: ({ organisationId, parts, quoteId }) => ({
                    url: `/${organisationId}/${quoteId}/quote-items`,
                    method: 'POST',
                    body: parts,
                }),
                invalidatesTags: [{ type: 'WebStoreQuoteItems', id: 'LIST' }],
            }),

            updateQuoteItems: builder.mutation({
                query: ({ organisationId, quoteId, quoteItems }) => ({
                    url: `/${organisationId}/${quoteId}/quote-items`,
                    method: 'PUT',
                    body: quoteItems,
                }),
                async onQueryStarted({ organisationId, quoteId, quoteItems }, { dispatch, queryFulfilled }) {
                    dispatch(
                        webStoreQuoteItemsApi.util.updateQueryData(
                            'getQuoteItems',
                            { organisationId, quoteId },
                            (draft) => {
                                Object.assign(draft, quoteItems)
                            }
                        )
                    )
                    try {
                        await queryFulfilled
                    } catch {
                        //patchResult.undo()
                        dispatch(
                            webStoreQuoteItemsApi.util.invalidateTags([{ type: 'WebStoreQuoteItems', id: 'LIST' }])
                        )
                    }
                },
            }),

            updateQuoteItemsAttributes: builder.mutation({
                query: ({ organisationId, quoteId, quoteItems }) => ({
                    url: `/${organisationId}/${quoteId}/quote-items/attribute`,
                    method: 'PUT',
                    body: quoteItems,
                }),
                invalidatesTags(result, error, arg) {
                    return arg.quoteItems.quoteItemIds.map((quoteItemId) => ({
                        type: 'WebStoreQuoteItems',
                        id: quoteItemId,
                    }))
                },
            }),

            deleteQuoteItem: builder.mutation({
                query: ({ organisationId, quoteId, quoteItemId }) => ({
                    url: `/${organisationId}/${quoteId}/quote-items/${quoteItemId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (result, error, arg) => [
                    'WebStoreQuote',
                    { type: 'WebStoreQuoteItems', id: 'LIST' },
                    { type: 'WebStoreQuoteItems', id: arg.quoteItemId },
                ],
            }),

            deleteQuoteItems: builder.mutation({
                query: ({ organisationId, quoteId, quoteItemsIds }) => ({
                    url: `/${organisationId}/${quoteId}/quote-items`,
                    method: 'DELETE',
                    body: quoteItemsIds,
                }),
                invalidatesTags: ['WebStoreQuote', 'WebStoreQuoteItems'],
            }),

            getQuoteItemDrawing: builder.query({
                query: ({ organisationId, quoteId, quoteItemId }) =>
                    `/${organisationId}/${quoteId}/quote-items/${quoteItemId}/drawing`,
                providesTags: (_result, _error, _arg) => [{ type: 'WebStoreQuoteItemDrawing', id: 'DRAWING' }],
            }),

            getQuoteItemCadView: builder.query({
                query: ({ organisationId, quoteId, quoteItemId }) =>
                    `/${organisationId}/${quoteId}/quote-items/${quoteItemId}/cad-view`,
            }),
        }),
    })

export const {
    useAddQuoteItemsFromFilesMutation,
    useAddQuoteItemsFromPartsMutation,
    useDeleteQuoteItemMutation,
    useDeleteQuoteItemsMutation,
    useGetQuoteItemCadViewQuery,
    useGetQuoteItemDrawingQuery,
    useGetQuoteItemsQuery,
    useUpdateQuoteItemsAttributesMutation,
    useUpdateQuoteItemsMutation,
} = webStoreQuoteItemsApi
