import { useTranslation } from 'react-i18next'
import { blueGrey } from '@mui/material/colors'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import { ThumbnailImage } from '@/common/components'
import { useNumberFormatter } from '@/common/hooks'
import { getFormattedPrice, hasSevereValidationIssues, quoteItemHasErrors } from '@/common/utils'

import FoldingDetailsDisplay from './FoldingDetailsDisplay'
import SecondaryProcessDetailsDisplay from './SecondaryProcessDetailsDisplay'

const classes = {
    noPageBreak: {
        breakInside: 'avoid',
    },

    colDivider: {
        py: 2,
        borderRight: `1px dashed ${blueGrey[200]}`,
        typography: 'body2',
        '&:last-of-type': {
            borderRight: 0,
        },
    },

    rowDivider: {
        borderBottom: `1px dashed ${blueGrey[200]}`,
    },
}

const QuoteOrderQuoteItemRow = ({ colspan, index, issueSeverityDictionary, organisation, part }) => {
    const { t } = useTranslation()

    const { n: q } = useNumberFormatter({ numberOfDecimalPlaces: 0 })

    const { n: metricNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 2,
    })

    const { n: imperialNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 3,
    })

    const units = organisation?.defaultDrawingUnits?.toString().toLowerCase() === 'imperial' ? 'in' : 'mm'
    const partUnits = (part) => (part.isImperial ? 'in' : 'mm')

    const hasVendorNotes = Boolean(part.vendorNotes)

    const partHasAnyErrors = (part) => {
        const isNull = part === null
        const hasErrors =
            quoteItemHasErrors(part, organisation.defaultDrawingUnits, issueSeverityDictionary) ||
            hasSevereValidationIssues(part)
        return isNull || hasErrors
    }

    const renderPartNameandId = (part) => {
        if (!part.partId) return part.name
        else
            return (
                <>
                    {part.name} <br />
                    {t('ID: ') + part.partId}
                </>
            )
    }

    const renderPartSize = (part) => {
        if (part.displayBoundBoxWidth && part.displayBoundBoxHeight) {
            const n = part.isImperial ? imperialNumberFormatter : metricNumberFormatter
            const bounds = part.profile
                ? part.profile.toLowerCase() === 'circle'
                    ? `${n(part.sheet?.diameter)} x ${n(part.rotaryPartLength)}`
                    : `${n(part.sheet?.sheetWidth)} x ${n(part.sheet?.sheetHeight)} x ${n(part.rotaryPartLength)}`
                : `${n(part.displayBoundBoxWidth)} x ${n(part.displayBoundBoxHeight)}`
            return <div style={{ whiteSpace: 'break-spaces' }}>{`${bounds} ${partUnits(part)}`}</div>
        }
        return <div>{'-'}</div>
    }

    const getMaterialText = (part) => {
        if (part.material) {
            if (part.material.materialName && part.thickness) {
                return (
                    <>
                        {part.material.materialName.toString()}
                        <br />
                        {t('Thickness')}: {`${part.thickness.toString()} ${units}`}
                    </>
                )
            }
        }
        return 'N/A'
    }

    const renderPartDetails = (part) => {
        if (partHasAnyErrors(part) === true)
            return (
                <Typography variant="small">{t('Part issue. This part is not included in this $t(quote).')}</Typography>
            )
        else
            return (
                <>
                    <Typography variant="body2">{t('Cutting')} </Typography>
                    <Typography variant="body2">
                        {t(part.isCustomerSuppliedMaterial ? "Customer's material" : 'Material')}{' '}
                    </Typography>
                    <FoldingDetailsDisplay part={part} />
                    <SecondaryProcessDetailsDisplay part={part} />
                </>
            )
    }

    return (
        <>
            <TableRow>
                <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    sx={[{ py: 2 }, hasVendorNotes && classes.rowDivider]}
                >
                    {index + 1}
                </TableCell>
                <TableCell
                    align="center"
                    sx={[{ py: 2 }, hasVendorNotes && classes.rowDivider]}
                >
                    <ThumbnailImage
                        lazyLoading={false}
                        uri={part.thumbnailUri}
                    />
                </TableCell>
                <TableCell
                    align="left"
                    sx={[{ py: 2 }, hasVendorNotes && classes.rowDivider]}
                >
                    {renderPartNameandId(part)}
                    {renderPartSize(part)}
                </TableCell>
                <TableCell
                    align="left"
                    sx={[{ py: 2 }, hasVendorNotes && classes.rowDivider]}
                >
                    {getMaterialText(part)}
                </TableCell>
                <TableCell
                    align="left"
                    sx={[{ py: 2 }, hasVendorNotes && classes.rowDivider]}
                >
                    {renderPartDetails(part)}
                </TableCell>
                <TableCell
                    align="right"
                    sx={[classes.colDivider, hasVendorNotes && classes.rowDivider, { typography: 'strong2' }]}
                >
                    {q(part.quantity)}
                </TableCell>
                <TableCell
                    align="right"
                    sx={[{ py: 2, typography: 'strong2' }, hasVendorNotes && classes.rowDivider]}
                >
                    {!partHasAnyErrors(part)
                        ? getFormattedPrice(part.itemPrice, organisation.currencyCode, organisation.locale)
                        : null}
                </TableCell>
                <TableCell
                    align="right"
                    sx={[{ py: 2, typography: 'strong2' }, hasVendorNotes && classes.rowDivider]}
                >
                    {!partHasAnyErrors(part)
                        ? getFormattedPrice(part.linePrice, organisation.currencyCode, organisation.locale)
                        : null}
                </TableCell>
            </TableRow>

            {part.vendorNotes ? (
                <TableRow>
                    <TableCell
                        colSpan={colspan}
                        sx={[{ py: 2, borderColor: blueGrey[200] }]}
                    >
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                                sx: { pointerEvents: 'none', fontWeight: 700 },
                            }}
                            InputProps={{
                                sx: { typography: 'inputTextSmall' },
                            }}
                            label={t('Vendor Notes')}
                            sx={{ pointerEvents: 'none' }}
                            value={part.vendorNotes}
                            variant="outlined"
                            fullWidth
                        />
                    </TableCell>
                </TableRow>
            ) : null}
        </>
    )
}

QuoteOrderQuoteItemRow.propTypes = {
    index: PropTypes.number.isRequired,
    organisation: PropTypes.object.isRequired,
    part: PropTypes.object.isRequired,
    colspan: PropTypes.number,
    issueSeverityDictionary: PropTypes.object,
}

export default QuoteOrderQuoteItemRow
