import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Dialog, DialogContent } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useAddQuoteItemsFromPartLibraryMutation } from '@/app/services/quoteItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import PartLibrary from '@/common/components/PartLibrary/PartLibrary'

const classes = {
    dialogContent: {
        display: 'flex',
        flexDirection: 'row',
        p: 0,
    },
}

const AddFromPartLibrary = ({ customer, onClose, open }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const { quoteId } = useParams()
    const organisationId = useSelector((state) => selectOrganisationId(state))

    const [addQuoteItemsFromPartLibrary] = useAddQuoteItemsFromPartLibraryMutation()

    const handleClose = () => {
        onClose()
    }

    const handleAddPartLibraryParts = async (quoteItemsToCreate) => {
        const partsToAdd = []
        quoteItemsToCreate.forEach((partLibraryItem) => {
            const newPart = {
                partLibraryId: partLibraryItem.partLibraryId,
                //partSource: 'partLibrary',
                quantity: partLibraryItem.newQuantity ?? 1,
                //partId: partLibraryItem.partId,
            }
            partsToAdd.push(newPart)
        })

        try {
            await addQuoteItemsFromPartLibrary({ organisationId, quoteId, partsToAdd }).unwrap()
            enqueueSnackbar(`${t(`${quoteItemsToCreate.length} parts added to $t(quote)`)}`, { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(`${t('An error occurred, please try again.')}`, { variant: 'error' })
        } finally {
            handleClose()
        }
    }

    return (
        <Dialog
            fullScreen={true}
            open={open}
            sx={classes.dialogRoot}
            onClose={handleClose}
        >
            <DialogContent sx={classes.dialogContent}>
                <PartLibrary
                    customer={customer}
                    handlePartsLibrarySubmit={handleAddPartLibraryParts}
                    onCancelClicked={handleClose}
                />
            </DialogContent>
        </Dialog>
    )
}

AddFromPartLibrary.propTypes = {
    customer: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default AddFromPartLibrary
