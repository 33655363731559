import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Divider, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectQuoteItems } from '@/app/services/quoteItems'
import { selectQuote, useCalculateDeliveryPriceMutation, useCalculateTaxRateMutation } from '@/app/services/quotes'
import { useGetTaxRatesQuery } from '@/app/services/taxRates'
import { selectOrganisation, selectOrganisationId, selectPaidFeatures } from '@/app/slices/organisationSlice'
import { selectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { fractionToPercentage, QUOTE_STATUS } from '@/common/utils'
import { formatCurrency } from '@/features/web-store/helpers/utilities'

const classes = {
    pricesContainer: {
        borderRadius: 2,
        border: '1px solid #263238',
        background: '#34495E',
        color: '#FFFFFF',
        height: '624px',
    },
    pricePercentage: {
        fontSize: '14px',
    },
    bold: {
        fontWeight: 'bold',
    },
    itemColumn: {
        display: 'flex',
        flex: 1,
    },
    alignRight: {
        justifyContent: 'flex-end',
    },
    divider: {
        borderColor: 'rgba(255, 255, 255, 0.12)',
    },
}

const ItemRow = ({ children }) => (
    <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
    >
        {children}
    </Box>
)

const PriceTotals = () => {
    const { t } = useTranslation()
    const { quoteId } = useParams()
    const { showFolding, showMiscItems, showMultipleTaxRates, showSecondaryProcesses } = useToolBoxTreatments()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)
    const [, { isLoading: isCalculatingDelivery }] = useCalculateDeliveryPriceMutation({
        fixedCacheKey: 'shared-calculate-delivery-price',
    })
    const [, { isLoading: isCalculatingTax }] = useCalculateTaxRateMutation({
        fixedCacheKey: 'shared-calculate-tax-rate',
    })

    const hidePrices =
        useSelector(selectedQuoteStatus) === QUOTE_STATUS.NotCalculated || isCalculatingDelivery || isCalculatingTax

    const selectedQuote = useSelector((state) =>
        selectQuote(state, { organisationId: organisation.organisationId, quoteId })
    )

    const quoteItems = useSelector((state) =>
        selectQuoteItems(state, { organisationId: organisation.organisationId, quoteId })
    )

    const { data: taxRates } = useGetTaxRatesQuery({ organisationId, params: { includeDeleted: true } })

    const areAllItemsCalculated = useMemo(() => {
        return quoteItems?.every((item) => Boolean(item.cutPrice))
    }, [selectedQuote, quoteItems])

    const isMiscItemsEnabled = showMiscItems && paidFeatures?.hasMiscellaneousItems

    const showMinimumQuoteChargePrice = Boolean(selectedQuote.minimumQuoteChargePrice)

    const showDeliveryPrice =
        selectedQuote?.deliveryOption === 1 && selectedQuote?.deliveryPricingMethod === 'ChargeToOrder'

    const subtotal = useMemo(
        () =>
            (selectedQuote.materialPrice || 0) +
            (selectedQuote.processingTotalPrice || 0) +
            (selectedQuote.foldingTotalPrice || 0) +
            (selectedQuote.secondaryProcessTotalPrice || 0) +
            (selectedQuote.miscellaneousItemsTotalPrice || 0),
        [selectedQuote]
    )

    const adjustments = selectedQuote.otherChargeTotalPrice || 0

    const totalWithoutTax = selectedQuote.linePrice

    const quoteMinimumChargePrice = selectedQuote.minimumQuoteChargePrice || 0

    const deliveryPrice = selectedQuote.deliveryPrice || 0

    const subTotalWithDiff = totalWithoutTax + quoteMinimumChargePrice

    const total = selectedQuote.lineTaxedPrice

    const taxes = total - subTotalWithDiff

    const decimalPlaces = showMultipleTaxRates && selectedQuote?.taxRateId ? 4 : 2

    const quoteTaxRate = useMemo(
        () =>
            showMultipleTaxRates && selectedQuote?.taxRateId
                ? (taxRates?.find((taxRate) => taxRate.id === selectedQuote?.taxRateId)?.percentage ??
                  taxes / totalWithoutTax)
                : taxes / totalWithoutTax,
        [selectedQuote, taxRates, taxes, totalWithoutTax]
    )

    return (
        <Box
            display="flex"
            flex={1}
            flexDirection="column"
            gap={2}
            justifyContent="space-between"
            p={3}
            sx={classes.pricesContainer}
        >
            <ItemRow>
                <Typography
                    data-testid="price-totals-section-title"
                    variant="strong1"
                >
                    {t('PRICE TOTALS')}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-material-price-label"
                    sx={classes.itemColumn}
                    variant="body2"
                >
                    {t('Material')}
                </Typography>
                <Typography
                    sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}
                    variant="strong2"
                >
                    {!hidePrices && areAllItemsCalculated
                        ? fractionToPercentage(selectedQuote.materialPrice / subtotal, 0)
                        : null}
                    %
                </Typography>
                <Typography
                    sx={[classes.itemColumn, classes.alignRight]}
                    variant="strong2"
                >
                    {!hidePrices && areAllItemsCalculated && selectedQuote.materialPrice
                        ? formatCurrency(selectedQuote.materialPrice, organisation.currencyCode, organisation.locale)
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-cutting-price-label"
                    sx={classes.itemColumn}
                    variant="body2"
                >
                    {t('Cutting')}
                </Typography>
                <Typography
                    sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}
                    variant="strong2"
                >
                    {!hidePrices && areAllItemsCalculated
                        ? fractionToPercentage(selectedQuote.processingTotalPrice / subtotal, 0)
                        : null}
                    %
                </Typography>
                <Typography
                    sx={[classes.itemColumn, classes.alignRight]}
                    variant="strong2"
                >
                    {!hidePrices && areAllItemsCalculated && selectedQuote.processingTotalPrice
                        ? formatCurrency(
                              selectedQuote.processingTotalPrice,
                              organisation.currencyCode,
                              organisation.locale
                          )
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
            {showFolding ? (
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-folding-price-label"
                        sx={classes.itemColumn}
                        variant="body2"
                    >
                        {t('Folding')}
                    </Typography>
                    <Typography
                        sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}
                        variant="strong2"
                    >
                        {!hidePrices && areAllItemsCalculated
                            ? fractionToPercentage(selectedQuote.foldingTotalPrice / subtotal, 0)
                            : null}
                        %
                    </Typography>
                    <Typography
                        sx={[classes.itemColumn, classes.alignRight]}
                        variant="strong2"
                    >
                        {!hidePrices && areAllItemsCalculated && selectedQuote.foldingTotalPrice
                            ? formatCurrency(
                                  selectedQuote.foldingTotalPrice,
                                  organisation.currencyCode,
                                  organisation.locale
                              )
                            : String.fromCharCode(8212)}
                    </Typography>
                </ItemRow>
            ) : null}
            {showSecondaryProcesses ? (
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-secondary-process-price-label"
                        sx={classes.itemColumn}
                        variant="body2"
                    >
                        {t('Secondary processes')}
                    </Typography>
                    <Typography
                        sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}
                        variant="strong2"
                    >
                        {!hidePrices && areAllItemsCalculated
                            ? fractionToPercentage(selectedQuote.secondaryProcessTotalPrice / subtotal, 0)
                            : null}
                        %
                    </Typography>
                    <Typography
                        sx={[classes.itemColumn, classes.alignRight]}
                        variant="strong2"
                    >
                        {!hidePrices && areAllItemsCalculated && selectedQuote.secondaryProcessTotalPrice
                            ? formatCurrency(
                                  selectedQuote.secondaryProcessTotalPrice,
                                  organisation.currencyCode,
                                  organisation.locale
                              )
                            : String.fromCharCode(8212)}
                    </Typography>
                </ItemRow>
            ) : null}

            {isMiscItemsEnabled ? (
                <>
                    <Divider
                        orientation="horizontal"
                        sx={classes.divider}
                        variant="fullWidth"
                    />

                    <ItemRow>
                        <Typography
                            data-testid="nqp-quote-other-price-label"
                            sx={classes.itemColumn}
                            variant="body2"
                        >
                            {t('Miscellaneous items')}
                        </Typography>
                        <Typography
                            sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}
                            variant="strong2"
                        >
                            {!hidePrices && areAllItemsCalculated
                                ? fractionToPercentage(selectedQuote.miscellaneousItemsTotalPrice / subtotal, 0)
                                : null}
                            %
                        </Typography>
                        <Typography
                            sx={[classes.itemColumn, classes.alignRight]}
                            variant="strong2"
                        >
                            {!hidePrices && areAllItemsCalculated && selectedQuote.miscellaneousItemsTotalPrice
                                ? formatCurrency(
                                      selectedQuote.miscellaneousItemsTotalPrice,
                                      organisation.currencyCode,
                                      organisation.locale
                                  )
                                : String.fromCharCode(8212)}
                        </Typography>
                    </ItemRow>
                </>
            ) : null}

            <Divider
                orientation="horizontal"
                sx={classes.divider}
                variant="fullWidth"
            />

            <ItemRow>
                <Typography
                    data-testid="nqp-quote-subtotal-price-label"
                    sx={classes.itemColumn}
                    variant="body2"
                >
                    {t('Subtotal')}
                </Typography>
                <Typography
                    sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}
                    variant="strong2"
                >
                    {!hidePrices && areAllItemsCalculated ? 100 : null}%
                </Typography>
                <Typography
                    sx={[classes.itemColumn, classes.alignRight]}
                    variant="strong2"
                >
                    {!hidePrices && areAllItemsCalculated && selectedQuote.linePrice
                        ? formatCurrency(subtotal, organisation.currencyCode, organisation.locale)
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-adjustment-price-label"
                    sx={classes.itemColumn}
                    variant="body2"
                >
                    {t('Adjustment')}
                </Typography>
                <Typography
                    sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}
                    variant="strong2"
                >
                    {!hidePrices && areAllItemsCalculated ? fractionToPercentage(adjustments / subtotal, 0) : null}%
                </Typography>
                <Typography
                    sx={[classes.itemColumn, classes.alignRight]}
                    variant="strong2"
                >
                    {!hidePrices && areAllItemsCalculated && selectedQuote.otherChargeTotalPrice
                        ? formatCurrency(adjustments, organisation.currencyCode, organisation.locale)
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
            <Divider
                orientation="horizontal"
                sx={classes.divider}
                variant="fullWidth"
            />

            {showMinimumQuoteChargePrice ? (
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-minimum-charge-price-label"
                        sx={classes.itemColumn}
                        variant="body2"
                    >
                        {t('Minimum order charge')}
                    </Typography>
                    <Typography
                        sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}
                        variant="strong2"
                    >
                        {!hidePrices && areAllItemsCalculated
                            ? fractionToPercentage(quoteMinimumChargePrice / totalWithoutTax, 0)
                            : null}
                        %
                    </Typography>
                    <Typography
                        sx={[classes.itemColumn, classes.alignRight]}
                        variant="strong2"
                    >
                        {!hidePrices && areAllItemsCalculated && organisation.minimumQuoteChargePrice
                            ? formatCurrency(quoteMinimumChargePrice, organisation.currencyCode, organisation.locale)
                            : String.fromCharCode(8212)}
                    </Typography>
                </ItemRow>
            ) : null}

            {showDeliveryPrice ? (
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-minimum-charge-price-label"
                        sx={classes.itemColumn}
                        variant="body2"
                    >
                        {t('Delivery')}
                    </Typography>
                    <Typography
                        sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}
                        variant="strong2"
                    >
                        {!hidePrices && areAllItemsCalculated
                            ? fractionToPercentage(deliveryPrice / subtotal, 0)
                            : null}
                        %
                    </Typography>
                    <Typography
                        sx={[classes.itemColumn, classes.alignRight]}
                        variant="strong2"
                    >
                        {!hidePrices && areAllItemsCalculated && selectedQuote.deliveryPrice
                            ? formatCurrency(deliveryPrice, organisation.currencyCode, organisation.locale)
                            : String.fromCharCode(8212)}
                    </Typography>
                </ItemRow>
            ) : null}
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-subtotal-price-label"
                    sx={classes.itemColumn}
                    variant="body2"
                >
                    {t('Subtotal')}
                </Typography>
                <Typography
                    sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}
                    variant="strong2"
                >
                    {!hidePrices && areAllItemsCalculated ? fractionToPercentage(subTotalWithDiff / subtotal, 0) : null}
                    %
                </Typography>
                <Typography
                    sx={[classes.itemColumn, classes.alignRight]}
                    variant="strong2"
                >
                    {!hidePrices && areAllItemsCalculated && totalWithoutTax
                        ? formatCurrency(subTotalWithDiff, organisation.currencyCode, organisation.locale)
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-tax-price-label"
                    sx={classes.itemColumn}
                    variant="body2"
                >
                    {t(`Tax (${quoteTaxRate ? `${fractionToPercentage(quoteTaxRate, decimalPlaces)}%` : '0%'})`)}
                </Typography>
                <Typography
                    sx={[classes.itemColumn, classes.alignRight]}
                    variant="strong2"
                >
                    {!hidePrices && areAllItemsCalculated && taxes
                        ? formatCurrency(taxes, organisation.currencyCode, organisation.locale)
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
            <Divider
                orientation="horizontal"
                sx={classes.divider}
                variant="fullWidth"
            />
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-total-price-label"
                    sx={classes.itemColumn}
                    variant="strong1"
                >
                    {t('Total')}
                </Typography>
                <Typography
                    sx={[classes.itemColumn, classes.alignRight]}
                    variant="strong1"
                >
                    {!hidePrices && areAllItemsCalculated && selectedQuote.lineTaxedPrice
                        ? formatCurrency(selectedQuote.lineTaxedPrice, organisation.currencyCode, organisation.locale)
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
        </Box>
    )
}

PriceTotals.propTypes = {
    quote: PropTypes.object,
}

export default memo(PriceTotals)
