import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {} from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { ArrowForwardRounded, ErrorTwoTone, HighlightOffTwoTone } from '@mui/icons-material'
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import { useGetStoreSettingsQuery } from '@/app/services/organisation'
import { useCalculateQuoteMutation, useGetQuoteQuery } from '@/app/services/web-store/webStoreQuote'
import {
    useGetQuoteItemsQuery,
    useUpdateQuoteItemsAttributesMutation,
} from '@/app/services/web-store/webStoreQuoteItems'

import { quoteHasIssues, quoteIssuesCount } from '../../helpers/quoteUtilities'
import { formatCurrency } from '../../helpers/utilities'

import QuoteItemStatus from './QuoteItemStatus'

const classes = {
    orderSummary: {
        padding: 3,
        gap: '24px',
        minHeight: {
            lg: 'calc(100vh - 114px)',
        },
    },
    summaryTitle: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },
    issueMessages: {
        gap: '24px',
    },
    pricingSummary: {
        gap: '12px',
        '& .price': {
            fontWeight: 700,
        },
    },
    checkoutButton: {
        gap: '8px',
        '& a': {
            textDecoration: 'none',
        },
    },
    saveOrder: {
        gap: '8px',
    },
    smallText: {
        fontSize: '0.75rem',
    },
}

const QuoteSummary = () => {
    const { t } = useTranslation()

    const { organisationId, quoteId } = useParams()

    const { isFetching, isLoading: isLoadingQuote } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })
    const { data: storeSettings } = useGetStoreSettingsQuery({
        organisationId,
    })

    const { data: quote } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })

    const { data: quoteItems } = useGetQuoteItemsQuery({
        organisationId,
        quoteId,
    })

    const [, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-webstore-quote',
    })
    const [, { isLoading: isUpdating }] = useUpdateQuoteItemsAttributesMutation({
        fixedCacheKey: 'shared-update-quote-items-attributes',
    })

    const hasIssues = quoteHasIssues(quoteItems)
    const issuesCount = quoteIssuesCount(quoteItems)

    const allItemsCalculated = useCallback(() => {
        return (
            quote?.status === 'Calculated' &&
            quoteItems?.every((item) => Boolean(item.itemPrice) && Boolean(item.linePrice))
        )
    }, [quote, quoteItems])

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={classes.orderSummary}
        >
            <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
            >
                <Typography
                    sx={classes.summaryTitle}
                    variant="body1"
                >
                    {t('Order summary')}
                </Typography>

                <Box
                    alignItems="center"
                    display="flex"
                    style={{ gap: '6px' }}
                >
                    {!isLoadingQuote && hasIssues && Boolean(issuesCount.errors) ? (
                        <QuoteItemStatus
                            count={issuesCount.errors}
                            status="error"
                        />
                    ) : null}
                    {!isLoadingQuote && hasIssues && Boolean(issuesCount.warnings) ? (
                        <QuoteItemStatus
                            count={issuesCount.warnings}
                            status="warning"
                        />
                    ) : null}
                    {!isLoadingQuote && Boolean(issuesCount.success) ? (
                        <QuoteItemStatus
                            count={issuesCount.success}
                            status="success"
                        />
                    ) : null}
                </Box>
            </Box>

            {!isLoadingQuote && (!quote || hasIssues) ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={classes.issueMessages}
                >
                    {issuesCount.errors ? (
                        <Alert
                            icon={<HighlightOffTwoTone />}
                            severity="error"
                            variant="filled"
                        >
                            <AlertTitle>{t('Error')}</AlertTitle>
                            {t(
                                'There is a part in the $t(quote) with an error. Make sure a material has been assigned to it. If the part has an issue, please delete this part to continue with your order or contact us for help.'
                            )}
                        </Alert>
                    ) : null}

                    {issuesCount.warnings ? (
                        <Alert
                            icon={<ErrorTwoTone />}
                            severity="warning"
                            variant="outlined"
                        >
                            <AlertTitle>{t('Warning')}</AlertTitle>
                            {t(
                                "A part in this $t(quote) has issues that don't prevent calculation but might result in a higher price being calculated. Consider fixing the issues and reuploading the part to get a more accurate price."
                            )}
                        </Alert>
                    ) : null}

                    {!quote ? (
                        <Alert
                            icon={<ErrorTwoTone />}
                            severity="error"
                            variant="filled"
                        >
                            <AlertTitle>{t('Error')}</AlertTitle>
                            {t('This $t(quote) link is inactive. Please create a new quote.')}
                        </Alert>
                    ) : null}
                </Box>
            ) : null}

            {Boolean(quoteItems?.length) && !issuesCount.errors ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={classes.pricingSummary}
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Typography variant="body2">{t('Parts')}</Typography>
                        <Typography
                            className="price"
                            variant="body2"
                        >
                            {allItemsCalculated() && !isCalculating
                                ? formatCurrency(
                                      quote?.processingTotalPrice + quote?.materialPrice,
                                      storeSettings?.currencyCode,
                                      storeSettings?.locale
                                  )
                                : String.fromCharCode(8212)}
                        </Typography>
                    </Box>
                    {quote?.minimumQuoteChargePrice > 0 ? (
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography variant="body2">{t('Minimum order charge')}</Typography>
                            <Typography
                                className="price"
                                variant="body2"
                            >
                                {allItemsCalculated() && !isCalculating
                                    ? formatCurrency(
                                          quote?.minimumQuoteChargePrice,
                                          storeSettings?.currencyCode,
                                          storeSettings?.locale
                                      )
                                    : String.fromCharCode(8212)}
                            </Typography>
                        </Box>
                    ) : null}
                    <Divider />
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Typography variant="body1">{t('Subtotal')}</Typography>
                        <Typography
                            className="price"
                            variant="body1"
                        >
                            {allItemsCalculated() && !isCalculating
                                ? formatCurrency(
                                      quote?.linePrice +
                                          (quote?.minimumQuoteChargePrice || 0) -
                                          (quote?.deliveryPrice || 0),
                                      storeSettings?.currencyCode,
                                      storeSettings?.locale
                                  )
                                : String.fromCharCode(8212)}
                        </Typography>
                    </Box>
                </Box>
            ) : null}

            {Boolean(quoteItems?.length) && !issuesCount.errors && allItemsCalculated() ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={classes.checkoutButton}
                >
                    <Typography
                        sx={classes.smallText}
                        variant="body2"
                    >
                        {t('Proceed to checkout for delivery options and tax calculation')}
                    </Typography>

                    <Link to={'checkout'}>
                        <Button
                            color="primary"
                            disabled={isCalculating || isLoadingQuote || isFetching || isUpdating}
                            endIcon={!isCalculating ? <ArrowForwardRounded /> : null}
                            startIcon={isCalculating ? <CircularProgress size={20} /> : null}
                            variant="contained"
                            fullWidth
                        >
                            {isCalculating ? t('Calculating...') : t('Review order')}
                        </Button>
                    </Link>
                </Box>
            ) : null}

            <Box
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="flex-end"
            >
                {storeSettings?.webStoreSupportEmail || storeSettings?.webStoreContactNumber ? (
                    <Box className="needHelp">
                        <Alert
                            severity="info"
                            variant="standard"
                        >
                            <AlertTitle>{t('Need help with your files?')}</AlertTitle>
                            {storeSettings?.webStoreSupportEmail && !storeSettings?.webStoreContactNumber ? (
                                <>
                                    {t('Contact us at')}{' '}
                                    <a href={`mailto:${storeSettings?.webStoreSupportEmail}`}>
                                        {storeSettings?.webStoreSupportEmail}
                                    </a>
                                </>
                            ) : null}
                            {!storeSettings?.webStoreSupportEmail && storeSettings?.webStoreContactNumber ? (
                                <>
                                    {t('Call us at')}{' '}
                                    <a href={`tel:${storeSettings?.webStoreContactNumber}`}>
                                        {storeSettings?.webStoreContactNumber}
                                    </a>
                                </>
                            ) : null}
                            {storeSettings?.webStoreSupportEmail && storeSettings?.webStoreContactNumber ? (
                                <>
                                    {t('Contact us at')}{' '}
                                    <a href={`mailto:${storeSettings?.webStoreSupportEmail}`}>
                                        {storeSettings?.webStoreSupportEmail}
                                    </a>{' '}
                                    {t('or call us at')}{' '}
                                    <a href={`tel:${storeSettings?.webStoreContactNumber}`}>
                                        {storeSettings?.webStoreContactNumber}
                                    </a>
                                </>
                            ) : null}
                        </Alert>
                    </Box>
                ) : null}
            </Box>
        </Box>
    )
}

export default QuoteSummary
