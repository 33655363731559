import { memo, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Script from 'react-load-script'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import { useSnackbar } from 'notistack'

import { useGetCustomerQuery } from '@/app/services/customers'
import { useGetStoreSettingsQuery } from '@/app/services/organisation'
import { useGetQuoteQuery } from '@/app/services/web-store/webStoreQuote'
import { useGetQuoteItemsQuery } from '@/app/services/web-store/webStoreQuoteItems'
import {
    selectCurrentContact,
    selectCurrentCustomer,
    selectGuestUser,
    selectIsContactLoggedIn,
    selectIsGuestLoggedIn,
} from '@/app/slices/web-store/webStoreAuthSlice'
import { QUOTE_USER_TYPE, WEB_STORE_QUOTE_STATUS } from '@/common/utils'

import OrderDetails from '../../components/Order/OrderDetails'
import OrderSummary from '../../components/Order/OrderSummary'

const classes = {
    root: {
        backgroundColor: 'background.paper',
        borderRadius: 2,
        border: (theme) => `1px ${theme.palette.grey[300]} solid`,
    },
    orderSummaryWrapper: (theme) => ({
        [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            top: '88px',
        },
    }),
}

const Order = () => {
    const { t } = useTranslation()

    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const { organisationId, quoteId } = useParams()

    const {
        data: quote,
        isError,
        isLoading,
        isSuccess,
    } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })

    useGetQuoteItemsQuery({
        organisationId,
        quoteId,
    })

    const { data: storeSettings } = useGetStoreSettingsQuery({
        organisationId,
    })

    const isContactLoggedIn = useSelector(selectIsContactLoggedIn)
    const isGuestLoggedIn = useSelector(selectIsGuestLoggedIn)

    const currentContact = useSelector(selectCurrentContact)
    const currentCustomer = useSelector(selectCurrentCustomer)
    const guestUser = useSelector(selectGuestUser)

    useGetCustomerQuery(
        { organisationId, customerId: currentContact?.customerId },
        { skip: !isContactLoggedIn && !currentContact }
    )

    const [scriptLoaded, setScriptLoaded] = useState(false)

    const handleScriptLoad = () => {
        setScriptLoaded(true)
    }

    const form = useForm({
        mode: 'all',
        defaultValues: {
            name: '',
            companyName: '',
            phone: '',
            email: '',
            billingAddress: {
                googleAddress: '',
                line1: '',
                line2: '',
                city: '',
                state: '',
                country: '',
                postcode: '',
            },
            deliveryAddress: {
                googleAddress: '',
                line1: '',
                line2: '',
                city: '',
                state: '',
                country: '',
                postcode: '',
            },
            shippingOption: 'pickup',
            sameAsBillingAddress: false,
            customerDeliveryProvider: '',
            customerDeliveryAccountNumber: '',
            deliveryProviderToUse: 'defaultProvider',
        },
    })

    // Re-render child components if user logs in
    useEffect(() => {
        let contactData
        let customerData

        if (isContactLoggedIn) {
            contactData = currentContact
            customerData = currentCustomer
        }
        if (!isContactLoggedIn && !quote?.customerContactId && isGuestLoggedIn && guestUser) {
            contactData = guestUser
            customerData = guestUser
        }
        if (
            !isContactLoggedIn &&
            quote?.customerContactId &&
            [QUOTE_USER_TYPE.Guest].includes(quote?.quoteUserType) &&
            isGuestLoggedIn &&
            guestUser
        ) {
            contactData = guestUser
            customerData = guestUser
        }

        form.reset({
            name: contactData?.name || '',
            companyName: customerData?.companyName || '',
            phone: contactData?.phone || '',
            email: contactData?.email || '',
            billingAddress: {
                googleAddress: customerData?.billingAddress?.googleAddress || '',
                line1: customerData?.billingAddress?.line1 || '',
                line2: customerData?.billingAddress?.line2 || '',
                city: customerData?.billingAddress?.city || '',
                state: customerData?.billingAddress?.state || '',
                country: customerData?.billingAddress?.country || '',
                postcode: customerData?.billingAddress?.postcode || '',
            },
            deliveryAddress: {
                googleAddress: customerData?.deliveryAddress?.googleAddress || '',
                line1: customerData?.deliveryAddress?.line1 || '',
                line2: customerData?.deliveryAddress?.line2 || '',
                city: customerData?.deliveryAddress?.city || '',
                state: customerData?.deliveryAddress?.state || '',
                country: customerData?.deliveryAddress?.country || '',
                postcode: customerData?.deliveryAddress?.postcode || '',
            },
            shippingOption: contactData?.shippingOption || 'pickup',
            sameAsBillingAddress: customerData?.useOneAddress ?? customerData?.sameAsBillingAddress ?? false,
            customerDeliveryProvider: quote?.customerDeliveryProvider || '',
            customerDeliveryAccountNumber: quote?.customerDeliveryAccountNumber || '',
            deliveryProviderToUse: 'defaultProvider',
        })
    }, [isContactLoggedIn, isGuestLoggedIn, currentContact, currentCustomer, guestUser])

    useEffect(() => {
        if (!storeSettings?.webStoreIsPublic && !isContactLoggedIn) {
            navigate(`/store/${organisationId}/login`)
        }

        if (!isGuestLoggedIn && !isContactLoggedIn) {
            navigate(`/store/${organisationId}/login`)
        }
    }, [isContactLoggedIn, isGuestLoggedIn, storeSettings, navigate, organisationId])

    useEffect(() => {
        if (
            isSuccess &&
            quote?.customerContactId &&
            quote?.quoteUserType === QUOTE_USER_TYPE.Customer &&
            !isContactLoggedIn
        ) {
            navigate(`/store/${organisationId}/login`)
        }

        if (
            isSuccess &&
            quote?.customerContactId &&
            quote?.quoteUserType === QUOTE_USER_TYPE.Customer &&
            isContactLoggedIn &&
            currentContact.contactId !== quote.customerContactId
        ) {
            navigate(`/store/${organisationId}`)
        }
    }, [isSuccess, quote, isContactLoggedIn, organisationId, currentContact, navigate])

    if ((!isLoading && quote && Object.keys(quote).length === 0) || isError) {
        return <Navigate to={`/store/${organisationId}/not-found`} />
    }

    if (!isLoading && isSuccess && quote.status === WEB_STORE_QUOTE_STATUS.NotCalculated) {
        enqueueSnackbar(t('The $t(quote) is not yet calculated!'), {
            variant: 'info',
        })
        return <Navigate to={`/store/${organisationId}/${quoteId}`} />
    }

    if (!isLoading && isSuccess && quote.status === WEB_STORE_QUOTE_STATUS.PendingOrderConfirmation) {
        return <Navigate to={`/store/${organisationId}/${quoteId}/order-confirmation`} />
    }

    if (!isLoading && isSuccess && quote.status === WEB_STORE_QUOTE_STATUS.Calculated) {
        const summaryWrapperStyle = Object.assign({}, classes.root, classes.orderSummaryWrapper)
        return (
            <>
                <Script
                    url={`https://maps.googleapis.com/maps/api/js?key=${
                        import.meta.env.VITE_GOOGLE_API_KEY
                    }&libraries=places`}
                    onLoad={handleScriptLoad}
                />
                {scriptLoaded ? (
                    <>
                        <Grid
                            component="section"
                            lg={9}
                            xs={12}
                            item
                        >
                            <Box sx={classes.root}>
                                <OrderDetails
                                    customerDeliveryProviderOption={
                                        storeSettings?.webStoreCustomerDeliveryProviderOption
                                    }
                                    deliveryPriceMethod={quote.deliveryPricingMethod}
                                    deliveryProvider={quote.deliveryProvider}
                                    form={form}
                                />
                            </Box>
                        </Grid>

                        <Grid
                            component="aside"
                            lg={3}
                            xs={12}
                            item
                        >
                            <Box sx={summaryWrapperStyle}>
                                <OrderSummary form={form} />
                            </Box>
                        </Grid>
                    </>
                ) : null}
            </>
        )
    }
}

export default memo(Order)
