import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { Archive, Unarchive } from '@mui/icons-material'
import { Box, IconButton, Link, TableCell, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useUpdatePartLibraryEntryMutation } from '@/app/services/partLibrary'
import { selectLocale } from '@/app/slices/appSlice'
import {
    selectCurrencyCode,
    selectCurrencySymbol,
    selectOrganisation,
    selectOrganisationId,
    selectPaidFeatures,
    selectUseImperialUnits,
} from '@/app/slices/organisationSlice'
import { DateTime, TbxTooltip, ThumbnailImage } from '@/common/components'
import TbxLocalizationProvider from '@/common/components/TbxLocalizationProvider/TbxLocalizationProvider'
import { useNumberFormatter, useToolBoxTreatments } from '@/common/hooks'
import { getCurrencyFormat, getFormattedPrice, organisationLengthPrecision } from '@/common/utils'

import ArchivePartLibraryHistoryDialog from './ArchivePartLibraryDialog/ArchivePartLibraryDialog'

dayjs.extend(utc)
dayjs.extend(timezone)

const classes = {
    cell: (isDeleted) => ({
        // padding: '24px',
        color: isDeleted ? 'text.disabled' : 'text.primary',
        fontSize: '14px',
        // border: 'none',
    }),
    cellWithEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    row: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },

    icon: {
        color: 'text.primary',
    },
    disabledIcon: (isDeleted) => ({
        color: isDeleted ? 'text.disabled' : 'text.primary',
    }),

    link: {
        textDecoration: 'underline',
        color: 'inherit',
    },

    tableCell: {
        py: 1.5,
    },
    tableCellBorder: {
        borderRight: (theme) => `1px dashed ${theme.palette.text.disabled}`,
    },
}

const PartLibraryRow = ({
    archivePartLibrary,
    handleDetailSecondaryProcessClick,
    handleProjectMoreDetailsClick,
    partFromLibrary,
    unarchivePartLibrary,
}) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const locale = useSelector(selectLocale)
    const currencyCode = useSelector(selectCurrencyCode)
    const currencySymbol = useSelector(selectCurrencySymbol)

    const { showFixedPriceParts } = useToolBoxTreatments()

    const currencyFormat = getCurrencyFormat(currencyCode, locale)
    const placeholder = getFormattedPrice(0, currencyCode, locale, true)

    const [selectedPartLibrary, setSelectedPartLibrary] = useState(null)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const showFixedPricePartsColumn = showFixedPriceParts && paidFeatures?.hasFixedPriceParts

    const [updatePartLibraryEntry] = useUpdatePartLibraryEntryMutation()

    const userTimeZone = dayjs.tz.guess()

    const isDeleted = useMemo(
        () => selectedPartLibrary?.isDeleted || partFromLibrary?.isDeleted,
        [selectedPartLibrary, partFromLibrary]
    )

    const { n: dimensionFormat } = useNumberFormatter({
        numberOfDecimalPlaces: organisation ? organisationLengthPrecision(organisation) : 2,
    })

    const { n: metricNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 2,
    })

    const { n: imperialNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 3,
    })

    const handleDetailProjectClick = (event, selectedPartFromLibrary) => {
        typeof handleProjectMoreDetailsClick === 'function' &&
            handleProjectMoreDetailsClick(event, selectedPartFromLibrary)
    }

    const handleArchivePartLibraryClick = (event, selectedRow) => {
        setSelectedPartLibrary(selectedRow)
        setShowDeleteDialog(true)
    }

    const hideArchiveDialog = () => {
        setSelectedPartLibrary(null)
        setShowDeleteDialog(false)
    }

    const confirmArchivePartLibrary = () => {
        archivePartLibrary(selectedPartLibrary)
        hideArchiveDialog()
    }

    const confirmUnarchivePartLibrary = () => {
        unarchivePartLibrary(selectedPartLibrary)
        hideArchiveDialog()
    }

    const renderProjectDetails = (selectedPartFromLibrary) => {
        return (
            <>
                {selectedPartFromLibrary.lastQuote.quoteName} <br />
                {`${t(selectedPartFromLibrary.lastQuote.quoteStatus)}`} <br />
                <DateTime
                    format="DD-MMM-YYYY"
                    locale={locale}
                >
                    {selectedPartFromLibrary.lastQuote.lastModifiedDate}
                </DateTime>{' '}
                <br />
                <Link
                    href="#"
                    sx={classes.link}
                    onClick={(e) => handleDetailProjectClick(e, selectedPartFromLibrary)}
                >
                    {t('More details')}
                </Link>
            </>
        )
    }

    const renderBounds = (rowData) => {
        if (rowData.minimumBoundBoxWidth && rowData.minimumBoundBoxHeight) {
            const n = rowData.isImperial ? imperialNumberFormatter : metricNumberFormatter
            const bounds = rowData.profile
                ? `${n(rowData.rotaryProfileWidth)} x ${n(rowData.rotaryProfileLength)} x ${n(
                      rowData.rotaryPartLength
                  )}`
                : `${n(rowData.displayBoundBoxWidth ?? rowData.minimumBoundBoxWidth)} x ${n(rowData.displayBoundBoxHeight ?? rowData.minimumBoundBoxHeight)}`
            const units = rowData.isImperial !== useImperialUnits ? (rowData.isImperial ? '(in)' : '(mm)') : ''
            return <div>{`${bounds} ${units}`}</div>
        }
        return <div>{'-'}</div>
    }

    const renderSecondaryProcess = (partFromLibrary) => {
        const { numberOfFolds, partLibraryEntrySecondaryProcesses } = partFromLibrary
        if (partLibraryEntrySecondaryProcesses.length > 0 || numberOfFolds > 0) {
            return (
                <>
                    {numberOfFolds > 0 ? <div>{t('Folding')}</div> : null}
                    {partLibraryEntrySecondaryProcesses.map((i) => (
                        <Box
                            key={i.partLibraryEntrySecondaryProcessId}
                            sx={classes.cellWithEllipsis}
                        >
                            {i.secondaryProcess.name}
                        </Box>
                    ))}
                    <Link
                        href="#"
                        sx={classes.link}
                        onClick={(e) =>
                            handleDetailSecondaryProcessClick(e, partLibraryEntrySecondaryProcesses, numberOfFolds)
                        }
                    >
                        {t('More details')}
                    </Link>
                </>
            )
        }
    }

    // Material column
    const renderMaterialCell = (materialName, thickness) => (
        <Box sx={classes.cell(isDeleted)}>
            <Tooltip title={materialName ?? ''}>
                <Box>
                    <Typography variant="body2">{materialName || '[Unnamed part]'}</Typography>
                </Box>
            </Tooltip>
            <Box sx={classes.cell(isDeleted)}>
                {
                    <Typography sx={classes.cell(isDeleted)}>
                        {dimensionFormat(thickness)} {useImperialUnits ? 'in' : 'mm'}
                    </Typography>
                }
            </Box>
        </Box>
    )

    const handleFixPriceChange = async (values) => {
        const { floatValue } = values

        try {
            await updatePartLibraryEntry({
                organisationId,
                customerId: partFromLibrary.customerId,
                partLibraryEntryId: partFromLibrary.partLibraryId,
                fixedPrice: floatValue || null,
                fixedPriceEndTime: floatValue
                    ? partFromLibrary.fixedPriceEndTime
                        ? partFromLibrary.fixedPriceEndTime.toString()
                        : null
                    : null,
            })
            enqueueSnackbar(t('Part fixed price updated successfully'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        }
    }

    const handleFixedPriceEndTime = async (date) => {
        try {
            await updatePartLibraryEntry({
                organisationId,
                customerId: partFromLibrary.customerId,
                partLibraryEntryId: partFromLibrary.partLibraryId,
                fixedPrice: partFromLibrary.fixedPrice,
                fixedPriceEndTime: date?.toISOString() || null,
            })
            enqueueSnackbar(t('Part fixed price end date updated successfully'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        }
    }

    return (
        <TbxLocalizationProvider>
            <TableRow sx={classes.row}>
                <TableCell
                    align="left"
                    sx={classes.tableCell}
                >
                    <ThumbnailImage
                        fromPartLibrary={true}
                        uri={partFromLibrary.thumbnailUri}
                    />
                </TableCell>

                <TableCell
                    align="left"
                    sx={[classes.cell(isDeleted), classes.tableCell]}
                >
                    {partFromLibrary.partId}
                </TableCell>

                <TableCell
                    align="left"
                    sx={[classes.cell(isDeleted), classes.tableCell]}
                >
                    {partFromLibrary.name}
                </TableCell>

                <TableCell
                    align="left"
                    sx={[classes.cell(isDeleted), classes.tableCell]}
                >
                    {renderBounds(partFromLibrary)}
                </TableCell>

                <TableCell
                    align="left"
                    sx={[classes.cell(isDeleted), classes.tableCell]}
                >
                    {partFromLibrary.cuttingTechnology.name}
                </TableCell>

                <TableCell
                    align="left"
                    sx={[classes.cell(isDeleted), classes.tableCell]}
                >
                    {renderMaterialCell(partFromLibrary.material.materialName, partFromLibrary.thickness)}
                </TableCell>

                <TableCell
                    align="left"
                    sx={[classes.cell(isDeleted), classes.tableCell, classes.cellWithEllipsis]}
                >
                    {renderSecondaryProcess(partFromLibrary)}
                </TableCell>

                <TableCell
                    align="left"
                    sx={[classes.cell(isDeleted), classes.tableCell]}
                >
                    {renderProjectDetails(partFromLibrary)}
                </TableCell>

                <TableCell
                    align="right"
                    sx={[classes.cell(isDeleted), classes.tableCell]}
                >
                    {partFromLibrary.lastQuote.lastPrice
                        ? getFormattedPrice(
                              partFromLibrary.lastQuote.lastPrice,
                              organisation.currencyCode,
                              organisation.locale
                          )
                        : getFormattedPrice(0, organisation.currencyCode, organisation.locale)}
                </TableCell>

                <TableCell
                    align="right"
                    sx={[
                        classes.cell(isDeleted),
                        classes.tableCell,
                        showFixedPricePartsColumn ? classes.tableCellBorder : null,
                    ]}
                >
                    {partFromLibrary.lastQuote.lastQuantity}
                </TableCell>

                {showFixedPricePartsColumn ? (
                    <TableCell
                        align="center"
                        sx={[classes.cell(isDeleted), classes.tableCell, classes.tableCellBorder, { paddingInline: 1 }]}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1.5}
                        >
                            <NumericFormat
                                allowNegative={false}
                                customInput={TextField}
                                decimalScale={currencyFormat.decimalPlaces}
                                decimalSeparator={currencyFormat.decimal}
                                fixedDecimalScale={true}
                                inputProps={{
                                    style: { textAlign: 'right' },
                                }}
                                InputProps={{
                                    fullWidth: true,
                                    color: 'secondary',
                                }}
                                placeholder={placeholder}
                                prefix={currencyFormat.currencySymbolPosition === 'before' ? currencySymbol : undefined}
                                size="small"
                                suffix={currencyFormat.currencySymbolPosition === 'after' ? currencySymbol : undefined}
                                thousandSeparator={currencyFormat.group}
                                value={partFromLibrary.fixedPrice}
                                variant="outlined"
                                onValueChange={handleFixPriceChange}
                            />

                            <DatePicker
                                closeOnSelect={false}
                                disabled={!partFromLibrary.fixedPrice || partFromLibrary.isDeleted}
                                disableOpenPicker={!partFromLibrary.fixedPrice || partFromLibrary.isDeleted}
                                format="DD-MMM-YYYY"
                                slotProps={{
                                    textField: {
                                        color: 'secondary',
                                        variant: 'outlined',
                                        placeholder: t('End date'),
                                        size: 'small',
                                        inputProps: {
                                            'data-testid': 'part-fixed-price-end-date-input',
                                        },
                                    },
                                    field: {
                                        readOnly: true,
                                    },
                                    actionBar: {
                                        actions: ['clear', 'cancel', 'accept'],
                                        disableSpacing: true,
                                    },
                                }}
                                timezone={userTimeZone}
                                value={
                                    partFromLibrary?.fixedPriceEndTime
                                        ? dayjs(partFromLibrary?.fixedPriceEndTime)
                                        : null
                                }
                                disablePast
                                onAccept={handleFixedPriceEndTime}
                            />
                        </Box>
                    </TableCell>
                ) : null}

                <TableCell
                    align="right"
                    sx={[classes.cell(isDeleted), classes.tableCell]}
                >
                    <TbxTooltip
                        title={partFromLibrary?.isDeleted ? t('Unarchive Part') : t('Archive Part')}
                        arrow
                    >
                        <IconButton
                            disabled={selectedPartLibrary?.isDeleted}
                            size="small"
                            onClick={(event) => handleArchivePartLibraryClick(event, partFromLibrary)}
                        >
                            {partFromLibrary?.isDeleted ? (
                                <Unarchive
                                    fontSize="small"
                                    sx={classes.disabledIcon(isDeleted)}
                                />
                            ) : (
                                <Archive
                                    fontSize="small"
                                    sx={classes.icon}
                                />
                            )}
                        </IconButton>
                    </TbxTooltip>
                </TableCell>

                {showDeleteDialog ? (
                    <ArchivePartLibraryHistoryDialog
                        mode={partFromLibrary.isDeleted ? 'unarchive' : 'archive'}
                        name={partFromLibrary.name}
                        onCancelClose={hideArchiveDialog}
                        onConfirmClose={
                            partFromLibrary.isDeleted ? confirmUnarchivePartLibrary : confirmArchivePartLibrary
                        }
                    />
                ) : null}
            </TableRow>
        </TbxLocalizationProvider>
    )
}

PartLibraryRow.propTypes = {
    archivePartLibrary: PropTypes.func,
    handleChangeSelectedPartLibraryItem: PropTypes.func,
    handleDetailSecondaryProcessClick: PropTypes.func,
    handleProjectMoreDetailsClick: PropTypes.func,
    partFromLibrary: PropTypes.object,
    project: PropTypes.object,
    unarchivePartLibrary: PropTypes.func,
}

export default PartLibraryRow
