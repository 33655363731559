/**
 * Select quote item by id and return warnings.
 * @param {Object} quoteItem - The quote item object.
 * @returns {Array|boolean} - Returns an array of warnings if they exist, otherwise false.
 */
export const quoteItemWarnings = (quoteItem) => {
    return quoteItem?.issues?.warnings?.length ? quoteItem.issues.warnings : false
}

/**
 * Select quote item by id and return errors.
 * @param {Object} quoteItem - The quote item object.
 * @returns {Array|boolean} - Returns an array of errors if they exist, otherwise false.
 */
export const quoteItemErrors = (quoteItem) => {
    return quoteItem?.issues?.errors?.length ? quoteItem.issues.errors : false
}

/**
 * Select quote item by id and return if it is unset.
 * @param {Object} quoteItem - The quote item object.
 * @returns {boolean} - Returns true if the quote item is unset, otherwise false.
 */
export const quoteItemUnset = (quoteItem) => {
    return !quoteItem?.cuttingTechnologyId || !quoteItem?.materialId || !quoteItem?.thickness || !quoteItem?.sheetId
}

/**
 * Select quote item by id and return if it is successful.
 * @param {Object} quoteItem - The quote item object.
 * @returns {boolean} - Returns true if the quote item has no warnings or errors, otherwise false.
 */
export const quoteItemSuccess = (quoteItem) => {
    return !quoteItem?.issues?.warnings?.length && !quoteItem?.issues?.errors?.length
}

/**
 * Select quote item by id and return if it is calculated.
 * @param {Object} quoteItem - The quote item object.
 * @returns {boolean} - Returns true if the quote item has itemPrice and linePrice, otherwise false.
 */
export const quoteItemCalculated = (quoteItem) => {
    return Boolean(quoteItem?.itemPrice) && Boolean(quoteItem?.linePrice)
}

/**
 * Check if any quote items have issues (warnings or errors).
 * @param {Array} quoteItems - The array of quote items.
 * @returns {boolean} - Returns true if any quote item has issues, otherwise false.
 */
export const quoteHasIssues = (quoteItems) => {
    if (!quoteItems?.length) return false
    return quoteItems.some((item) =>
        Object.keys(item?.issues).some((key) => {
            if (key !== '$type') {
                return item?.issues[key]?.length
            }
        })
    )
}

/**
 * Check if any quote items are unset.
 * @param {Array} quoteItems - The array of quote items.
 * @returns {boolean} - Returns true if any quote item is unset, otherwise false.
 */
export const quoteHasUnsetItems = (quoteItems) => {
    if (!quoteItems?.length) return false
    return quoteItems.some((item) => quoteItemUnset(item))
}

/**
 * Count how many quote items have warnings, errors, or are unset.
 * @param {Array} quoteItems - The array of quote items.
 * @returns {Object} - Returns an object with counts of unset, warnings, errors, and success.
 */
export const quoteIssuesCount = (quoteItems) => {
    if (!quoteItems?.length) return false
    return quoteItems.reduce(
        (acc, item) => {
            if (quoteItemUnset(item)) acc.unset += 1
            if (!quoteItemUnset(item) && quoteItemWarnings(item)) acc.warnings += 1
            if (!quoteItemUnset(item) && quoteItemErrors(item)) acc.errors += 1
            if (!quoteItemUnset(item) && quoteItemSuccess(item)) acc.success += 1
            return acc
        },
        { unset: 0, warnings: 0, errors: 0, success: 0 }
    )
}

/**
 * Select quote item by id and check if it has warnings or errors.
 * @param {Object} quoteItem - The quote item object.
 * @returns {boolean} - Returns true if the quote item has issues, otherwise false.
 */
export const quoteItemHasIssues = (quoteItem) => {
    if (!quoteItem?.issues) return false
    return Object.keys(quoteItem?.issues).some((key) => {
        if (key !== '$type') {
            return quoteItem?.issues[key]?.length
        }
    })
}

/**
 * Sort by user-defined index.
 * @param {boolean} isAsc - Sort in ascending order if true, otherwise descending.
 * @returns {Function} - Returns a comparison function for sorting.
 */
const sortByUserDefinedIndex = (isAsc) => (a, b) => {
    return isAsc ? a.index - b.index : b.index - a.index
}

/**
 * Sort by default (creation date).
 * @param {boolean} isAsc - Sort in ascending order if true, otherwise descending.
 * @returns {Function} - Returns a comparison function for sorting.
 */
const sortByDefault = (isAsc) => (a, b) => {
    const dateA = new Date(a.createDateUtc)
    const dateB = new Date(b.createDateUtc)
    return isAsc ? dateA - dateB : dateB - dateA
}

/**
 * Sort by name.
 * @param {boolean} isAsc - Sort in ascending order if true, otherwise descending.
 * @returns {Function} - Returns a comparison function for sorting.
 */
const sortByName = (isAsc) => (a, b) => {
    return isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
}

/**
 * Sort by quantity.
 * @param {boolean} isAsc - Sort in ascending order if true, otherwise descending.
 * @returns {Function} - Returns a comparison function for sorting.
 */
const sortByQuantity = (isAsc) => (a, b) => {
    return isAsc ? a.quantity - b.quantity : b.quantity - a.quantity
}

/**
 * Sort by unit price.
 * @param {boolean} isAsc - Sort in ascending order if true, otherwise descending.
 * @returns {Function} - Returns a comparison function for sorting.
 */
const sortByUnitPrice = (isAsc) => (a, b) => {
    return isAsc ? a.itemPrice - b.itemPrice : b.itemPrice - a.itemPrice
}

/**
 * Sort by material thickness.
 * @param {boolean} isAsc - Sort in ascending order if true, otherwise descending.
 * @param {Object} materials - The materials object.
 * @returns {Function} - Returns a comparison function for sorting.
 */
const sortByMaterialThickness = (isAsc, materials) => (a, b) => {
    const materialArray = Object.values(materials)

    const materialA = materialArray.find((material) => material.materialId === a.materialId)
    const materialB = materialArray.find((material) => material.materialId === b.materialId)

    const materialThicknessA = {
        materialName: materialA?.materialName ?? '',
        thickness: a.thickness,
    }
    const materialThicknessB = {
        materialName: materialB?.materialName ?? '',
        thickness: b.thickness,
    }

    if (materialThicknessA.materialName === materialThicknessB.materialName) {
        return isAsc
            ? materialThicknessA.thickness - materialThicknessB.thickness
            : materialThicknessB.thickness - materialThicknessA.thickness
    }

    return isAsc
        ? materialThicknessA.materialName.localeCompare(materialThicknessB.materialName)
        : materialThicknessB.materialName.localeCompare(materialThicknessA.materialName)
}

/**
 * Get the sorting function based on selected criteria and direction.
 * @param {string} selectedCriteria - The selected criteria for sorting.
 * @param {string} selectedDirection - The selected direction for sorting ('ASC' or 'DESC').
 * @param {Object} materials - The materials object.
 * @returns {Function} - Returns the appropriate sorting function.
 */
export const getSortFunction = (selectedCriteria, selectedDirection, materials) => {
    const isAsc = selectedDirection === 'ASC'
    switch (selectedCriteria) {
        case 'Default':
            return sortByDefault(isAsc)
        case 'Name':
            return sortByName(isAsc)
        case 'MaterialThickness':
            return sortByMaterialThickness(isAsc, materials)
        case 'Quantity':
            return sortByQuantity(isAsc)
        case 'UnitPrice':
            return sortByUnitPrice(isAsc)
        default:
            return sortByUserDefinedIndex(isAsc)
    }
}
