import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Checkbox, Divider, Link, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { DateTime, ThumbnailImage } from '@/common/components'
import { useNumberFormatter } from '@/common/hooks'
import MiscItemIcon from '@/common/icons/MiscItemIcon/MiscItemIcon'

const classes = {
    documentContainer: {
        width: 600,
        margin: '24px auto',
    },
    card: {
        backgroundColor: 'common.white',
        border: 1,
        borderColor: 'grey.300',
        borderRadius: 2,
        width: 600,
        mx: 'auto',
        my: 3,
        minHeight: 240,
        '@media print': {
            margin: '0',
            border: 'none',
        },
    },

    cardSection: {
        py: 1.5,
        px: 1.5,
        breakInside: 'avoid',
    },

    divider: {
        borderRight: 'thin dashed',
        borderColor: blueGrey[100],
    },

    pageBreak: {
        '@media print': {
            breakInside: 'avoid',
        },
        breakInside: 'avoid',
    },

    footerTextContent: {
        '& p': {
            margin: 0,
        },
        '& p:first-of-type': {
            color: 'text.primary',
            '@media print': {
                color: '#2b2b2b',
            },
        },
    },
    footer: {
        '@media print': {
            position: 'relative',
            textAlign: 'center',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 0)',
            display: 'none',
        },
    },
}

const ProductionLabels = ({ customer, issueSeverityDictionary, organisation, quote }) => {
    const { t } = useTranslation()
    const locale = useSelector(selectLocale)

    const { n: metricNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 2,
    })

    const { n: imperialNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 4,
    })

    const { n: q } = useNumberFormatter({ numberOfDecimalPlaces: 0 })

    const formatNumberWithX = (str, item) => {
        const parts = str.split('x')
        const formattedParts = parts.map((part) =>
            item.isImperial ? imperialNumberFormatter(Number(part)) : metricNumberFormatter(Number(part))
        )
        return formattedParts.join(' x ')
    }

    const [contentHeight, setContentHeight] = useState(0)

    const partHasNotes = (part) => {
        return (
            (part.customerNotes && part.customerNotes.length > 0) ||
            (part.vendorNotes && part.vendorNotes.length > 0) ||
            (part.privateNotes && part.privateNotes.length > 0)
        )
    }

    const lengthUnits = organisation?.defaultDrawingUnits?.toString().toLowerCase() === 'imperial' ? 'in' : 'mm'
    const weightUnits = organisation?.defaultDrawingUnits?.toString().toLowerCase() === 'imperial' ? 'lb' : 'kg'
    const partUnits = (part) => (part.isImperial ? 'in' : 'mm')

    const secondsToMinutes = (seconds) => {
        if (!seconds) {
            return '0 min'
        }
        const minutes = (seconds / 60).toFixed(2)
        return `${minutes} min`
    }

    const renderPartSize = (part) => {
        if (part.displayBoundBoxWidth && part.displayBoundBoxHeight) {
            const n = part.isImperial ? imperialNumberFormatter : metricNumberFormatter
            const bounds = part.profile
                ? part.profile.toLowerCase() === 'circle'
                    ? `${n(part.sheet?.diameter)} x ${n(part.rotaryPartLength)}`
                    : `${n(part.sheet?.sheetWidth)} x ${n(part.sheet?.sheetHeight)} x ${n(part.rotaryPartLength)}`
                : `${n(part.displayBoundBoxWidth)} x ${n(part.displayBoundBoxHeight)}`
            return (
                <Typography
                    fontWeight={700}
                    textAlign="center"
                    variant="small"
                >
                    {`${bounds} ${partUnits(part)}`}
                </Typography>
            )
        }
        return <Typography>{'-'}</Typography>
    }

    const renderPartThickness = (part) => {
        const n =
            organisation.defaultDrawingUnits.toLowerCase() === 'imperial'
                ? imperialNumberFormatter
                : metricNumberFormatter
        return part.thickness ? n(part.thickness) : ''
    }

    const renderSecondayProcesses = (part) => {
        let secProcs = [...part.secondaryProcesses]
        if (part.numberOfFolds > 0) {
            if (secProcs) {
                secProcs.unshift({ secondaryProcess: { name: t('Folding') } })
            } else {
                secProcs = [{ secondaryProcess: { name: t('Folding') } }]
            }
        }
        if (secProcs?.length > 0) {
            return (
                <Box
                    alignItems="flex-start"
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    gap={0.5}
                    justifyContent="flex-start"
                >
                    {secProcs.map((secProc, index) => (
                        <Box
                            alignItems="center"
                            display="flex"
                            gap={0.5}
                            key={index}
                        >
                            <Checkbox
                                size="small"
                                sx={{ pointerEvents: 'none', p: 0 }}
                            />
                            <Typography variant="small">{secProc.secondaryProcess.name}</Typography>
                        </Box>
                    ))}
                </Box>
            )
        } else {
            return (
                <Box
                    alignItems="center"
                    display="flex"
                    flex={1}
                    justifyContent="center"
                >
                    <Typography
                        alignItems="center"
                        textAlign="center"
                        variant="strong2"
                    >
                        {t('None')}
                    </Typography>
                </Box>
            )
        }
    }

    const sortQuoteItems = (quoteItems) => {
        return quoteItems.toSorted((a, b) => a.index - b.index)
    }

    const sortMiscItems = (miscItems) => {
        return miscItems.toSorted((a, b) => a.index - b.index)
    }

    const totalItems = quote?.quoteItems?.length + quote?.miscellaneousItems?.length

    useLayoutEffect(() => {
        const heights = Array.from(document.querySelectorAll('.production-label')).map((item) => item.offsetHeight)
        const maxHeight = Math.max(...heights)
        setContentHeight(maxHeight / 1.5)
    }, [])

    return (
        quote &&
        quote.organisationId &&
        quote.quoteItems && (
            <Box
                sx={[
                    classes.documentContainer,
                    {
                        '@media print': {
                            margin: '0',
                            '@page': {
                                size: `400px ${contentHeight}px`,
                                margin: '0',
                            },
                        },
                    },
                ]}
            >
                {sortQuoteItems(quote.quoteItems).map((row, index) => (
                    <Box
                        className="production-label"
                        display="flex"
                        flexDirection="row"
                        id={`production-label-${index}`}
                        key={row.id}
                        sx={[classes.card, classes.pageBreak]}
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            gap={1.5}
                            id={`production-label-${index}-thubmnail`}
                            sx={classes.cardSection}
                            width={175}
                        >
                            <ThumbnailImage
                                lazyLoading={false}
                                uri={row.thumbnailUri}
                                wrapperHeight={150}
                                wrapperWidth={150}
                            />
                            <Box
                                alignItems="center"
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                justifyContent="center"
                            >
                                <Typography
                                    fontWeight={700}
                                    textAlign="center"
                                    variant="small"
                                >
                                    {row.name}
                                </Typography>

                                {renderPartSize(row)}
                            </Box>
                        </Box>

                        <Divider
                            orientation="vertical"
                            sx={classes.divider}
                            variant="fullWidth"
                            flexItem
                        ></Divider>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={0.5}
                            id={`production-label-${index}-details`}
                            sx={classes.cardSection}
                            width={230}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={0.75}
                                id={`production-label-${index}-details__info`}
                            >
                                {row.partId ? (
                                    <Box display="flex">
                                        <Typography
                                            variant="small"
                                            width={70}
                                        >
                                            {t('Part ID:')}
                                        </Typography>
                                        <Typography
                                            fontWeight={700}
                                            variant="small"
                                        >
                                            {row.partId}
                                        </Typography>
                                    </Box>
                                ) : null}

                                <Box display="flex">
                                    <Typography
                                        variant="small"
                                        width={70}
                                    >
                                        {t('Quantity:')}
                                    </Typography>
                                    <Typography
                                        fontWeight={700}
                                        variant="small"
                                    >
                                        {q(row.quantity)}
                                    </Typography>
                                </Box>

                                <Box display="flex">
                                    <Typography
                                        variant="small"
                                        width={70}
                                    >
                                        {t('Unit weight:')}
                                    </Typography>
                                    <Typography
                                        fontWeight={700}
                                        variant="small"
                                    >
                                        {row.mass ? `${row.mass?.toFixed(2)?.toString()} ${weightUnits}` : null}
                                    </Typography>
                                </Box>

                                <Box display="flex">
                                    <Typography
                                        variant="small"
                                        width={70}
                                    >
                                        {t('Material:')}
                                    </Typography>
                                    <Typography
                                        fontWeight={700}
                                        variant="small"
                                    >
                                        {row.material?.materialName}
                                    </Typography>
                                </Box>

                                <Box display="flex">
                                    <Typography
                                        variant="small"
                                        width={70}
                                    >
                                        {t('Thickness:')}
                                    </Typography>
                                    <Typography
                                        fontWeight={700}
                                        variant="small"
                                    >
                                        {`${renderPartThickness(row)} ${lengthUnits}`}
                                    </Typography>
                                </Box>

                                <Box display="flex">
                                    <Typography variant="small">{t('Secondary processes:')}</Typography>
                                </Box>
                            </Box>

                            {renderSecondayProcesses(row)}
                        </Box>

                        <Divider
                            orientation="vertical"
                            sx={classes.divider}
                            variant="fullWidth"
                            flexItem
                        ></Divider>

                        <Box
                            display="flex"
                            flexDirection="column"
                            id={`production-label-${index}-work-order`}
                            justifyContent="space-between"
                            sx={classes.cardSection}
                            width={190}
                        >
                            <Box textAlign="center">
                                <Typography
                                    color="text.secondary"
                                    variant="small"
                                >
                                    {t('Work order number')}
                                </Typography>
                                <Typography variant="strong2">{quote.workOrderNumberText}</Typography>
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    color="text.secondary"
                                    variant="small"
                                >
                                    {t('PO number')}
                                </Typography>
                                <Typography variant="strong2">{quote.poNumber}</Typography>
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    color="text.secondary"
                                    variant="small"
                                >
                                    {t('Customer')}
                                </Typography>
                                <Typography variant="strong2">{customer ? customer.companyName : ''}</Typography>
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    color="text.secondary"
                                    variant="small"
                                >
                                    {t('Required shipping date')}
                                </Typography>
                                <Typography variant="strong2">
                                    {quote.requiredDateUtc ? (
                                        <DateTime
                                            format="DD-MMM-YYYY"
                                            locale={locale}
                                        >
                                            {quote.requiredDateUtc}
                                        </DateTime>
                                    ) : (
                                        'N/A'
                                    )}
                                </Typography>
                            </Box>
                            <Box textAlign="center">
                                <Typography variant="small">{t(`Part ${index + 1} of ${totalItems}`)}</Typography>
                            </Box>
                            {partHasNotes(row) ? (
                                <Box textAlign="center">
                                    <Typography
                                        fontSize={10}
                                        variant="small"
                                    >
                                        {t('* This part has notes, refer to the work order')}
                                    </Typography>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                ))}

                {sortMiscItems(quote.miscellaneousItems).map((item, index) => (
                    <Box
                        display="flex"
                        flexDirection="row"
                        id={`production-label-${index}`}
                        key={item.id}
                        sx={[classes.card, classes.pageBreak]}
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            fontSize={150}
                            gap={1.5}
                            id={`production-label-${index}-thubmnail`}
                            sx={classes.cardSection}
                            width={175}
                        >
                            <MiscItemIcon
                                fontSize="inherit"
                                titleAccess={t('Miscellaneous item icon')}
                            />
                            <Box
                                alignItems="center"
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                justifyContent="center"
                            >
                                <Typography
                                    fontWeight={700}
                                    textAlign="center"
                                    variant="small"
                                >
                                    {item.name}
                                </Typography>
                            </Box>
                        </Box>

                        <Divider
                            orientation="vertical"
                            sx={classes.divider}
                            variant="fullWidth"
                            flexItem
                        ></Divider>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={0.5}
                            id={`production-label-${index}-details`}
                            sx={classes.cardSection}
                            width={230}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={0.75}
                                id={`production-label-${index}-details__info`}
                            >
                                {item.partId ? (
                                    <Box display="flex">
                                        <Typography
                                            variant="small"
                                            width={70}
                                        >
                                            {t('Part ID:')}
                                        </Typography>
                                        <Typography
                                            fontWeight={700}
                                            variant="small"
                                        >
                                            {item.partId}
                                        </Typography>
                                    </Box>
                                ) : null}

                                <Box display="flex">
                                    <Typography
                                        variant="small"
                                        width={70}
                                    >
                                        {t('Quantity:')}
                                    </Typography>
                                    <Typography
                                        fontWeight={700}
                                        variant="small"
                                    >
                                        {q(item.quantity)}
                                    </Typography>
                                </Box>
                                {item.dimensions ? (
                                    <Box display="flex">
                                        <Typography
                                            variant="small"
                                            width={70}
                                        >
                                            {t('Dimensions:')}
                                        </Typography>
                                        <Typography
                                            fontWeight={700}
                                            variant="small"
                                        >
                                            {formatNumberWithX(item.dimensions, item)} {lengthUnits}
                                        </Typography>
                                    </Box>
                                ) : null}

                                {item.weight ? (
                                    <Box display="flex">
                                        <Typography
                                            variant="small"
                                            width={70}
                                        >
                                            {t('Weight:')}
                                        </Typography>
                                        <Typography
                                            fontWeight={700}
                                            variant="small"
                                        >
                                            {item.isImperial
                                                ? imperialNumberFormatter(item.weight)
                                                : metricNumberFormatter(item.weight)}{' '}
                                            {weightUnits}
                                        </Typography>
                                    </Box>
                                ) : null}
                                {item.productionTimePerItemSeconds ? (
                                    <Box display="flex">
                                        <Typography
                                            variant="small"
                                            width={70}
                                        >
                                            {t('Time:')}
                                        </Typography>
                                        <Typography
                                            fontWeight={700}
                                            variant="small"
                                        >
                                            {secondsToMinutes(item.productionTimePerItemSeconds)}
                                        </Typography>
                                    </Box>
                                ) : null}
                            </Box>
                        </Box>

                        <Divider
                            orientation="vertical"
                            sx={classes.divider}
                            variant="fullWidth"
                            flexItem
                        ></Divider>

                        <Box
                            display="flex"
                            flexDirection="column"
                            id={`production-label-${index}-work-order`}
                            justifyContent="space-between"
                            sx={classes.cardSection}
                            width={190}
                        >
                            <Box textAlign="center">
                                <Typography
                                    color="text.secondary"
                                    variant="small"
                                >
                                    {t('Work order number')}
                                </Typography>
                                <Typography variant="strong2">{quote.workOrderNumberText}</Typography>
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    color="text.secondary"
                                    variant="small"
                                >
                                    {t('PO number')}
                                </Typography>
                                <Typography variant="strong2">{quote.poNumber}</Typography>
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    color="text.secondary"
                                    variant="small"
                                >
                                    {t('Customer')}
                                </Typography>
                                <Typography variant="strong2">{customer ? customer.companyName : ''}</Typography>
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    color="text.secondary"
                                    variant="small"
                                >
                                    {t('Required shipping date')}
                                </Typography>
                                <Typography variant="strong2">
                                    {quote?.requiredDateUtc ? (
                                        <DateTime
                                            format="DD-MMM-YYYY"
                                            locale={locale}
                                        >
                                            {quote.requiredDateUtc}
                                        </DateTime>
                                    ) : (
                                        'N/A'
                                    )}
                                </Typography>
                            </Box>
                            <Box textAlign="center">
                                <Typography variant="small">
                                    {t(`Part ${quote.quoteItems.length + index + 1} of ${totalItems}`)}
                                </Typography>
                            </Box>
                            {partHasNotes(item) ? (
                                <Box textAlign="center">
                                    <Typography
                                        fontSize={10}
                                        variant="small"
                                    >
                                        {t('* This part has notes, refer to the work order')}
                                    </Typography>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                ))}

                {!import.meta.env.VITE_RESELLER ? (
                    <Box
                        component="footer"
                        sx={!organisation?.showBrandingInDocumentFooter ? { displayPrint: 'none' } : classes.footer}
                        textAlign="center"
                    >
                        <Typography variant="small">
                            {t('Powered by')}{' '}
                            <Link
                                color="primary"
                                href="https://tempustools.com/"
                                target="_blank"
                                underline="always"
                            >
                                Tempus Tools
                            </Link>
                        </Typography>
                    </Box>
                ) : null}
            </Box>
        )
    )
}

ProductionLabels.propTypes = {
    customer: PropTypes.object,
    issueSeverityDictionary: PropTypes.object,
    organisation: PropTypes.object,
    quote: PropTypes.object,
    showShipping: PropTypes.bool,
}

export default ProductionLabels
