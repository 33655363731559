import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { ChevronRight, CloseRounded, MenuRounded } from '@mui/icons-material'
import {
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
} from '@mui/material'
import { alpha } from '@mui/material/styles'

import { api } from '@/app/services/api'
import {
    selectAppBarState,
    selectCurrentUser,
    selectCurrentUserOrganisations,
    selectShowDrawerMenu,
    selectShowNestedDrawerMenu,
    selectUserRole,
    setShowDrawerMenu,
    setShowNestedDrawerMenu,
} from '@/app/slices/appSlice'
import { selectSelectedCustomerId } from '@/app/slices/customersSlice'
import { selectSelectedMaterialId } from '@/app/slices/materialsSlice'
import { selectOrganisation } from '@/app/slices/organisationSlice'
import { selectSelectedRateTableId } from '@/app/slices/rateTablesSlice'
import { AppBar } from '@/common/components'
import { useToolBoxTreatments, useUserContextSwitcher } from '@/common/hooks'
import { AuthorisationUtils, Paths } from '@/common/utils'
import CustomersList from '@/features/customer-central/components/CustomersList'
import MaterialsList from '@/features/materials/components/MaterialsList'
import RateTablesList from '@/features/rate-tables/components/RateTablesList'

import { AppLogo, OrganisationSelect, UserAvatar } from '..'

import { UserflowHelpWidgetToggle } from './UserflowHelpWidgetToggle'
import { UserflowTooltipsToggle } from './UserflowTooltipsToggle'

const drawerWidth = 250

const classes = {
    appBar: {
        backgroundColor: 'white',
        color: 'white',
        zIndex: 1300,
    },
    appLogo: {
        height: 32,
        px: 2,
        py: 1,
        cursor: 'pointer',
        boxSizing: 'content-box',
    },
    toolbarItems: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: 1210,
        '& .MuiDrawer-paper': {
            width: drawerWidth,
        },
    },
    nestedDrawer: {
        width: 360,
        flexShrink: 0,
        left: 250,
        '& .MuiDrawer-paper': {
            width: 360,
            left: 250,
            p: 3,
        },
    },
    helpersToogles: {
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
    },
    drawerContainer: {
        overflow: 'auto',
        flex: '1',
        mt: '72px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    drawerBackdrop: {
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.5),
    },
    userSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mb: 3,
    },
    userAvatar: {
        backgroundColor: 'primary.main',
    },
    menuButton: {
        color: 'text.primary',
    },
    menuItem: {
        cursor: 'pointer',
    },
    organisationSelect: {
        cursor: 'pointer',
        marginBlockEnd: 1,
    },
    toolbarOffset: (theme) => theme.mixins.toolbar,
    menuDivider: {
        backgroundColor: 'divider',
        margin: '10px 16px',
    },
    signOutButton: {
        textTransform: 'none',
        fontWeight: 'normal',
        color: 'text.primary',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'space-between',
        '& .MuiListItemButton-root': {
            '&:hover': {
                backgroundColor: 'primary.main',
                color: 'white',
            },
        },
        '& .MuiListItemButton-root.Mui-selected': {
            backgroundColor: 'primary.main',
            color: 'white',
            '&:hover': {
                backgroundColor: 'primary.main',
                color: 'white',
            },
        },
    },
}

const MainAppBar = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const location = useLocation()

    const organisation = useSelector(selectOrganisation)
    const currentUser = useSelector(selectCurrentUser)
    const currentUserOrganisations = useSelector(selectCurrentUserOrganisations)
    const userRole = useSelector(selectUserRole)
    const appBarState = useSelector(selectAppBarState)

    const { instance: msalInstance } = useMsal()
    const { showCustomerCentral, showCustomers, showMaterialRevamp, showUserflowTooltips, showWebStore } =
        useToolBoxTreatments()

    const { setSelectedOrganisation } = useUserContextSwitcher()

    const showAdministrationLink = AuthorisationUtils.isSiteAdminOrSupport(msalInstance.getActiveAccount())

    const organisationNotSetup = !organisation

    const selectedRateTableId = useSelector(selectSelectedRateTableId)
    const selectedMaterialId = useSelector(selectSelectedMaterialId)
    const selectedCustomerId = useSelector(selectSelectedCustomerId)

    const showDrawerMenu = useSelector(selectShowDrawerMenu)
    const showNestedDrawerMenu = useSelector(selectShowNestedDrawerMenu)

    const navigationItems = useMemo(() => {
        return [
            {
                label: t('$t(Quotes)'),
                href: Paths.DASHBOARD_PATHNAME,
                hidden: organisationNotSetup,
            },
            {
                label: t('Processes'),
                href: Paths.PROCESS_CONFIGURATION,
                hidden: organisationNotSetup || !userRole || !(userRole === 'administrator' || userRole === 'owner'),
            },
            {
                label: t('Rate Tables'),
                href: Paths.RATE_TABLES,
                displayNestedMenu: 'rate-tables',
                hidden:
                    !showMaterialRevamp ||
                    organisationNotSetup ||
                    !userRole ||
                    !(userRole === 'administrator' || userRole === 'owner'),
            },
            {
                label: t('Materials'),
                href: '/materials',
                displayNestedMenu: 'materials',
                hidden: organisationNotSetup || !userRole || !(userRole === 'administrator' || userRole === 'owner'),
            },
            {
                label: showCustomerCentral ? t('Customer Central') : t('Customers'),
                href: showCustomerCentral ? Paths.CUSTOMER_CENTRAL_PATHNAME : Paths.CUSTOMERS_PATHNAME,
                displayNestedMenu: showCustomerCentral ? 'customer-central' : null,
                hidden:
                    !showCustomers ||
                    organisationNotSetup ||
                    !userRole ||
                    !(userRole === 'administrator' || userRole === 'owner'),
            },
            {
                label: t('Settings'),
                href: '/settings',
            },
            {
                label: t('Administration'),
                href: '/administration',
                hidden: !showAdministrationLink,
            },
        ].filter(Boolean)
    }, [
        appBarState,
        organisationNotSetup,
        userRole,
        showCustomers,
        showMaterialRevamp,
        showWebStore,
        t,
        showAdministrationLink,
    ])

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        dispatch(setShowDrawerMenu(!showDrawerMenu))
        dispatch(setShowNestedDrawerMenu(null))
    }

    const hideDrawer = (event, reason) => {
        if (showNestedDrawerMenu && reason === 'backdropClick') {
            if (showNestedDrawerMenu === 'rate-tables' && !selectedRateTableId) return
            if (showNestedDrawerMenu === 'materials' && !selectedMaterialId) return
            if (showNestedDrawerMenu === 'customer-central' && !selectedCustomerId) return
        }

        dispatch(setShowDrawerMenu(false))
        dispatch(setShowNestedDrawerMenu(null))
    }

    const handleNavigationItemClick = (event, item) => {
        if ('displayNestedMenu' in item) {
            event.preventDefault()
            dispatch(setShowNestedDrawerMenu(item.displayNestedMenu))
            navigate(item.href)
            return
        }

        hideDrawer()
        navigate(item.href)
    }

    const handleLogoClick = () => {
        hideDrawer()
        navigate(Paths.DASHBOARD_PATHNAME)
    }

    const handleSignOutClick = () => {
        dispatch(api.util.resetApiState())
        msalInstance.logoutRedirect()
    }

    const handleMaterialItemClick = () => {
        hideDrawer()
        navigate('materials')
    }

    const handleRateTableItemClick = () => {
        hideDrawer()
        navigate('rate-tables')
    }

    const handleCustomerClick = () => {
        hideDrawer()
        navigate(Paths.CUSTOMER_CENTRAL_PATHNAME)
    }

    const handleOrganisationSelectClick = () => {
        hideDrawer()
    }

    useEffect(() => {
        const { pathname } = location
        const navigationItem = navigationItems.find((item) => item.href === pathname)
        if (showDrawerMenu && navigationItem?.displayNestedMenu) {
            dispatch(setShowNestedDrawerMenu(navigationItem.href.slice(1)))
        }
    }, [location.pathname, navigationItems, showDrawerMenu])

    return (
        <>
            <AppBar
                className={classes.appBar}
                position="sticky"
            >
                {!appBarState?.menuHidden ? (
                    <IconButton
                        edge="start"
                        size="small"
                        sx={classes.menuButton}
                        onClick={toggleDrawer}
                    >
                        {showDrawerMenu ? <CloseRounded /> : <MenuRounded />}
                    </IconButton>
                ) : null}
                <AppLogo
                    className={classes.appLogo}
                    onClick={appBarState?.menuHidden ? undefined : handleLogoClick}
                />

                <Divider
                    orientation="vertical"
                    sx={classes.menuDivider}
                    flexItem
                />

                {/* APP BAR PORTAL */}
                <Box
                    id="app-bar"
                    sx={classes.toolbarItems}
                ></Box>
            </AppBar>

            <Drawer
                anchor="left"
                hideBackdrop={!!showNestedDrawerMenu}
                open={showDrawerMenu}
                slotProps={{
                    backdrop: {
                        sx: classes.drawerBackdrop,
                    },
                }}
                sx={classes.drawer}
                onClose={hideDrawer}
            >
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                >
                    <Box sx={classes.drawerContainer}>
                        <List sx={classes.listItem}>
                            <Box>
                                <ListItem>
                                    {currentUser && currentUserOrganisations && currentUserOrganisations.length > 1 ? (
                                        <OrganisationSelect
                                            sx={classes.organisationSelect}
                                            value={organisation.organisationId}
                                            onChange={setSelectedOrganisation}
                                            onClick={handleOrganisationSelectClick}
                                        />
                                    ) : null}
                                </ListItem>
                                {navigationItems.map((navigationItem) => {
                                    if (navigationItem.isSubheader) {
                                        return (
                                            <ListSubheader
                                                component="div"
                                                key={navigationItem.label}
                                            >
                                                {navigationItem.label}
                                            </ListSubheader>
                                        )
                                    } else {
                                        return (
                                            !navigationItem.hidden && (
                                                <ListItem
                                                    key={navigationItem.label}
                                                    disablePadding
                                                    onClick={(event) =>
                                                        handleNavigationItemClick(event, navigationItem)
                                                    }
                                                >
                                                    <ListItemButton
                                                        selected={
                                                            (navigationItem.href === '/settings' &&
                                                                location.pathname.includes('settings')) ||
                                                            location.pathname === navigationItem.href
                                                        }
                                                    >
                                                        <ListItemText primary={navigationItem.label} />
                                                        {'displayNestedMenu' in navigationItem &&
                                                        showNestedDrawerMenu === navigationItem.displayNestedMenu ? (
                                                            <ChevronRight />
                                                        ) : null}
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        )
                                    }
                                })}
                            </Box>

                            <Box>
                                {showUserflowTooltips ? <UserflowTooltipsToggle /> : null}
                                <UserflowHelpWidgetToggle />
                            </Box>
                        </List>

                        {currentUser ? (
                            <Box sx={classes.userSection}>
                                <Divider
                                    orientation="horizontal"
                                    sx={classes.menuDivider}
                                />
                                <Box
                                    display="flex"
                                    gap={1}
                                    px={2}
                                >
                                    <UserAvatar sx={classes.userAvatar} />
                                    <Button
                                        size="large"
                                        sx={classes.signOutButton}
                                        variant="text"
                                        onClick={handleSignOutClick}
                                    >
                                        {t('Sign out')}
                                    </Button>
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            </Drawer>
            <Drawer
                anchor="left"
                open={!!showNestedDrawerMenu}
                slotProps={{
                    backdrop: {
                        sx: classes.drawerBackdrop,
                    },
                }}
                sx={classes.nestedDrawer}
                onClose={hideDrawer}
            >
                {showNestedDrawerMenu === 'rate-tables' ? <RateTablesList onClose={handleRateTableItemClick} /> : null}
                {showNestedDrawerMenu === 'materials' ? <MaterialsList onClose={handleMaterialItemClick} /> : null}
                {showNestedDrawerMenu === 'customer-central' ? <CustomersList onClose={handleCustomerClick} /> : null}
            </Drawer>
        </>
    )
}

export default MainAppBar
