import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

import { api } from '@/app/services/api'
import { useLazyGetMaterialsQuery } from '@/app/services/materials'
import { useLazyGetOrganisationQuery } from '@/app/services/organisation'
import { useLazyGetOrganisationUserQuery } from '@/app/services/organisationUsers'
import { useGetOrCreateUserContextMutation, useLazyGetUserOrganisationsQuery } from '@/app/services/user'
import {
    selectCurrentUser,
    setAppIsLoading,
    setCurrentUser,
    setLanguage,
    setLocale,
    setUserRole,
} from '@/app/slices/appSlice'
import { setLastPaymentStatus, setOrganisation } from '@/app/slices/organisationSlice'
import { getLanguageToUse, getLocaleToUse, Paths } from '@/common/utils'

import { useBroadcastChannel } from './useBroadcastChannel'

export const useUserContextSwitcher = () => {
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const appInsights = useAppInsightsContext()
    const { i18n } = useTranslation()

    const currentUser = useSelector(selectCurrentUser)

    const [getOrCreateUserContext] = useGetOrCreateUserContextMutation()
    const [getOrganisation] = useLazyGetOrganisationQuery()
    const [getOrganisationUser] = useLazyGetOrganisationUserQuery()
    const [getMaterials] = useLazyGetMaterialsQuery()
    const [getUserOrganisations] = useLazyGetUserOrganisationsQuery()

    const { postMessage } = useBroadcastChannel('orgChangeChannel', (message) => {
        try {
            if (message === 'ORG_CHANGED') {
                navigate(Paths.DASHBOARD_PATHNAME)
                window.location.reload()
            }
        } catch (error) {
            console.error('Failed to process message:', error)
        }
    })

    const trackEvent = ({ data, eventName }) => appInsights.trackEvent({ name: eventName, properties: data })

    const determineRedirectPath = (organisationResult) => {
        if (
            !organisationResult?.hasActiveSubscription &&
            (!organisationResult.trial || organisationResult.trial.hasExpired) &&
            !organisationResult.freePlan
        ) {
            return Paths.NO_ACTIVE_SUBSCRIPTION_PATHNAME
        }
        return Paths.DASHBOARD_PATHNAME
    }

    const setSelectedOrganisation = async (newSelectedOrganisationId) => {
        dispatch(setAppIsLoading(true))
        dispatch(api.util.resetApiState())

        try {
            const userContext = await getOrCreateUserContext({
                user: { ...currentUser, lastAccessedOrganisationId: newSelectedOrganisationId },
            }).unwrap()

            const appUser = userContext.user
            const selectedOrganisationId = userContext.selectedOrganisationId

            let organisationResult
            try {
                organisationResult = await getOrganisation({ organisationId: selectedOrganisationId }).unwrap()
            } catch (error) {
                console.error('An error occurred handling organisation.', error)
            }

            let organisationUserResult
            try {
                organisationUserResult = await getOrganisationUser({
                    organisationId: selectedOrganisationId,
                    userId: appUser.userId,
                }).unwrap()
            } catch (error) {
                console.error('An error occurred handling organisation user.', error)
            }

            try {
                await getMaterials({ organisationId: selectedOrganisationId })
            } catch (error) {
                console.error('An error occurred handling materials.', error)
            }

            await getUserOrganisations({ userId: appUser.userId })

            const role = organisationUserResult?.role?.toLowerCase() || 'user'
            const lastPaymentStatus = userContext.lastPayment?.status?.toLowerCase() || 'unknown'
            const localeToUse = getLocaleToUse(organisationResult)
            const languageToUse = getLanguageToUse(appUser, organisationResult)

            i18n.changeLanguage(languageToUse)
            dispatch(setCurrentUser(appUser))
            dispatch(setOrganisation(organisationResult))
            dispatch(setUserRole(role))
            dispatch(setLastPaymentStatus(lastPaymentStatus))
            dispatch(setLanguage(languageToUse))
            dispatch(setLocale(localeToUse))
            dispatch(setAppIsLoading(false))

            const redirectTo = determineRedirectPath(organisationResult)

            trackEvent({
                eventName: `User redirected to ${redirectTo}`,
                data: { currentUser: appUser, organisation: organisationResult },
            })

            navigate(redirectTo)

            postMessage('ORG_CHANGED')
            return organisationResult
        } catch (e) {
            dispatch(setAppIsLoading(false))
            navigate(Paths.ERROR_PATHNAME)
        }
    }

    return {
        setSelectedOrganisation,
    }
}
