import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Delete } from '@mui/icons-material'
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { useSnackbar } from 'notistack'

import { useGetStoreSettingsQuery } from '@/app/services/organisation'
import { useCreateQuoteMutation } from '@/app/services/web-store/webStoreQuote'
import { useAddQuoteItemsFromFilesMutation } from '@/app/services/web-store/webStoreQuoteItems'
import { selectIsContactLoggedIn, selectIsGuestLoggedIn } from '@/app/slices/web-store/webStoreAuthSlice'
import TbxShadowScroll from '@/common/components/TbxShadowScroll'
import { QUOTE_USER_TYPE } from '@/common/utils'

import FileRequirementsList from '../../components/shared/FileRequirementsList'
import QuoteItemDropzone from '../../components/shared/QuoteItemDropzone'

const classes = {
    root: {
        backgroundColor: 'background.paper',
        borderRadius: 1,
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        width: '100%',
        height: {
            xs: '410px',
            md: 'initial',
        },
        maxHeight: 'calc(100vh - 150px)',
    },
    mainContent: {
        maxWidth: '600px',
        gap: 5,
    },
    mainContent_text: {
        gap: 4,
    },
    draftIdContainer: {
        gap: 0.75,
    },
    draftIdInputContainer: {
        gap: 1.5,
    },
    goToDraftButton: {
        flex: '1 0 auto',
    },
    fileListContainer: {
        overflow: 'hidden',
    },
    fileListWrapper: {
        marginBottom: 2,
    },
    filesToUpload: {
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        marginBottom: 1.5,
    },
    fileItem: {
        px: 1,
        py: 0.5,
        gap: 1,
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05),
        borderRadius: 0.5,
    },
    smallText: {
        fontSize: '0.75rem',
    },
}

const Home = () => {
    const { organisationId } = useParams()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const [draftId, setDraftId] = useState('')
    const [filesToUpload, setFilesToUpload] = useState([])

    const isContactLoggedIn = useSelector(selectIsContactLoggedIn)
    const isGuestLoggedIn = useSelector(selectIsGuestLoggedIn)

    const { data: storeSettings } = useGetStoreSettingsQuery({
        organisationId,
    })

    const handleAcceptedFiles = (acceptedFiles) => {
        setFilesToUpload((prevFiles) => [...prevFiles, ...acceptedFiles])
    }

    const handleFileRemoval = (fileToRemove) => {
        setFilesToUpload((files) => files.filter((file) => file.name !== fileToRemove))
    }

    const handleCleanUploadList = () => {
        setFilesToUpload([])
    }

    const handleDraftIdChange = (e) => {
        setDraftId(e.target.value)
    }

    const handleGoToDraft = () => {
        if (draftId) {
            navigate(`/store/${organisationId}/${draftId}`)
        }
    }

    const [createQuote, { isLoading: isCreating, reset: resetCreateQuote }] = useCreateQuoteMutation()

    const [uploadQuoteItems, { isLoading: isUploading, reset: resetUploadQuoteItems }] =
        useAddQuoteItemsFromFilesMutation()

    const handleCreateQuoteAndFilesUpload = async () => {
        if (filesToUpload.length) {
            try {
                const {
                    data: { id: quoteId },
                } = await createQuote({
                    organisationId,
                    payload: {
                        organisationId,
                        quoteUserType: isContactLoggedIn ? QUOTE_USER_TYPE.Customer : QUOTE_USER_TYPE.Guest,
                    },
                })
                enqueueSnackbar(t('New $t(quote) created, uploading files...'), { variant: 'success' })

                const formData = new FormData()
                filesToUpload.forEach((el) => formData.append('files', el, el.name))

                await uploadQuoteItems({ organisationId, quoteId, drawingFiles: formData }).unwrap()

                enqueueSnackbar(`${filesToUpload.length} file(s) ${t('added to $t(quote)')}`, { variant: 'success' })
                navigate(`/store/${organisationId}/${quoteId}`)
            } catch (e) {
                let fileUploadErrorMessage = t('Failed to upload $t(quote) items')
                if (e?.originalStatus === 408 || e?.originalStatus === 504) {
                    fileUploadErrorMessage = t(
                        'The upload timed out. Please try again. If the problem persists, please contact our support team.'
                    )
                }
                enqueueSnackbar(fileUploadErrorMessage, { variant: 'error' })
            } finally {
                handleCleanUploadList()
                resetCreateQuote()
                resetUploadQuoteItems()
            }
        }
    }

    useEffect(() => {
        if (!storeSettings?.webStoreIsPublic && !isContactLoggedIn) {
            navigate(`/store/${organisationId}/login`)
        }

        if (!isGuestLoggedIn && !isContactLoggedIn) {
            navigate(`/store/${organisationId}/login`)
        }
    }, [isContactLoggedIn, isGuestLoggedIn, storeSettings])

    return (
        <>
            <Grid
                component="section"
                md={7}
                xs={12}
                container
                item
            >
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    p={6}
                    sx={classes.root}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={classes.mainContent}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.mainContent_text}
                        >
                            <Typography variant="h2">
                                {storeSettings?.webStoreHeadingParagraph || t('Welcome to the store')}
                            </Typography>
                            <Typography variant="h5">
                                {storeSettings?.webStoreSubheadingParagraph ||
                                    t('Upload your parts, select the material and get a quote.')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>

            <Grid
                component="aside"
                md={5}
                xs={12}
                container
                item
            >
                <Box
                    display="flex"
                    flexDirection={{ xs: 'row', md: 'column' }}
                    p={3}
                    sx={classes.root}
                >
                    <QuoteItemDropzone
                        isUploading={isUploading}
                        showHeading={true}
                        onFilesAccepted={handleAcceptedFiles}
                    />

                    {!filesToUpload.length ? (
                        <Box
                            flex="1 1 auto"
                            p={3}
                            pb={{ xs: 3, md: 0 }}
                        >
                            <FileRequirementsList />
                        </Box>
                    ) : null}

                    {filesToUpload.length ? (
                        <Box
                            display="flex"
                            flex="1 1 auto"
                            flexDirection="column"
                            justifyContent="space-between"
                            pt={2}
                            px={2}
                            sx={classes.fileListContainer}
                        >
                            <TbxShadowScroll
                                scrollContainerSx={classes.filesToUpload}
                                wrapperSx={classes.fileListWrapper}
                            >
                                {filesToUpload.map((file) => (
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        key={file.name}
                                        sx={classes.fileItem}
                                    >
                                        <Typography variant="body2">{file.name}</Typography>
                                        <IconButton
                                            color="secondary"
                                            size="small"
                                            onClick={() => handleFileRemoval(file.name)}
                                        >
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </Box>
                                ))}
                            </TbxShadowScroll>

                            <Button
                                color="secondary"
                                disabled={isUploading || isCreating || !filesToUpload.length}
                                startIcon={isUploading ? <CircularProgress size={20} /> : null}
                                variant="contained"
                                onClick={handleCreateQuoteAndFilesUpload}
                            >
                                {isUploading ? t('Uploading files...') : t('Upload files')}
                            </Button>
                        </Box>
                    ) : null}
                </Box>
            </Grid>
        </>
    )
}

export default Home
