import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'

import { selectCurrencyCode } from '@/app/slices/organisationSlice'
import { useCurrencyFormatter, useNumberFormatter } from '@/common/hooks'

const classes = {
    tableRow: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    tableCell: {
        paddingBlock: 1,
        paddingInline: 1,
        borderBottom: 'none',
    },
    tableCellWithEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}

const DeliveryContainersRow = ({ deliveryContainer, deliveryPriceOption }) => {
    const { t } = useTranslation()
    const currencyCode = useSelector(selectCurrencyCode)

    const { formatCurrency } = useCurrencyFormatter({ currencyCode })
    const { n } = useNumberFormatter()

    const maximumSize = `${n(deliveryContainer?.maximumLength) || String.fromCharCode(8212)} x ${n(deliveryContainer?.maximumWidth) || String.fromCharCode(8212)} x ${n(deliveryContainer?.maximumHeight) || String.fromCharCode(8212)}`

    return (
        <TableRow sx={classes.tableRow}>
            <TableCell sx={[classes.tableCell, classes.tableCellWithEllipsis]}>
                {deliveryContainer?.containerName}
            </TableCell>
            <TableCell
                align="right"
                sx={[classes.tableCell]}
            >
                {n(deliveryContainer?.maximumWeight) || t('No maximum')}
            </TableCell>
            <TableCell
                align="right"
                sx={[classes.tableCell]}
            >
                {maximumSize}
            </TableCell>
            <TableCell
                align="right"
                sx={[classes.tableCell]}
            >
                {n(deliveryContainer?.quantity)}
            </TableCell>
            <TableCell
                align="right"
                sx={[classes.tableCell]}
            >
                {formatCurrency(deliveryContainer?.flatPrice) ?? String.fromCharCode(8212)}
            </TableCell>
            {deliveryPriceOption === 'ByDistance' ? (
                <TableCell
                    align="right"
                    sx={[classes.tableCell]}
                >
                    {formatCurrency(deliveryContainer?.pricePerDistance) ?? String.fromCharCode(8212)}
                </TableCell>
            ) : null}
            {deliveryPriceOption === 'ByWeight' ? (
                <>
                    <TableCell
                        align="right"
                        sx={[classes.tableCell]}
                    >
                        {formatCurrency(deliveryContainer?.pricePerWeight) ?? String.fromCharCode(8212)}
                    </TableCell>
                    <TableCell
                        align="right"
                        sx={[classes.tableCell]}
                    >
                        {n(deliveryContainer?.sumNetWeightOfParts) ?? String.fromCharCode(8212)}
                    </TableCell>
                </>
            ) : null}
            <TableCell
                align="right"
                sx={[classes.tableCell]}
            >
                {formatCurrency(deliveryContainer?.calculatedPrice) ?? String.fromCharCode(8212)}
            </TableCell>
        </TableRow>
    )
}

DeliveryContainersRow.propTypes = {
    deliveryContainer: PropTypes.object.isRequired,
    deliveryPriceOption: PropTypes.string.isRequired,
}

export default DeliveryContainersRow
