import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import PropTypes from 'prop-types'

import QuoteItem from './QuoteItem/QuoteItem'

const QuoteItemList = ({ quoteItems }) => {
    const quoteItemsIds = quoteItems.map((item) => item.id)

    return (
        <SortableContext
            items={quoteItemsIds}
            strategy={verticalListSortingStrategy}
        >
            {quoteItems.map((quoteItem, index) => (
                <QuoteItem
                    id={quoteItem.id}
                    index={index}
                    key={quoteItem.id}
                    quoteItem={quoteItem}
                />
            ))}
        </SortableContext>
    )
}

QuoteItemList.propTypes = {
    quoteItems: PropTypes.array,
}

export default QuoteItemList
