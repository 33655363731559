import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBackRounded, ExitToApp } from '@mui/icons-material'
import {
    AppBar as MuiAppBar,
    Avatar,
    Box,
    Button,
    Dialog,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar as MuiToolbar,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'

import { useGetStoreSettingsQuery } from '@/app/services/organisation'
import {
    selectCurrentContact,
    selectCurrentCustomer,
    selectIsContactLoggedIn,
    selectOpenLoginDialog,
    setOpenLoginDialog,
    unsetCurrentUser,
} from '@/app/slices/web-store/webStoreAuthSlice'
import { Paths } from '@/common/utils'

import Login from '../../Login/Login'
import AddQuoteItemsDialog from '../../Quote/AddQuoteItemsDialog'
import AppLogo from '../AppLogo'
import TbxDialog from '../TbxDialog'

const classes = {
    appBar: {
        backgroundColor: 'white',
        color: 'white',
    },
    appBarLogo: {
        height: 32,
        px: 2,
        py: 1,
        boxSizing: 'content-box',
    },
    appBarItems: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
    },
    appBarDivider: {
        mx: '15px',
    },
    appBarTitle: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '21px',
        textTransform: 'uppercase',
        color: 'text.primary',
    },
    appBarActions: {
        display: 'flex',
        alignItems: 'center',
        gap: 1,
    },
    dialogPaper: {
        borderRadius: 1,
        width: 480,
    },
}

const AppBar = ({ color = 'default', position = 'sticky' }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { organisationId } = useParams()
    const [showAddPartDialog, setShowAddPartDialog] = useState(false)
    const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const { data: storeSettings } = useGetStoreSettingsQuery({
        organisationId,
    })

    const isLoggedIn = useSelector(selectIsContactLoggedIn)
    const currentUser = useSelector(selectCurrentContact)
    const currentCustomer = useSelector(selectCurrentCustomer)
    const openLoginDialog = useSelector(selectOpenLoginDialog)

    const handleReturnToWebClick = () => {
        window.location.href = storeSettings?.webStoreRedirectLink
            ? storeSettings?.webStoreRedirectLink
            : Paths.WEB_STORE_SETTINGS
    }

    const handleAddPartDialogClose = () => setShowAddPartDialog(false)

    const handleLogoutDialogOpen = () => setIsLogoutDialogOpen(true)

    const handleIsLogoutDialogClose = () => setIsLogoutDialogOpen(false)

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const handleLoginDialogClose = () => {
        dispatch(setOpenLoginDialog(false))
    }

    const handleLogOut = () => {
        dispatch(unsetCurrentUser())
        setAnchorEl(null)
        handleIsLogoutDialogClose()
        navigate(`/store/${organisationId}/login`)
    }

    const handleLogIn = () => {
        setAnchorEl(null)
        dispatch(setOpenLoginDialog(true))
    }

    return (
        <MuiAppBar
            color={color}
            position={position}
            sx={classes.appBar}
        >
            <MuiToolbar>
                <AppLogo
                    logoUrl={storeSettings?.webStoreLogoUri}
                    sx={classes.appBarLogo}
                />

                {storeSettings?.webStoreLogoUri ? (
                    <Divider
                        orientation="vertical"
                        sx={classes.appBarDivider}
                        variant="middle"
                        flexItem
                    />
                ) : null}

                <Box sx={classes.appBarItems}>
                    <Typography sx={classes.appBarTitle}>
                        {storeSettings?.webStoreDisplayName ?? storeSettings?.organisationName}
                    </Typography>

                    <Box sx={classes.appBarActions}>
                        <div id="store-app-bar"></div>

                        {!window.location.pathname.includes('login') ? (
                            <>
                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                />
                                <div className="profileIcon">
                                    <IconButton
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        aria-label="account of current user"
                                        color="primary"
                                        size="small"
                                        onClick={handleMenu}
                                    >
                                        <Avatar>{isLoggedIn && currentUser ? currentUser?.name[0] : 'G'}</Avatar>
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        id="menu-appbar"
                                        open={open}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        onClose={handleCloseMenu}
                                    >
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar />
                                            </ListItemAvatar>
                                            {isLoggedIn && currentUser ? (
                                                <ListItemText
                                                    primary={currentUser?.name}
                                                    secondary={currentCustomer?.companyName}
                                                />
                                            ) : (
                                                <ListItemText primary={t('Guest user')} />
                                            )}
                                        </ListItem>
                                        <Divider />

                                        {isLoggedIn && currentUser ? (
                                            <MenuItem onClick={handleLogoutDialogOpen}>
                                                <ListItemIcon>
                                                    <ExitToApp />
                                                </ListItemIcon>
                                                <ListItemText primary={t('Log out')} />
                                            </MenuItem>
                                        ) : (
                                            <MenuItem onClick={handleLogIn}>
                                                <ListItemIcon>
                                                    <ExitToApp />
                                                </ListItemIcon>
                                                <ListItemText primary={t('Log in')} />
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </div>
                            </>
                        ) : null}

                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />

                        <Button
                            color="secondary"
                            data-testid="return-to-website-button"
                            startIcon={<ArrowBackRounded />}
                            variant="text"
                            onClick={handleReturnToWebClick}
                        >
                            {t('Return to website')}
                        </Button>
                    </Box>
                </Box>
            </MuiToolbar>
            <AddQuoteItemsDialog
                open={showAddPartDialog}
                onClose={handleAddPartDialogClose}
            />

            {isLoggedIn && currentUser ? (
                <TbxDialog
                    closeButtonText={t('cancel')}
                    confirmButtonText={t('Yes, Log out')}
                    content={t('Are you sure you want to log out? You will be navigated away from the current quote.')}
                    handleClose={handleIsLogoutDialogClose}
                    handleConfirmClose={handleLogOut}
                    isOpen={isLogoutDialogOpen}
                    key={currentUser.contactId}
                    title={t('Log out')}
                />
            ) : null}

            <Dialog
                classes={{ paper: classes.dialogPaper }}
                maxWidth="sm"
                open={openLoginDialog}
                onClose={handleLoginDialogClose}
            >
                <Login
                    showGuestButton={false}
                    showRequestAccessButton={false}
                    onSuccessClose={handleLoginDialogClose}
                />
            </Dialog>
        </MuiAppBar>
    )
}

AppBar.propTypes = {
    color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', 'transparent']),
    position: PropTypes.oneOf(['absolute', 'fixed', 'relative', 'static', 'sticky']),
}

export default AppBar
