import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { EventType, InteractionStatus, InteractionType, PublicClientApplication } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { SplitClient, SplitFactoryProvider } from '@splitsoftware/splitio-react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { api } from '@/app/services/api'
import { useLazyGetOrganisationQuery } from '@/app/services/organisation'
import { useLazyGetOrganisationUserQuery } from '@/app/services/organisationUsers'
import {
    useCreateUserMutation,
    useGetOrCreateUserContextMutation,
    useLazyGetUserOrganisationsQuery,
} from '@/app/services/user'
import {
    selectAppIsLoading,
    selectCurrentUser,
    setAppIsLoading,
    setLanguage,
    setLocale,
    setUserRole,
} from '@/app/slices/appSlice'
import {
    selectOrganisation,
    selectPlanMetadata,
    setLastPaymentStatus,
    setOrganisation,
} from '@/app/slices/organisationSlice'
import { loginRequest, resetPasswordRequest } from '@/authConfig'
import { PageSpinner } from '@/common/components'
import useEffectOnlyOnUpdate from '@/common/hooks/useEffectOnlyOnUpdate'
import {
    getLanguageToUse,
    getLocaleToUse,
    getUserDtoFromMsalAccountInfo,
    initUserFlow,
    Paths,
    RESELLERS,
} from '@/common/utils'
import Layout from '@/layout/Layout'

import UserObserverContainer from './UserObserverContainer'

const noSubscriptionRequired = [
    Paths.ACTIVATE_PATHNAME,
    Paths.ACTIVATE_BILLING_DETAILS_PATHNAME,
    Paths.ACTIVATE_PAYMENT_DETAILS_PATHNAME,
    Paths.ERROR_PATHNAME,
    Paths.NO_ACTIVE_SUBSCRIPTION_PATHNAME,
]
const UserRequiredContainer = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const appInsights = useAppInsightsContext()
    const userIsAuthenticated = useIsAuthenticated()
    const { i18n } = useTranslation()
    const { inProgress, instance: msalInstance } = useMsal()

    const appIsLoading = useSelector(selectAppIsLoading)
    const organisation = useSelector(selectOrganisation)
    const currentUser = useSelector(selectCurrentUser)
    const planMetadata = useSelector(selectPlanMetadata)

    const [splitConfig, setSplitConfig] = useState({
        core: {
            authorizationKey: import.meta.env.VITE_SPLIT_APIKEY,
            key: 'anonymous',
        },
    })

    const [createUser] = useCreateUserMutation()
    const [getOrCreateUserContext] = useGetOrCreateUserContextMutation()
    const [getOrganisation] = useLazyGetOrganisationQuery()
    const [getOrganisationUser] = useLazyGetOrganisationUserQuery()
    const [getUserOrganisations] = useLazyGetUserOrganisationsQuery()

    const trackEvent = ({ data, eventName }) => appInsights.trackEvent({ name: eventName, properties: data })

    const initSplit = useCallback(
        (user) => {
            setSplitConfig((prevConfig) => ({
                ...prevConfig,
                core: {
                    ...prevConfig.core,
                    key: user?.emailAddress || 'anonymous',
                    trafficType: user ? undefined : 'anonymous',
                },
            }))
        },
        [setSplitConfig]
    )

    const handleUserError = async (error) => {
        if (error?.status === 404) {
            return await createUser({ user: getUserDtoFromMsalAccountInfo(msalInstance.getActiveAccount()) }).unwrap()
        } else if (error?.status === 401) {
            dispatch(api.util.resetApiState())
            await msalInstance.logoutRedirect()
        } else {
            throw error
        }
    }

    const determineRedirectPath = (organisationResult, currentPath) => {
        if (
            !organisationResult?.hasActiveSubscription &&
            (!organisationResult.trial || organisationResult.trial.hasExpired) &&
            !organisationResult.freePlan
        ) {
            return Paths.NO_ACTIVE_SUBSCRIPTION_PATHNAME
        } else if (currentPath === '/') {
            return Paths.DASHBOARD_PATHNAME
        }
        return null
    }

    const initUserAndOrg = useCallback(async () => {
        const msalUser = msalInstance.getActiveAccount()

        if (msalUser && (!currentUser || _.isEmpty(currentUser))) {
            let userContext
            try {
                userContext = await getOrCreateUserContext({ user: getUserDtoFromMsalAccountInfo(msalUser) }).unwrap()
            } catch (error) {
                userContext = await handleUserError(error)
            }

            if (!userContext?.user) {
                trackEvent({
                    eventName: 'User not found, redirected to error page',
                    data: { msalUser, userContext },
                })
                navigate(Paths.ERROR_PATHNAME)
                dispatch(setAppIsLoading(false))
                return
            }

            const appUser = userContext.user
            const selectedOrganisationId = userContext.selectedOrganisationId

            if (!selectedOrganisationId) {
                trackEvent({
                    eventName: `New user has not yet created an organisation, redirected to ${Paths.WELCOME_PATHNAME}`,
                    data: { currentUser: appUser, organisation: null },
                })
                navigate(Paths.WELCOME_PATHNAME)
                dispatch(setAppIsLoading(false))

                return
            }

            let organisationResult
            try {
                organisationResult = await getOrganisation({ organisationId: selectedOrganisationId }).unwrap()
            } catch (error) {
                if ([400, 404].includes(error.status) && location.pathname !== Paths.WELCOME_PATHNAME) {
                    trackEvent({
                        eventName: `Organisation not found, redirected to ${Paths.WELCOME_PATHNAME}`,
                        data: { currentUser: appUser, organisation: organisationResult },
                    })
                    navigate(Paths.WELCOME_PATHNAME)
                    dispatch(setAppIsLoading(false))
                    return
                }

                dispatch(api.util.resetApiState())
                await msalInstance.logoutRedirect()
                return
            }

            let organisationUserResult
            try {
                organisationUserResult = await getOrganisationUser({
                    organisationId: selectedOrganisationId,
                    userId: appUser.userId,
                }).unwrap()
            } catch (error) {
                console.error('An error occurred handling organisation user.', error)
            }

            await getUserOrganisations({ userId: appUser.userId })

            const role = organisationUserResult?.role?.toLowerCase() || 'user'
            const lastPaymentStatus = userContext.lastPayment?.status?.toLowerCase() || 'unknown'
            const localeToUse = getLocaleToUse(organisationResult)
            const languageToUse = getLanguageToUse(appUser, organisationResult)

            i18n.changeLanguage(languageToUse)
            dispatch(setOrganisation(organisationResult))
            dispatch(setUserRole(role))
            dispatch(setLastPaymentStatus(lastPaymentStatus))
            dispatch(setLanguage(languageToUse))
            dispatch(setLocale(localeToUse))
            dispatch(setAppIsLoading(false))

            initSplit(appUser)

            initUserFlow({
                user: appUser,
                organisation: organisationResult,
                localeToUse,
                language: languageToUse,
                planMetadata,
                reseller: import.meta.env.VITE_RESELLER ? RESELLERS[import.meta.env.VITE_RESELLER].name : undefined,
            })

            const redirectTo = determineRedirectPath(organisationResult, location.pathname)

            if (redirectTo) {
                trackEvent({
                    eventName: `User redirected to ${redirectTo}`,
                    data: { currentUser: appUser, organisation: organisationResult },
                })
                navigate(redirectTo)
            } else {
                trackEvent({
                    eventName: 'User not redirected',
                    data: { currentUser: appUser, organisation: organisationResult },
                })
            }
        }
    }, [navigate, location, currentUser, msalInstance, planMetadata, trackEvent])

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            initUserAndOrg()
        }
    }, [inProgress])

    useEffect(() => {
        const callbackId = msalInstance.addEventCallback((message) => {
            if (message.eventType === EventType.LOGIN_FAILURE && message.interactionType === InteractionType.Redirect) {
                msalInstance.loginRedirect(
                    message.error.errorMessage.includes('AADB2C90118') ? resetPasswordRequest : loginRequest
                )
            }
        })

        return () => {
            if (callbackId) {
                msalInstance.removeEventCallback(callbackId)
            }
        }
    }, [msalInstance])

    useEffectOnlyOnUpdate(() => {
        if (organisation && currentUser && userIsAuthenticated && !noSubscriptionRequired.includes(location.pathname)) {
            const localeToUse = getLocaleToUse(organisation)
            const languageToUse = getLanguageToUse(currentUser, organisation)
            initUserFlow({
                user: currentUser,
                organisation,
                localeToUse,
                language: languageToUse,
                planMetadata,
                reseller: import.meta.env.VITE_RESELLER ? RESELLERS[import.meta.env.VITE_RESELLER].name : undefined,
            })
        }
    }, [organisation, currentUser, userIsAuthenticated, location.pathname])

    return (
        <MsalAuthenticationTemplate
            authenticationRequest={loginRequest}
            errorComponent={PageSpinner}
            interactionType={InteractionType.Redirect}
            loadingComponent={PageSpinner}
        >
            {appIsLoading ? (
                <PageSpinner />
            ) : (
                <SplitFactoryProvider config={splitConfig}>
                    <SplitClient splitKey={currentUser?.emailAddress}>
                        <UserObserverContainer>
                            <Layout>
                                <Outlet />
                            </Layout>
                        </UserObserverContainer>
                    </SplitClient>
                </SplitFactoryProvider>
            )}
        </MsalAuthenticationTemplate>
    )
}

UserRequiredContainer.propTypes = {
    msalInstance: PropTypes.instanceOf(PublicClientApplication),
}

export default UserRequiredContainer
